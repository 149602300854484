import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import { isNotEmpty } from '../../../common/utils/common.utils';
import CostViewSubTotal from '../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal';
import FormHeaderTitle from '../common/FormHeaderTitle';
import { IBillingInfo, IViewInvoice } from '../Type/Invoice.interface';

type Props = {
  invoiceDetails: IViewInvoice | undefined;
};

const ViewInvoiceOtherDetails = ({ invoiceDetails }: Props) => {
  const billingColumn: ColumnsType<IBillingInfo> = [
    {
      title: 'SL',
      align: 'center',
      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: 'Pax Name',
      dataIndex: 'billing_pax',
      key: 'billing_pax',
      render: (billing_pax) => (billing_pax ? billing_pax : 'N/A'),
    },
    {
      title: 'PNR',
      dataIndex: 'billing_pnr',
      key: 'billing_pnr',
      render: (billing_pnr) => (billing_pnr ? billing_pnr : 'N/A'),
    },

    {
      title: 'Total Pax',
      dataIndex: 'billing_total_pax',
      key: 'billing_total_pax',
      width: '150px',
      align: 'right',
    },
    {
      title: 'BDT Equivalent',
      dataIndex: 'billing_usd_equivalent',
      key: 'billing_usd_equivalent',
      width: '150px',
      align: 'right',
    },
    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      width: '120px',
      align: 'right',
      render: (_, { billing_total_pax, billing_usd_equivalent }) =>
        billing_total_pax && billing_usd_equivalent
          ? Number(billing_total_pax) * Number(billing_usd_equivalent)
          : 'N/A',
    },
  ];
  return (
    <>
      {isNotEmpty(invoiceDetails?.billing) && (
        <div className='mt-5'>
          <FormHeaderTitle title='BILLING INFO' />
          <Table
            bordered
            size='small'
            rowKey={(e) => Number(e.billing_id)}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.billing}
            columns={billingColumn}
            pagination={false}
          />
        </div>
      )}
      <CostViewSubTotal invoice_info={invoiceDetails} />
    </>
  );
};

export default ViewInvoiceOtherDetails;
