import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  IAccountNonInvoiceIncome,
  IAccountNonInvoiceIncomeDelResBody,
  IAccountNonInvoiceIncomeEditFormData,
  IAccountNonInvoiceIncomeFormData,
  IViewAccountNonInvoiceIncome,
} from '../../AccountsTypes/AccountsTypes';
import { NON_INVOICE_INCOME } from '../Constants/constants';

export const nonInvoiceIncomeEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all non-invoice_income
    getAllNonInvoiceIncome: build.query<
      HTTPResponse<IAccountNonInvoiceIncome[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/accounts/non-invoice-income?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),
    //get non-invoice_income by id
    getViewNonInvoiceIncome: build.query<
      HTTPResponse<IViewAccountNonInvoiceIncome[]>,
      number
    >({
      query: (body) => ({ url: `/accounts/non-invoice-income/${body}` }),
      providesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),
    //get all trashed non-invoice_income
    getAllTrashedNonInvoiceIncome: build.query<
      HTTPResponse<IAccountNonInvoiceIncome[]>,
      void
    >({
      query: () => ({ url: `/accounts/non-invoice-income/trash` }),
      providesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),

    //create non-invoice-income
    createAllNonInvoiceIncome: build.mutation<
      void,
      IAccountNonInvoiceIncomeFormData
    >({
      query: (body) => ({
        url: `/accounts/non-invoice-income`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Non-Invoice Income Added Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),

    //edit non-invoice-income
    editNonInvoiceIncome: build.mutation<
      void,
      IAccountNonInvoiceIncomeEditFormData
    >({
      query: (body) => ({
        url: `/accounts/non-invoice-income/${body.nonincome_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Non-Invoice Income Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),

    //delete non-invoice income
    deleteNonInvoiceIncome: build.mutation<
      void,
      IAccountNonInvoiceIncomeDelResBody
    >({
      query: (body) => ({
        url: `/accounts/non-invoice-income/${body.nonincome_id}`,
        method: 'DELETE',
        body: body,
      }),

      invalidatesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),

    //restore non-invoice income

    restoreNonInvoiceIncome: build.mutation<
      void,
      IAccountNonInvoiceIncomeDelResBody
    >({
      query: (body) => ({
        url: `/accounts/non-invoice-income/restore/${body.nonincome_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Non-Invoice Income Restored Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
      ],
    }),
  }),
});

export const {
  useGetAllNonInvoiceIncomeQuery,
  useLazyGetAllTrashedNonInvoiceIncomeQuery,
  useCreateAllNonInvoiceIncomeMutation,
  useEditNonInvoiceIncomeMutation,
  useDeleteNonInvoiceIncomeMutation,
  useRestoreNonInvoiceIncomeMutation,
  useGetViewNonInvoiceIncomeQuery,
} = nonInvoiceIncomeEndpoints;
