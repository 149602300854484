import { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react';
import { IGellAllInvoice } from '../Type/Invoice.interface';
import { Fixed2 } from '../../../common/utils/common.utils';
import { Button, Popconfirm, Space, Tag, message } from 'antd';
import { Link } from 'react-router-dom';
import InvoiceOtherAddMoneyModal from '../Modals/InvoiceOtherAddMoneyModal';
import { useDeleteInvoiceMutation } from '../Api/invoiceEndpoints';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { numberFormate } from '../../../common/utils/numberFormatting';
import dayjs from 'dayjs';
type IProps = {
  pagination: {
    current: number;
    pageSize: number;
  };
};
const InvoiceColumnList = ({
  pagination,
}: IProps): ColumnsType<IGellAllInvoice> => {
  const [deleteInvoice, { isError, isSuccess }] = useDeleteInvoiceMutation();
  const handleOnDelete = (id: any) => {
    deleteInvoice(id);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice deleted successfully!');
    }
    if (isError) {
      message.error('Thare was an error');
    }
  }, [isSuccess, isError]);
  return [
    {
      title: 'SL.',
      width: 55,
      render: (_, value, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 120,
    },
    {
      title: 'Sales Date',
      key: 'invoice_date',
      dataIndex: 'invoice_date',

      render: (_, data) => (
        <>{dayjs(data?.invoice_sales_date).format('DD MMM YYYY')} </>
      ),
      width: 135,
      align: 'center',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => record.client_name,
      width: 150,
    },
    {
      title: 'Net total',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      width: 120,
      align: 'center',
      render: (_, record) => numberFormate(Number(record.invoice_net_total)),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'center',
      width: 120,
      render(value) {
        return numberFormate(Fixed2(value));
      },
    },
    {
      title: 'Client Due',
      align: 'center',
      width: 120,
      render(value, record, index) {
        const dueAmount =
          Number(record?.invoice_net_total) -
          Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {numberFormate(
              Number(record?.invoice_net_total) -
                Number(record?.invclientpayment_amount)
            )}
          </span>
        ) : (
          <Tag color='success'>PAID</Tag>
        );
      },
    },

    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },

    {
      title: 'Sales by',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 150,
    },

    {
      title: 'Action',
      key: 'operation',
      width: 200,

      render: (_, data, index) => {
        return (
          <Space size='small'>
            <Link
              to={'/invoice/details/' + data?.invoice_id}
              state={location.pathname}
            >
              <Button
                size='small'
                type='link'
                style={{ width: '50px', fontSize: '15px' }}
                icon={<EyeOutlined />}
              ></Button>
            </Link>
            <Link
              to={'/invoice/edit/' + data?.invoice_id}
              state={location.pathname}
            >
              <Button
                style={{ width: '50px', fontSize: '15px' }}
                size='small'
                type='link'
                icon={<EditOutlined />}
              ></Button>
            </Link>

            <Button
              style={{ width: '50px', fontSize: '15px' }}
              size='small'
              type='link'
              danger
            >
              <Popconfirm
                onConfirm={() => handleOnDelete(data?.invoice_id)}
                title='Sure to delete?'
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>

            <InvoiceOtherAddMoneyModal
              invoice_id={data?.invoice_id}
              InvocieModalTitle={'modal'}
              disabled={
                Number(data?.invoice_net_total) <=
                Number(data?.invclientpayment_amount)
              }
            />
          </Space>
        );
      },
    },
  ];
};

export default InvoiceColumnList;
