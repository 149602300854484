import { Card, Modal, Skeleton, Typography } from 'antd';
import { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useAccountBalanceQuery } from '../Api/Endpoints/DashboardEndpoints';
import DashboardTitle from './DashboardTitle';
import AccountHistoryDetails from './Dashboard_Acc_balance_History/AccountHistoryDetails';
import { IDarkMode } from './InvoiceDetails';

const Account_Insight = ({ isDarkMode }: IDarkMode) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const { isLoading, data } = useAccountBalanceQuery();
  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );

  const accData = data?.data;

  const accArray = accData?.names.map((name, index) => ({
    name,
    amount: parseFloat(accData?.ammount[index]),
  }));

  const sum = accData?.ammount?.reduce(
    (acc, currentValue) => acc + parseFloat(currentValue),
    0
  );

  const amount = accArray?.map((items) => Math?.abs(items?.amount));

  if (isLoading) {
    return (
      <Card style={{ height: '100%' }}>
        <Skeleton title={false} paragraph={{ rows: 10 }} active />
      </Card>
    );
  }

  if (amount?.length === 0) {
    return (
      <div className='dashboard-box-shape' style={{ height: 350 }}>
        <DashboardTitle
          title='ACCOUNT BALANCE HISTORY'
          link='/accounts/balancestatus'
        />
        <Card
          bodyStyle={{ padding: '10px 20px', height: 305 }}
          bordered={false}
        >
          <Typography.Text>No information found to display</Typography.Text>
        </Card>
      </div>
    );
  }

  const showModal = (name: string) => {
    setModalTitle(name);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle
        title='ACCOUNT BALANCE HISTORY'
        link='/accounts/balancestatus'
      />
      <Card bodyStyle={{ padding: '10px 20px', height: 305 }} bordered={false}>
        <div className='dash_account'>
          {accData?.names?.map((name, index) => (
            <div
              key={index}
              className={`account_b_box ${
                isDarkMode ? 'account_b_box-dark' : ''
              }`}
            >
              <p style={{ cursor: 'pointer' }} onClick={() => showModal(name)}>
                {name.toLocaleUpperCase()}
              </p>
              <p>
                {accData?.ammount[index]} {currency?.toUpperCase()}
              </p>
            </div>
          ))}
        </div>

        <Typography.Text
          strong
          style={{
            textTransform: 'uppercase',
            fontSize: 16,
          }}
        >
          Total Available Balance: {Fixed2(sum) || 0} {currency?.toUpperCase()}
        </Typography.Text>
      </Card>

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <AccountHistoryDetails modalTitle={modalTitle} />
      </Modal>
    </div>
  );
};

export default Account_Insight;
