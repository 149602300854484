import { Button, ConfigProvider, theme, type RadioChangeEvent } from 'antd';
import { useEffect, useState } from 'react';

import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillToolbar, { formats, modules } from './EditorToolbar';

type Props = {
  configDetails: IGetAppConfig | undefined;
};

const InvoiceExtraInfo = ({ configDetails }: Props) => {
  const [postEdit] = usePostEditAppConfigMutation();
  const [value, setValue] = useState('');

  const prevNote = configDetails?.tac_invoice_footer_note;

  useEffect(() => {
    if (prevNote) {
      setValue(prevNote);
    }
  }, [prevNote]);

  const handleSubmit = (e: any) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_invoice_footer_note: value,
    };

    postEdit(body);
  };
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <div
        style={{
          maxWidth: 900,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 35,
        }}
      >
        <h2>Invoice Footer Info:</h2>

        <QuillToolbar />
        <ReactQuill
          placeholder='Invoice Footer Information'
          style={{ height: 200, marginBottom: 20 }}
          theme='snow'
          value={value}
          onChange={setValue}
          modules={modules}
          formats={formats}
        />
        <Button
          style={{ float: 'right' }}
          onClick={handleSubmit}
          type='primary'
        >
          Save
        </Button>
      </div>
    </ConfigProvider>
  );
};

export default InvoiceExtraInfo;
