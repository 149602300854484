import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllMoneyReceiptsQuery } from '../../api/endpoints/moneyReceiptEndpoints';
import MoneyReceiptColumn from '../../utils/MoneyReceiptColumn';
import { removeSpaceBeforeWord } from '../../../../common/utils/removeSpaceBeforeWord';

type Props = { permission?: IPermission };

export interface DataType {
  key: React.Key;
  receipt_has_deleted: number;
  trxntype_name: string;
  receipt_vouchar_no: string;
  receipt_payment_date: string;
  acctype_name: string;
  client_name: string;
  receipt_total_amount: number;
  receipt_id: number;
  account_name?: string;
  receipt_money_receipt_no?: string | null;
  cheque_or_bank_name?: string;
  mobile?: string;
}

const ListofMoneyReceipt = ({ permission }: Props) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching, refetch } = useGetAllMoneyReceiptsQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  useEffect(() => {
    const dataTemp: DataType[] =
      data?.data?.map((el, index) => {
        return {
          key: index + 1,
          receipt_has_deleted: el.receipt_has_deleted,
          acctype_name: el.acctype_name,
          client_name: el.client_name,
          receipt_payment_date: el.receipt_payment_date
            ? dayjs(el.receipt_payment_date).format('DD-MM-YYYY')
            : '',
          receipt_total_amount: Number(el.receipt_total_amount || '0'),
          receipt_vouchar_no: el.receipt_vouchar_no,
          trxntype_name: el.trxntype_name,
          receipt_money_receipt_no: el.receipt_money_receipt_no,
          receipt_id: el.receipt_id,
          account_name: el?.account_name,
          cheque_or_bank_name: el?.cheque_or_bank_name,
          mobile: el.mobile,
        };
      }) || [];
    setDataSource(dataTemp);
  }, [data]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <ViewInvoiceStyle>
      {(isLoading || isFetching) && <LoadingIndicator />}
      <BreadCrumb
        arrOfOption={['Money Receipts']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row
        justify={'space-between'}
        style={{ margin: '25px 0' }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
      >
        <Space>
          {permission?.['create:any'] && (
            <Link to='/moneyreceipt/add' state={location.pathname}>
              <Button type='primary'>
                <PlusOutlined />
                Add Money Receipt
              </Button>
            </Link>
          )}
        </Space>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowKey={(e) => e.receipt_vouchar_no}
        columns={MoneyReceiptColumn({ pagination, permission })}
        dataSource={dataSource}
        pagination={
          data?.count !== undefined && data?.count > 20
            ? {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
            : false
        }
      />
    </ViewInvoiceStyle>
  );
};

export default ListofMoneyReceipt;

const ViewInvoiceStyle = styled.div`
  .transfer-in-list {
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 12px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 10px !important;
    background-color: #e2e2e2;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
