import { MinusCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Row } from 'antd';
import { FormInstance, FormListFieldData, Rule } from 'antd/lib/form';
import {
  DateInput,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectClientsInvoicesByIdForEdit } from '../../../components/common/FormItem/SelectCustomFeilds';
import { Invoices } from '../../../components/notificatioin/Interfaces/Notification.interface';
import { IMoneyReceiptInvoices } from '../Types/MoneyReceiptTypes';
import { useEffect, useState } from 'react';

type Props = {
  selectClient?: number | string;
  name: number;
  fields: FormListFieldData[];
  remove: (index: number | number[]) => void;
  paymentTo: 'INVOICE';
  index: number;
  form: FormInstance<any>;
  reason?: 'ADD_NEW' | 'EDIT';
  data?: Invoices;
  disableRemoveIndex?: number | false | undefined;
};

export default function NewMoneyReceiptSpecificInvoice({
  selectClient,
  name,
  fields,
  remove,
  paymentTo,
  index,
  form,
  reason,
  data,
  disableRemoveIndex,
}: Props) {
  const receipt_client_id = form.getFieldValue('receipt_client_id');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disableRemoveIndex && disableRemoveIndex > index) {
      setDisabled(true);
    }
  }, [disableRemoveIndex]);

  const rules: Rule[] = [
    {
      validator(rule, value, callback) {
        if (paymentTo === 'INVOICE') {
          const fullLIst: IMoneyReceiptInvoices[] = form.getFieldValue([
            'invoices',
          ]);
          const filtered = fullLIst.filter((item, i) => {
            if (item.invoice_no === value) {
              if (index === i) {
                return;
              }
              return item;
            }
          });
          if (filtered.length) {
            return Promise.reject('Cannot select duplicate invoice!!');
          } else {
            return Promise.resolve();
          }
        }
      },
    },
  ];

  return (
    <>
      <Row
        style={{
          width: '100%',
          borderRadius: '5px',
        }}
        gutter={[22, 10]}
      >
        <SelectClientsInvoicesByIdForEdit
          label={'Invoice No.'}
          name={[name, 'invoice_no']}
          client_id={receipt_client_id}
          receipt_payment_to={paymentTo}
          disabled={
            disabled || (selectClient && !data?.invoice_no ? false : true)
          }
          placeholder={`Select Invoice No.'`}
          rules={rules}
          index={name}
          form={form}
          paymentTo={paymentTo}
        />

        {paymentTo === 'INVOICE' && (
          <DateInput
            name={[name, 'invoiceDate']}
            label='Invoice Date'
            size={4}
            disabled
          />
        )}
        <NumberInput
          name={[name, 'netTotal']}
          label='Net Total'
          readOnly
          size={4}
          min='0'
          maxChar={14}
          minChar={0}
        />
        <NumberInput
          name={[name, 'paid']}
          label='Paid'
          size={3}
          readOnly
          min='0'
          maxChar={14}
          minChar={0}
        />
        <NumberInput
          name={[name, 'due']}
          label='Due'
          size={3}
          readOnly
          min='0'
          maxChar={14}
          minChar={0}
        />
        <NumberInput
          name={[name, 'invoice_amount']}
          label='Amount'
          size={3}
          min='0'
          maxChar={14}
          minChar={0}
          required
          disabled={disabled}
          maxAmount={
            // WHEN ADD_NEW AMOUNT IS NOT GREATERTHAN DUE AND WHEN EDIT AMOUNT DUE + PAID
            reason === 'ADD_NEW'
              ? Number(
                  form.getFieldValue([
                    paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                    name,
                    'due',
                  ]) || 0
                )
              : Number(
                  form.getFieldValue([
                    paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                    name,
                    'due',
                  ]) || 0
                ) +
                Number(
                  form.getFieldValue([
                    paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                    name,
                    'paid',
                  ]) || 0
                )
          }
          max={
            reason === 'ADD_NEW'
              ? Number(
                  form.getFieldValue([
                    paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                    name,
                    'due',
                  ]) || 0
                )
              : Number(
                  form.getFieldValue([
                    paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                    name,
                    'due',
                  ]) || 0
                ) +
                Number(
                  form.getFieldValue([
                    paymentTo === 'INVOICE' ? 'invoices' : 'tickets',
                    name,
                    'paid',
                  ]) || 0
                )
          }
        />
        {fields.length > 1 ? (
          <>
            <Popconfirm
              placement='top'
              title={'Are you sure you want to remove?'}
              onConfirm={() => {
                remove(name);
              }}
              okText='Remove'
              cancelText='Cancel'
              disabled={disableRemoveIndex ? disableRemoveIndex > index : false}
            >
              <MinusCircleOutlined
                style={{
                  marginLeft: '50px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              />
            </Popconfirm>
          </>
        ) : null}
      </Row>
    </>
  );
}
