import { Col, Form, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useEditInvoiceMutation,
  useGetViewInvoiceQuery,
} from '../Api/invoiceEndpoints';
import dayjs from 'dayjs';
type Props = {};

const EditInvoice = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [createAndView, setCreateAnView] = useState(false);
  const { data } = useGetViewInvoiceQuery(Number(id)!);
  // ============= set total billing
  const [form] = Form.useForm();

  // EDIT QUERY FOR INVOICE
  const [
    editInvoice,
    { data: editdata, isError, isSuccess, isLoading: postLoading, error },
  ] = useEditInvoiceMutation();

  useEffect(() => {
    if (data?.data) {
      form.setFieldsValue({
        ...data.data,
        invoice_due_date:
          data.data.invoice_due_date && dayjs(data.data.invoice_due_date),
        invoice_sales_date: dayjs(data.data.invoice_sales_date),
        billing: data.data.billing.length
          ? data.data.billing.map((item) => ({
              ...item,
              billing_flight_date:
                item.billing_flight_date && dayjs(item.billing_flight_date),
            }))
          : [{}],
      });
    }
  }, [data?.data]);

  const onFinish = async (values: any) => {
    editInvoice({ id: Number(id), body: values });
  };

  // NEVIGATE TO ALL INVOICES
  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice edited successfully!');
      if (createAndView) {
        navigate(`/invoice/details/${id}`);
      } else {
        navigate(`/invoice`);
      }
    }
    if (isError) {
      message.error('Thare was an error');
    }
  }, [postLoading]);

  // SUBMIT FAILD
  const onFinishFailed = (errorInfo: any) => {
    message.error('Submit failed!');
  };

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice', 'edit']} />
      {postLoading && <LoadingIndicator />}
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        form={form}
      >
        <InvoiceHeaderForm
          isEdit
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />
        <InvoiceBillingInfo
          form={form}
          billing_length={data?.data?.billing?.length}
        />

        <InvoiceVisaSubTotalForm form={form} />

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton loading={postLoading} label='UPDATE' />
          </Col>
          <Col>
            <FormButton
              loading={postLoading}
              label='UPDATE & VIEW'
              onClick={() => setCreateAnView(true)}
            />
          </Col>
        </Row>
      </Form>
    </ClientsStyle>
  );
};

export default EditInvoice;

const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
`;
