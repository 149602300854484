import { TUser } from '../states/userSlice';

const lib = {
  getLocalStorage(key: string) {
    const localStoreItem = localStorage.getItem(key) as string;

    if (localStoreItem) {
      return JSON.parse(localStoreItem);
    } else {
      return null;
    }
  },

  setLocalStorage(key: string, value: TUser | string) {
    const parsedValue = JSON.stringify(value);

    localStorage.setItem(key, parsedValue);
  },

  removeLocalStorageItem(key: string) {
    localStorage.removeItem(key);
  },
};

export default lib;

export function isObjectEmpty(obj: object) {
  return Object?.keys(obj)?.length === 0;
}
