import { Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { useEffect } from 'react';
import { NumberInput, TextAreaInput } from '../FormItem/FormItems';

type Props = {
  form: FormInstance<any>;
};

const InvoiceSubTotal = ({ form }: Props) => {
  const billingForm = useWatch(['billing'], form);

  const invoice_sub_total = useWatch(['invoice_sub_total'], form);
  const discount: number = useWatch(['invoice_discount'], form);
  const serviceCharge: number = useWatch(['invoice_service_charge'], form);
  useEffect(() => {
    form?.setFieldValue(
      'invoice_net_total',
      (Number(invoice_sub_total) || 0) -
        (Number(discount) || 0) +
        (Number(serviceCharge) || 0)
    );
  }, [billingForm, invoice_sub_total, discount, serviceCharge]);

  return (
    <Row gutter={[10, 0]} className='p-5 border'>
      <NumberInput
        size={3}
        label='Sub Total'
        name='invoice_sub_total'
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        min='0'
        size={5}
        label={'Discount'}
        name={'invoice_discount'}
        maxChar={10}
      />

      <NumberInput
        size={5}
        label={'Service Charge'}
        name={'invoice_service_charge'}
        min='0'
        maxChar={10}
      />

      <NumberInput
        size={5}
        label='Net Total'
        name={'invoice_net_total'}
        readOnly
        min='0'
        maxChar={14}
      />
      <TextAreaInput size={6} rows={1} label='Note' name='invoice_note' />
    </Row>
  );
};

export default InvoiceSubTotal;
