import { Form, Modal, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import ResetPassword from '../../../../components/common/FormItem/ChangeUserPassword';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { IResetPasswordType } from '../../../../components/common/header/interface/headerTypes';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useGetAllUsersWithPaginationQuery,
  useResetUserPasswordMutation,
} from '../endpoints/userEndpoints';
import { IUserDataType } from '../types/user.types';
import { UserUtils } from '../utils/UserUtils';
import AddUserAccount from './AddUserAccount';
import FormHeaderTitle from '../../../Invoices/common/FormHeaderTitle';

const ViewUser = () => {
  const dispatch = useAppDispatch();
  const modalSlice = useAppSelector(selectCommon);
  const [user_id, setUser_id] = useState<number>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [form] = Form.useForm();

  const {
    data: users,
    isLoading,
    refetch,
  } = useGetAllUsersWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: IUserDataType[] = [];

      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          dataToReturn.push({ ...element });
        }
      }

      return { ...cache, data: { dataToReturn, count } };
    },
  });

  const [resetPassword, { isSuccess }] = useResetUserPasswordMutation();

  const onFinish = async (values: IResetPasswordType) => {
    const body: IResetPasswordType = {
      ...values,
      user_id: user_id,
    };

    await resetPassword(body);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Password Change Successfully');
      dispatch(setModal(false));
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <div>
      <Modal
        title='Reset Password'
        open={modalSlice.isModal}
        onOk={() => {
          dispatch(setModal(false));
        }}
        onCancel={() => {
          dispatch(setModal(false));
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout='vertical'>
          <ResetPassword />
        </Form>
      </Modal>
      <BreadCrumb
        arrOfOption={['Users']}
        refetch={refetch}
        reloaderSize='small'
      />

      <AddUserAccount />

      <FormHeaderTitle title='Users' />
      <Table
        columns={UserUtils({ setUser_id, pagination })}
        dataSource={users.dataToReturn}
        bordered
        size='small'
        pagination={
          users?.count !== undefined && users?.count < 20
            ? false
            : {
                ...pagination,
                total: users?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ViewUser;
