import { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react';
import { DataType } from '../Pages/MoneyReceipt/ListofMoneyReceipt';
import { Button, Popconfirm, Space, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { IPermission } from '../../../common/types/commonTypes';
import { useDeleteMoneyReturnMutation } from '../api/endpoints/moneyReceiptEndpoints';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import { numberFormate } from '../../../common/utils/numberFormatting';

type IProps = {
  pagination: {
    current: number;
    pageSize: number;
  };
  permission: IPermission | undefined;
};
const MoneyReceiptColumn = ({
  pagination,
  permission,
}: IProps): ColumnsType<DataType> => {
  const user = useAppSelector(selectUser);
  const [deleteMoneyReceipt, { isLoading: deleteLoading, isSuccess, isError }] =
    useDeleteMoneyReturnMutation();

  const handleDelete = async (values: DataType) => {
    await deleteMoneyReceipt({
      id: values.receipt_id,
      userId: user?.user_id as number,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      message.success('MoneyReceipt deleted successfully');
    } else if (isError) {
      message.success('Something went wrong');
    }
  }, [isSuccess, isError]);
  return [
    {
      title: 'SL.',
      render: (_, value, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },

    {
      title: 'Payment Date',
      dataIndex: 'receipt_payment_date',
      key: 'receipt_payment_date',
      align: 'left',
    },

    {
      title: 'Vouchar No',
      dataIndex: 'receipt_vouchar_no',
      key: 'receipt_vouchar_no',
      align: 'left',
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      align: 'left',
      render: (_, data) => (
        <Link to={`/clients/details/client-${''}`}>{data.client_name}</Link>
      ),
    },
    {
      title: 'Payment Type',
      dataIndex: 'acctype_name',
      align: 'left',
    },

    {
      title: 'Account Name',
      dataIndex: 'cheque_or_bank_name',
      key: 'cheque_or_bank_name',
      align: 'left',
      render: (_, record) => {
        let color = record.cheque_or_bank_name === 'DEPOSIT' ? 'green' : 'red';
        function status(record: any) {
          if (
            record.cheque_or_bank_name == 'DEPOSIT' ||
            record.cheque_or_bank_name == 'PENDING' ||
            record.cheque_or_bank_name == 'BOUNCE'
          ) {
            return (
              <span style={{ color }}> {record.cheque_or_bank_name} </span>
            );
          } else {
            return `${record.cheque_or_bank_name}`;
          }
        }
        return status(record);
      },
    },

    {
      title: 'Manual Receipt No',
      dataIndex: 'receipt_money_receipt_no',
      key: 'receipt_money_receipt_no',
      align: 'left',
    },

    {
      title: 'Paid Amount',
      dataIndex: 'receipt_total_amount',
      key: 'receipt_total_amount',
      align: 'left',
      render: (_, data) => numberFormate(Number(data.receipt_total_amount)),
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={`/moneyreceipt/view/${data.receipt_id}`}
              state={location.pathname}
            >
              <Button icon={<EyeOutlined />} size='small' type='link'>
                <Typography style={{ color: '#ffffff' }}></Typography>
              </Button>
            </Link>
          )}

          {permission?.['update:any'] &&
            data.cheque_or_bank_name !== 'DEPOSIT' && (
              <Link
                to={`/moneyreceipt/edit/${data.receipt_id}`}
                state={location.pathname}
              >
                <Button
                  size='small'
                  type='link'
                  icon={<EditOutlined />}
                ></Button>
              </Link>
            )}
          {permission?.['delete:any'] && data.receipt_has_deleted === 0 && (
            <Button
              style={{ width: '50px', fontSize: '15px' }}
              size='small'
              type='link'
              danger
            >
              <Popconfirm
                placement='topLeft'
                title='Sure to delete?'
                onConfirm={() => handleDelete(data)}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};

export default MoneyReceiptColumn;
