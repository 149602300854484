import { Col, ConfigProvider, Row, theme, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import { PhoneParser } from '../../../../helpers/PhoneFormatter';
import dayjs from 'dayjs';

type Props = {
  extraInfo?: { name: string; mobile: string; email: string; address: string };
  printRef: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
  title: { title: string; info_title: string };
  isPrintFooterShowing?: boolean;
};

const invoiceViewStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(211, 211, 211, 0.2)',
  minHeight: '100vh',
  padding: '20px 0',
};
const a4sizeStyle: React.CSSProperties = {
  minHeight: '11in',
  // width: '8.27in',
  fontSize: '11px',
  // height: '200px',
  background: '#fff',
  boxSizing: 'border-box',
  padding: '0 15px',
};
const a4sizeStyleHalfPage: React.CSSProperties = {
  minHeight: '5.75in',
  width: '8.27in',
  boxSizing: 'border-box',
  fontSize: '12px',
  background: '#fff',
  margin: '10px auto',
};

const CommonViewReport = ({
  printRef,
  children,
  extraInfo,
  title,
  isPrintFooterShowing,
}: Props) => {
  const printTime = dayjs().format('ll LTS');

  return (
    <ViewInvoiceStyle style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div ref={printRef} style={{ ...a4sizeStyle, position: 'relative' }}>
          <header>
            <InvoiceHeader />
            {title.title ? <TitleCenter title={title.title} /> : ''}
            {extraInfo && (
              <Row justify={'space-between'} align='middle'>
                <Col
                  style={{
                    color: '#fff',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    width: '50%',
                  }}
                >
                  {title.info_title ? (
                    <Typography.Title
                      style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                      level={5}
                    >
                      {title.info_title}:
                    </Typography.Title>
                  ) : (
                    ''
                  )}
                  {extraInfo?.name && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '13px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Name :</b> {extraInfo?.name}
                    </Typography.Text>
                  )}
                  {extraInfo?.address && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '13px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Address :</b> {extraInfo?.address}
                    </Typography.Text>
                  )}
                  {extraInfo?.email && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '13px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Email :</b> {extraInfo?.email}
                    </Typography.Text>
                  )}
                  {extraInfo?.mobile && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '13px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Mobile :</b> {extraInfo?.mobile}
                    </Typography.Text>
                  )}
                </Col>
              </Row>
            )}
          </header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              fontFamily: "'Source Sans Pro', sans-serif",
              boxSizing: 'border-box',
              minHeight: '8.1in',
              padding: '60px 0 60px 0',
            }}
          >
            <div>{children}</div>
            {isPrintFooterShowing && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Text
                  style={{
                    marginLeft: '10px',
                    borderTop: '1px dashed gray',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Customer Signature
                </Typography.Text>
                <Typography.Text
                  style={{
                    marginTop: '10px',
                    fontSize: '10px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  {printTime}
                </Typography.Text>

                <Typography.Text
                  style={{
                    marginRight: '10px',
                    borderTop: '1px dashed gray',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Authorized Signature
                </Typography.Text>
              </div>
            )}
          </div>
        </div>
      </ConfigProvider>
    </ViewInvoiceStyle>
  );
};

export default CommonViewReport;

const ViewInvoiceStyle = styled.div``;
