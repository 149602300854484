import 'antd/dist/reset.css';
import { Navigate, Route } from 'react-router';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import PermissionRoute from '../../auth/components/PermissionRoute';
import Login from '../../auth/pages/Login';
import NotFound from '../../auth/pages/NotFound';
import { crud, rolePermissionType } from '../../common/types/commonTypes';
import { getListPermission } from '../../common/utils/common.utils';
import {
  CollectionPendingCheque,
  PaymentPendingCheque,
} from '../../components/notificatioin/Pages/CollectionPendingChque';
import DueInvoice from '../../components/notificatioin/Pages/DueInvoice';
import ClientDetails from '../../modules/Client/Client/components/ClientDetails/ClientDetails';
import ClientEdit from '../../modules/Client/Client/pages/ClientEdit';
import Clients from '../../modules/Client/Client/pages/ClientsList';
import CreateClient from '../../modules/Client/Client/pages/CreateClient';
import Db_backup from '../../modules/DB_Backup/Pages/Db_backup';
import AddExpense from '../../modules/Expense/Pages/AddExpense';
import EditExpense from '../../modules/Expense/Pages/EditExpense';
import ExpenseHistory from '../../modules/Expense/Pages/ExpenseHistory';
import ViewExpense from '../../modules/Expense/Pages/ViewExpense';
import AddAdvanceReturnMoneyReceipt from '../../modules/Money_Receipt/Pages/AdvanceReturn/AddAdvanceReturnMoneyReceipt';
import MoneyReceiptAdvance from '../../modules/Money_Receipt/Pages/AdvanceReturn/AdvanceReturnMoneyReceipt';
import ListofMoneyReceipt from '../../modules/Money_Receipt/Pages/MoneyReceipt/ListofMoneyReceipt';
import Expire from '../../auth/pages/Expire';
import { TState, moduleType } from '../../auth/states/userSlice';
import Money_Receipt_View from '../../modules/Money_Receipt/Pages/MoneyReceipt/Money_Receipt_View';
import New_Money_Receipt from '../../modules/Money_Receipt/Pages/MoneyReceipt/New_Money_Receipt';
import { AccountRoutes } from './Account.routes';
import { ConfigurationRoutes } from './Configuration.routes';
import { getDashboardRoute } from './Dashboard.routes';
import { InvoicesRoutes } from './Invoices.routes';
import { ReportRoutes } from './Report.routes';
import AdvanceMoneyReturnView from '../../modules/Money_Receipt/Pages/AdvanceReturn/AdvanceMoneyReturnView';
import LayoutContainer from '../../layout/pages/LayoutContainer';
import Cheque_Management from '../../modules/Cheque_Management/pages/Cheque_Management';
import EditAdvanceReturnMR from '../../modules/Money_Receipt/Pages/AdvanceReturn/EditAdvanceReturnMR';

type Props = {
  user: TState;
  permission: rolePermissionType | undefined;
};

export const CustomRoutes = ({ user, permission }: Props) => {
  const MODULE_TYPE = user?.organization_info?.org_module_type || 'TRABILL';
  const isAdmin = user?.user_role !== 'EMPLOYEE' ? ['*'] : undefined;

  const hasPermission = (module: moduleType) => {
    return user?.modules?.includes(module);
  };

  // EXPIRED OR DEACTIVATE USER
  if (user && user?.user_role === 'DEACTIVATE') {
    return <Expire />;
  }

  return (
    <Router>
      <Routes>
        <Route path='/login' element={user ? <Navigate to='/' /> : <Login />} />

        <Route element={<LayoutContainer />}>
          {getDashboardRoute(permission, user)}
          {ReportRoutes(permission, user)}
          {ConfigurationRoutes(permission, isAdmin)}
          {InvoicesRoutes(permission, hasPermission)}
          {AccountRoutes(permission, hasPermission)}
          <>
            <Route
              path='/cheque/moneyreceipt'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<PaymentPendingCheque />}
                />
              }
            />
            <Route
              path='/cheque/moneyreceipt-collection'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<CollectionPendingCheque />}
                />
              }
            />

            <Route
              path='/due-invoice'
              element={
                <PermissionRoute permission={['*']} element={<DueInvoice />} />
              }
            />
          </>

          {/* EXPENSE */}
          <>
            <Route
              path='/expense/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') &&
                    permission?.expense?.[crud.create]
                  }
                  element={<AddExpense permission={permission?.expense} />}
                />
              }
            />
            <Route
              path='/expense'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') && permission?.expense?.[crud.read]
                  }
                  element={<ExpenseHistory permission={permission?.expense} />}
                />
              }
            />
            <Route
              path='/expense/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') && permission?.expense?.[crud.read]
                  }
                  element={<ViewExpense />}
                />
              }
            />
            <Route
              path='/expense/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') &&
                    permission?.expense?.[crud.update]
                  }
                  element={<EditExpense />}
                />
              }
            />
          </>

          {/* CHEQUE MANAGEMENT */}
          <>
            <Route
              path='/cheques'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('cheque_management') &&
                    permission?.cheque_management?.[crud.read]
                  }
                  element={<Cheque_Management />}
                />
              }
            />
          </>
          {/* MONEY RECIPET */}
          <>
            <Route
              path='/moneyreceipt/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.create]
                  }
                  element={<New_Money_Receipt reason='ADD_NEW' />}
                />
              }
            />

            <Route
              path='/moneyreceipt'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    getListPermission(permission?.money_receipt)
                  }
                  element={
                    <ListofMoneyReceipt
                      permission={permission?.money_receipt}
                    />
                  }
                />
              }
            />
            <Route
              path='/moneyreceipt/edit/:receipt_id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.update]
                  }
                  element={<New_Money_Receipt reason='EDIT' />}
                />
              }
            />
            <Route
              path='/moneyreceipt/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.read]
                  }
                  element={<Money_Receipt_View />}
                />
              }
            />

            <Route
              path='/moneyreceipt/advance'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    getListPermission(permission?.money_receipt_advr)
                  }
                  element={
                    <MoneyReceiptAdvance
                      permission={permission?.money_receipt_advr}
                    />
                  }
                />
              }
            />

            <Route
              path='/moneyreceipt/advance/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    getListPermission(permission?.money_receipt_advr)
                  }
                  element={<AdvanceMoneyReturnView />}
                />
              }
            />
            <Route
              path='/moneyreceipt/advance/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt_advr?.[crud.create]
                  }
                  element={<AddAdvanceReturnMoneyReceipt />}
                />
              }
            />

            <Route
              path='/moneyreceipt/advance/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt_advr?.[crud.update]
                  }
                  element={<EditAdvanceReturnMR />}
                />
              }
            />
          </>

          {/* CLIENTS */}
          <>
            <Route
              path='/clients'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') &&
                    getListPermission(permission?.clients)
                  }
                  element={<Clients permission={permission?.clients} />}
                />
              }
            />
            <Route
              path='/clients/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') &&
                    permission?.clients?.[crud.create]
                  }
                  element={<CreateClient />}
                />
              }
            />
            <Route
              path='/clients/details/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') && permission?.clients?.[crud.read]
                  }
                  element={<ClientDetails />}
                />
              }
            />

            <Route
              path='/clients/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') &&
                    permission?.clients?.[crud.update]
                  }
                  element={<ClientEdit />}
                />
              }
            />
          </>

          <Route path='/db-backup' element={<Db_backup />}></Route>
        </Route>

        {/* </Route> */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
};
