import { Button, Popconfirm, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { useDeleteUserMutation } from '../endpoints/userEndpoints';
import EditUserAccount from '../pages/EditUserAccount';
import { IUserDataType } from '../types/user.types';

type Props = {
  setUser_id: (arg: number) => void;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const UserUtils = ({
  pagination,
  setUser_id,
}: Props): ColumnsType<IUserDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const [deleteUser, { isLoading, isSuccess, isError }] =
    useDeleteUserMutation();

  const deleteUserHandler = (userId: string | number, index: number) => {
    setColumnIndex(index);
    deleteUser(userId as string);
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'User Role',
      dataIndex: 'user_role',
      key: 'user_role',
    },
    {
      title: 'Full Name',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
    },
    {
      title: 'User Name',
      dataIndex: 'user_username',
      key: 'user_username',
    },

    {
      title: 'User Email',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (mobile) => {
        return <span>{mobile}</span>;
      },
    },
    {
      title: 'Role Name',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'Created at',
      dataIndex: 'user_create_date',
      key: 'user_create_date',
    },
    {
      title: 'Status',
      key: 'user_status',

      render: (_, record) => {
        return record.user_status ? (
          <Tag color='#87d068'>Active</Tag>
        ) : (
          <Tag color='#f50'>Inactive</Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',

      render: (_, record: any, index: number) => {
        const { user_id } = record;

        return (
          <Space size='small'>
            {record?.role_name !== 'admin_role' ? (
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  setUser_id(record.user_id);
                  dispatch(setModal(true));
                }}
              >
                Reset Password
              </Button>
            ) : null}
            {record?.role_name !== 'admin_role' && (
              <EditUserAccount userId={user_id} />
            )}

            {record?.role_name !== 'admin_role' && (
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => deleteUserHandler(record.user_id, index)}
              >
                <Button
                  size='small'
                  style={{ color: 'red' }}
                  loading={columnIndex === index && true}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
};
