import { Col, Form, Modal, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getDataWithIndex } from '../../../helpers/getDataWithIndex';
import { useLazyGetAllChequesQuery } from '../api/Endpoints/AllChequeManagementEndpoints';
import {
  setBounceModal,
  setDepositModal,
  setReturnModal,
} from '../states/chequeSlice';
import { IAllChequeDataType } from '../types/ChequeManagementTypes';
import { Cheque_Column, getChequeType } from '../utils/Cheque_Column';
import { SelectDataInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import Cheque_Update_Modal from '../modals/Common_Modals/Cheque_Update_Modal';
import { commonCheque } from '../states/chequeSlice';
import { generatePagination } from '../../../common/utils/generatePagination';

const Cheque_Management = () => {
  const [status, setStatus] = useState('PENDING');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const dispatch = useAppDispatch();
  const modalSlice = useAppSelector(commonCheque);

  const [fetchAllCheque, { data: allCheque, isLoading, isFetching }] =
    useLazyGetAllChequesQuery();

  const [depositInfo, setDepositInfo] = useState<IAllChequeDataType | null>(
    null
  );
  const [selectedChequeType, setSelectedChequeType] = useState(null);

  const showDepositModal = () => {
    dispatch(setDepositModal(true));
  };
  const handleCancel = () => {
    dispatch(setDepositModal(false));
    dispatch(setBounceModal(false));
    dispatch(setReturnModal(false));
  };

  useEffect(() => {
    return () => {
      dispatch(setDepositModal(false));
      dispatch(setBounceModal(false));
      dispatch(setReturnModal(false));
    };
  }, []);

  const uniqueChequeTypes = Array.from(
    new Set(allCheque?.data?.map((cheque) => cheque.cheque_type))
  );

  // Function to handle cheque type selection
  const handleChequeTypeChange = (value: any) => {
    setSelectedChequeType(value);
  };
  const filteredCheques = selectedChequeType
    ? allCheque?.data?.filter(
        (cheque) => cheque.cheque_type === selectedChequeType
      )
    : allCheque?.data;

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    fetchAllCheque({ query: query ? `${query}&status=${status}` : '' });
  };

  useEffect(() => {
    fetchAllCheque({
      query: `?status=${status}&page=${pagination.current}&size=${pagination.pageSize}`,
    });
  }, [status, pagination]);

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Cheque', 'All Cheque']}
        refetch={() =>
          refetch({
            query: `?page=${pagination.current}&size=${pagination.pageSize}`,
          })
        }
        reloaderSize='small'
      />

      <Modal
        title='Change Cheque Status'
        open={modalSlice.isDepositModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {depositInfo && <Cheque_Update_Modal info={depositInfo} />}
      </Modal>

      <Row style={{ marginBottom: '10px' }}>
        <SelectDataInput
          onChange={(value) => setStatus(value.toString())}
          defaultValue={'PENDING'}
          size={4}
          data={[
            { title: 'PENDING', id: 'PENDING' },
            { title: 'DEPOSIT', id: 'DEPOSIT' },
            { title: 'BOUNCE', id: 'BOUNCE' },
            { title: 'RETURN', id: 'RETURN' },
          ]}
        />

        <Col
          style={{ marginLeft: '10px' }}
          span={6}
          xs={24}
          sm={24}
          md={24}
          lg={6}
        >
          <Form.Item name='account_id'>
            <Select
              showSearch
              allowClear
              placeholder={'Select Cheque Type'}
              optionFilterProp='children'
              onChange={handleChequeTypeChange}
            >
              {uniqueChequeTypes?.map((item: any, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {getChequeType(item)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        dataSource={getDataWithIndex(filteredCheques)}
        columns={Cheque_Column(setDepositInfo, showDepositModal, pagination)}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={generatePagination(
          allCheque?.count,
          setPagination,
          pagination
        )}
      />
    </div>
  );
};

export default Cheque_Management;
