import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IClientLedger } from '../types/clientLedgerTypes';
import { numberFormate } from '../../../../common/utils/numberFormatting';

export const ClientLedgerUtils = (queryData: {
  current: number;
  pageSize: number;
}): ColumnsType<IClientLedger> => {
  return [
    {
      title: 'SL.',
      align: 'center',
      fixed: 'left',
      width: 40,
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'ctrxn_created_at',
      render: (_, record) => {
        return `${dayjs(record?.ctrxn_created_at).format('DD MMM YYYY')}`;
      },
      align: 'center',
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Particulars',
      key: 'ctrxn_particular_type',
      render: (_, data) => (
        <span>
          {data.ctrxn_particular_type.split('\n').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
      width: 150,
    },
    {
      title: 'Voucher No.',
      dataIndex: 'ctrxn_voucher',
      key: 'ctrxn_voucher',
      align: 'center',
      width: 120,
    },
    {
      title: 'Pax Name',
      dataIndex: 'ctrxn_pax',
      key: 'ctrxn_pax',
      render: (_, data) => (
        <span>
          {data?.ctrxn_pax?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'PNR',
      dataIndex: 'ctrxn_pnr',
      key: 'ctrxn_pnr',
      render: (_, data) => (
        <span>
          {data?.ctrxn_pnr?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Ticket No.',
      dataIndex: 'ctrxn_airticket_no',
      key: 'ctrxn_airticket_no',
      render: (_, data) => (
        <span>
          {data?.ctrxn_airticket_no?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Route',
      dataIndex: 'ctrxn_route',
      key: 'ctrxn_route',
      align: 'center',
      width: 100,
    },
    {
      title: 'Pay Type',
      dataIndex: 'ctrxn_pay_type',
      key: 'ctrxn_pay_type',
    },
    {
      title: 'Debit',
      render: (_, record) => {
        if (record.ctrxn_type == 'DEBIT') {
          return (
            <span style={{ color: 'red' }}>
              {numberFormate(Number(record.ctrxn_amount))}
            </span>
          );
        }
      },
      key: 'ctrxn_amount',

      align: 'right',
    },
    {
      title: 'Credit',
      render: (_, record) => {
        if (record.ctrxn_type == 'CREDIT') {
          return (
            <span style={{ color: 'green' }}>
              {numberFormate(Number(record.ctrxn_amount))}
            </span>
          );
        }
      },
      key: 'ctrxn_amount',
      align: 'right',
    },
    {
      title: 'Balance',
      dataIndex: 'ctrxn_lbalance',
      key: 'ctrxn_lbalance',
      align: 'right',
      width: 100,
      render: (amount) => {
        return (
          <span
            style={{
              color:
                Number(amount) === 0
                  ? '#18b4e9'
                  : Number(amount) > 0
                  ? 'green'
                  : 'red',
            }}
          >
            {Number(amount) === 0 ? '' : Number(amount) > 0 ? 'Adv ' : 'Due '}

            {numberFormate(Fixed2(Math.abs(Number(amount))))}
          </span>
        );
      },
    },

    {
      title: 'Note',
      dataIndex: 'ctrxn_note',
      key: 'ctrxn_note',
      render: (column, data) => (
        <span>
          {data?.ctrxn_note?.split('\n')?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Created Date',
      key: 'ctrxn_created_date',
      render: (_, record) => {
        return `${dayjs(record?.ctrxn_created_date).format('DD MMM YYYY')}`;
      },
      align: 'center',
      width: 100,
    },
  ];
};
