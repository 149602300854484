import dayjs from 'dayjs';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { IWeeklyMonthlyDailyMonthly } from '../interfaces/dashboard.interfaces';
import DashboardTitle from './DashboardTitle';
import { Collapse } from 'antd';
import {
  useLazyDailySalesDetailsQuery,
  useLazyMonthlySalesDetailsQuery,
} from '../Api/Endpoints/DashboardEndpoints';
import { Fixed2 } from '../../../common/utils/common.utils';
import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';

type Props = {
  data: IWeeklyMonthlyDailyMonthly | undefined;
  isDarkMode: any;
};

const MonthlyReport = ({ data, isDarkMode }: Props) => {
  const from_date = dayjs().subtract(1, 'M').format('YYYY-MM-DD');
  const to_date = dayjs().format('YYYY-MM-DD');
  const { Panel } = Collapse;

  const [getDetails, { data: details, isFetching, isLoading }] =
    useLazyMonthlySalesDetailsQuery();

  const handleChange = () => {
    getDetails('', true);
  };

  const reportData = [
    {
      label: 'Sales',
      name: 'Sales Amount',
      value: Fixed2(data?.monthly_sales),
      url: `/reports/daily_sales_report?from_date=${from_date}&to_date=${to_date}`,
    },
    {
      label: 'Received',
      name: 'Received Amount',
      value: Fixed2(data?.monthly_received),
      url: `/reports/client_wise_collection_and_sales_Report?from_date=${from_date}&to_date=${to_date}`,
    },

    {
      label: 'Expense',
      name: 'Office Expense',
      value: Fixed2(data?.monthly_expense),
      url: `/reports/head_wise_expense_report?from_date=${from_date}&to_date=${to_date}`,
    },
    {
      label: 'Purchase',
      name: 'Purchase Amount',
      value: Fixed2(details?.data?.monthly_purchase),
      url: `/reports/vendor_wise_purchase_and_payment?from_date=${from_date}&to_date=${to_date}`,
    },

    {
      label: 'Payment',
      name: 'Payment Amount',
      value: Fixed2(details?.data?.monthly_payment),
      url: `/vendor/payment-report?from_date=${from_date}&to_date=${to_date}`,
    },

    {
      label: 'Profit',
      name: 'Profit/Loss',
      value:
        Fixed2(data?.monthly_sales) - Fixed2(details?.data?.monthly_payment),
      url: `/reports/over_all_profit_loss?from_date=${from_date}&to_date=${to_date}`,
    },
  ];

  return (
    <div className='dashboard-box-shape'>
      <DashboardTitle title='MONTHLY REPORT' />

      <div style={{ padding: '0px 20px 10px' }}>
        {reportData.slice(0, 3)?.map((item: any, index: number) => (
          <div
            className={`${isDarkMode ? 'report_box-dark' : ''} report_box${
              index === reportData?.length - 1 ? ' last_box' : ''
            }`}
            key={index}
          >
            <Link
              to={item.url}
              className='hover_effect'
              style={getColor(index)}
            >
              {item.name}
            </Link>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthlyReport;

export const getColor = (index: number): CSSProperties => {
  let color = '';
  // FROM SIR
  if (index === 0) {
    color = '#1c79be';
  } else if (index === 1) {
    color = '#2D9596';
  } else if (index === 2) {
    color = '#52D3D8';
  } else if (index === 3) {
    color = '#836FFF';
  } else if (index === 4) {
    color = '#40A2E3';
  } else if (index === 5) {
    color = '#944E63';
  }

  return { color, fontWeight: 'bold', cursor: 'pointer', padding: '10px 0' };
  // return {
  //   background: color,
  //   color: '#fff',
  //   fontWeight: 'bold',
  //   padding: 5,
  //   borderRadius: 5,
  //   margin: '5px 0',
  // };
};
export const getDashboardBg = (index: number): CSSProperties => {
  let color = '';
  if (index === 0) {
    color = '#EEB76B';
  } else if (index === 1) {
    color = '#E2703A';
  } else if (index === 2) {
    color = '#9C3D54';
  } else if (index === 3) {
    color = '#310B0B';
  } else if (index === 4) {
    color = '#03506F';
  } else if (index === 5) {
    color = '#BBBBBB';
  }

  return { background: color, padding: '5px 15px' };
};
