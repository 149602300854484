import { Button, InputRef, Table, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../components/common/spinner/LoadingIndicator';
import { ISearchType } from '../../modules/Dashboard/interfaces/dashboard.interfaces';
import { render } from '@testing-library/react';
import { numberFormate } from '../../common/utils/numberFormatting';

type Props = { searchResult: ISearchType[] | undefined; isLoading: boolean };

interface resultType {
  billing_pnr: string;
  client_name: string;
  invoice_id: number;
  invoice_net_total: string;
  invoice_no: string;
  invoice_org_agency: number;
  pax_passname: string;
}

const SearchInvoices = ({ isLoading, searchResult }: Props) => {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Pax Name',
      dataIndex: 'pax_passname',
      key: 'pax_passname',
    },
    {
      title: 'Net Total',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_: any, data: resultType) =>
        numberFormate(Number(data.invoice_net_total)),
    },
    {
      title: 'Action',
      render: (_: any, data: resultType) => {
        return (
          <div>
            {
              <Link to={'invoice/details/' + data.invoice_id}>
                <Button size='small' type='primary'>
                  <Typography style={{ color: '#ffffff' }}>View</Typography>
                </Button>
              </Link>
            }
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {isLoading && <LoadingIndicator />}

      {searchResult?.length ? (
        <Table
          style={{ marginTop: '20px' }}
          columns={columns}
          // rowKey={(e) => e?.invoice_id}
          dataSource={searchResult}
          bordered
          loading={isLoading}
          size='small'
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          pagination={false}
        />
      ) : (
        <div style={{ width: '250px' }}>No Data Found</div>
      )}
    </div>
  );
};

export default SearchInvoices;

export const findInvoiceRoute = (
  invoiceCategoryId: number
): string | undefined => {
  const idToRouteMap: { [key: number]: string } = {
    1: 'airticket',
    2: 'noncomairticket',
    3: 'reissueairticket',
    4: 'invoiceTour',
    5: 'invoiceother',
    10: 'visa',
    26: 'invoiceumrah',
    30: 'hajjpreregistration',
    31: 'invoicehajj',
  };

  return idToRouteMap[invoiceCategoryId];
};
