import type { RadioChangeEvent } from 'antd';
import { Radio, Space } from 'antd';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';

type Props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};

const PaidWatermark = ({ configDetails, setConfigDetails }: Props) => {
  const [postEdit] = usePostEditAppConfigMutation();
  const defaultValue = configDetails?.tac_paid_wtr_mark;

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_paid_wtr_mark: e.target.value,
    };

    postEdit(body);
  };
  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Radio.Group onChange={onChange} value={defaultValue}>
        <Space direction='vertical'>
          <Radio value={0}>No Watermark</Radio>
          <Radio value={1}>Watermark</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default PaidWatermark;
