export function numberFormate(number: string | number) {
  // Convert the number to a string and split it into integer and decimal parts
  let [integerPart, decimalPart] = number.toString().split('.');

  // Reverse the integer part for easier processing
  let integerPartReversed = integerPart.split('').reverse().join('');

  // Add commas after every 2 digits, starting after the first 3 digits
  let result = '';
  for (let i = 0; i < integerPartReversed.length; i++) {
    if (i > 2 && (i - 3) % 2 === 0) {
      result += ',';
    }
    result += integerPartReversed[i];
  }

  // Reverse the result back to normal
  let formattedIntegerPart = result.split('').reverse().join('');

  // Combine the integer part with the decimal part if present
  let formattedNumber = formattedIntegerPart;
  if (decimalPart) {
    formattedNumber += '.' + decimalPart;
  }

  return formattedNumber;
}
