import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {} from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { IPermission } from '../../../../common/types/commonTypes';
import { getRowHightLightClass } from '../../../../common/utils/rowColorChange';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import Reload from '../../../../components/common/reload/Reload';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { IAccountDataType } from '../../AccountsTypes/AccountsTypes';
import { useGetAccountsQuery } from '../../Api/Endpoints/accountsEndpoints';
import AccountsAddModal from '../../modals/Add_ListOfAccounts/AccountsAddModal';
import AccountsEditModal from '../../modals/Add_ListOfAccounts/AccountsEditModal';
import { accountListColumns } from '../../utils/Add_ListOfAccounts/ListOfAccountsColumn';
import { removeSpaceBeforeWord } from '../../../../common/utils/removeSpaceBeforeWord';

type Props = { permission?: IPermission };

const Add_List_Accounts = ({ permission }: Props) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<IAccountDataType | null>(null);

  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    data: accounts,
    isLoading,
    isFetching,
    refetch: getAllAccounts,
  } = useGetAccountsQuery({ ...pagination, search: search });

  // ----------- tabel
  const column = accountListColumns({
    setEditInfo,
    showModal,
    permission,
    pagination,
  });

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [search]);

  return (
    <div>
      <BreadCrumb arrOfOption={['Accounts']} reloaderSize='small' />

      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Col span={12}>
          <Row justify={'start'} gutter={10}>
            {permission?.['create:any'] && (
              <Col>
                <Button type='primary' onClick={showModal}>
                  <PlusOutlined />
                  Add Account
                </Button>
              </Col>
            )}

            <Col style={{ display: 'flex' }}>
              <Col>
                <Reload
                  refetch={() => {
                    getAllAccounts();
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowClassName={(item) => getRowHightLightClass(item.account_create_date)}
        columns={column}
        dataSource={accounts?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          accounts?.count !== undefined && accounts?.count < 20
            ? false
            : {
                ...pagination,
                total: accounts?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />

      <CommonTableHeader
        modalTitle={editInfo ? 'Edit Account' : 'Add Account'}
        element={
          editInfo ? (
            <AccountsEditModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <AccountsAddModal />
          )
        }
        setNull={setEditInfo}
      />
    </div>
  );
};

export default Add_List_Accounts;
