import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import InvoiceNotFound from '../../../../auth/pages/InvoiceNotFound';
import CommonViewInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import TabDetailsView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';
import ViewInvoiceHeader from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetViewInvoiceQuery } from '../../Api/invoiceEndpoints';
import ViewInvoiceOtherBillingInfo from '../../Components/ViewInvoiceOtherBillingInfo';
import ViewInvoiceOtherDetails from '../../Components/ViewInvoiceOtherDetails';

const ViewInvoiceOther = () => {
  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);
  // GET INVOICE AIRTICKET DETAILS
  const {
    data: invoiceData,
    isLoading,
    isError,
  } = useGetViewInvoiceQuery(invoice_id);
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = React.useState<
    'viewInvoice' | 'costDetails' | 'payments' | 'activity'
  >('viewInvoice');

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case 'viewInvoice':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case 'costDetails':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case 'payments':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case 'activity':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  if (isError) return <InvoiceNotFound />;

  return (
    <>
      <ViewInvoiceHeader
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
      />
      {isLoading && <LoadingIndicator />}

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint('viewInvoice');
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint('costDetails');
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint('payments');
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint('activity');
              break;

            default:
              break;
          }
        }}
        type='card'
        style={{ marginTop: '15px' }}
        items={[
          {
            label: 'Invoice',
            key: '1',
            children: invoiceData && (
              <CommonViewInvoice
                invoiceData={invoiceData?.data}
                viewInvoicePrintableRef={viewInvoicePrintableRef}
                heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                children={
                  <ViewInvoiceOtherBillingInfo
                    billing_information={invoiceData?.data?.billing || []}
                    invoiceData={invoiceData?.data}
                  />
                }
              />
            ),
          },
          {
            label: 'Details',
            key: '2',
            children: invoiceData && (
              <TabDetailsView
                invoiceDetails={invoiceData.data}
                children={
                  <ViewInvoiceOtherDetails invoiceDetails={invoiceData.data} />
                }
                viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default ViewInvoiceOther;
