import Radio, { RadioChangeEvent } from 'antd/es/radio';
import React from 'react';
import { IGetAppConfig } from '../type/appConfig_Type';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { Space } from 'antd';

type props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};
export const AutoSms = ({ configDetails, setConfigDetails }: props) => {
  const [editPost] = usePostEditAppConfigMutation();
  const defaultValue = configDetails?.tac_auto_sms;

  const handleChange = (e: RadioChangeEvent) => {
    const body = {
      ...configDetails,
      tac_auto_sms: e.target.value,
    };

    editPost(body);
  };
  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Radio.Group onChange={handleChange} value={defaultValue}>
        <Space direction='vertical'>
          <Radio value={0}>No</Radio>
          <Radio value={1}>Yes</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};
