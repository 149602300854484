import { Divider, Typography } from 'antd';
import { Link } from 'react-router-dom';

type Props = { title: string; margintop?: number };

function FormHeaderTitle({ title, margintop }: Props) {
  const handleClick = () => {
    console.log('HII');
  };

  return (
    <Divider orientation='left' plain>
      <Typography.Title onClick={handleClick} level={5}>
        {title}
      </Typography.Title>
    </Divider>
  );
}

export default FormHeaderTitle;
