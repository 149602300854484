import { Col, Form, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { FormInstance } from 'rc-field-form';
import { useEffect, useState } from 'react';
import { useGetPaymentMethodQuery } from '../../../modules/Accounts/Api/Endpoints/accountsEndpoints';
import { useGetPaymentMethodListQuery } from '../../../modules/Money_Receipt/api/endpoints/moneyReceiptEndpoints';

type Props = {
  name: NamePath;
  label?: string;
  size?: number;
  mdSize?: number;
  smSize?: number;
  onChange?: React.Dispatch<React.SetStateAction<number>> | any;
  defaultValue?: any;
  cheque?: boolean;
  card?: boolean;
  disabled?: boolean;
  required?: boolean | undefined;
  placeholder?: string;
  form?: FormInstance<any>;
};

const SelectPaymentMethod = ({
  name,
  label,
  size,
  onChange,
  defaultValue,
  cheque,
  card,
  disabled,
  required,
  placeholder,
  mdSize,
  smSize,
  form,
}: Props) => {
  const { data, isSuccess } = useGetPaymentMethodListQuery();
  const [paymentMethod, setPaymentMethod] =
    useState<{ acctype_id: number; acctype_name: string }[]>();
  useEffect(() => {
    if (!cheque && !card) {
      data?.data && setPaymentMethod(data?.data.slice(0, 3));
    } else {
      const paymentMethods = [...(data?.data ?? [])];

      if (cheque) {
        paymentMethods.push({ acctype_id: 4, acctype_name: 'Cheque' });
      }

      if (card) {
        paymentMethods.push({ acctype_id: 5, acctype_name: 'Card' });
      }

      setPaymentMethod(paymentMethods);
    }
  }, [data?.data, cheque]);

  useEffect(() => {
    form && form.setFieldValue('payroll_pay_type', 1);
  }, [isSuccess]);

  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size || 8}
    >
      <Form.Item
        required={required}
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          allowClear
          defaultValue={defaultValue}
          onChange={onChange}
          showSearch
          placeholder={placeholder || `Select ${label}`}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          disabled={disabled && disabled}
        >
          {paymentMethod &&
            paymentMethod.map((item, index) => {
              return (
                <Select.Option
                  title=' '
                  value={item.acctype_id}
                  key={
                    (item.acctype_id + ' ' + item.acctype_name, +' ' + index)
                  }
                >
                  {item.acctype_name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default SelectPaymentMethod;
