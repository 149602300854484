import { Typography } from 'antd';
import { Col, Row } from 'antd/lib/grid';
import { IViewInvoiceDetails } from '../../../common/types/commonInterfaces';
import { FormatDate } from '../../../common/utils/common.utils';
import { PhoneParser } from '../../../helpers/PhoneFormatter';
import { IViewInvoice } from '../../../modules/Invoices/Type/Invoice.interface';

type Props = { invoice_info: IViewInvoice };

const ClientInfo = ({ invoice_info }: Props) => {
  const {
    client_name,
    invoice_create_date,
    invoice_no,
    invoice_sales_date,
    sales_by,
  } = invoice_info;
  return (
    <Row justify={'space-between'} align='middle' className='mt-5 p-10'>
      <Col
        style={{
          color: '#fff',
        }}
      >
        <Typography.Title level={4}>INVOICE TO</Typography.Title>
        {client_name && (
          <Typography.Text
            style={{
              display: 'block',
              fontSize: '12px',
              fontFamily: "'Source Sans Pro', sans-serif",
            }}
          >
            Name : {client_name}
          </Typography.Text>
        )}
        {/* {client_address && (
          <Typography.Text
            style={{
              display: 'block',
              fontSize: '12px',
              fontFamily: "'Source Sans Pro', sans-serif",
            }}
          >
            Address : {client_address}
          </Typography.Text>
        )} */}
        {/* {client_email && (
          <Typography.Text
            style={{
              display: 'block',
              fontSize: '12px',
              fontFamily: "'Source Sans Pro', sans-serif",
            }}
          >
            Email : {client_email}
          </Typography.Text>
        )} */}
      </Col>

      <Col className='p-10'>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          Invoice Date : {FormatDate(invoice_create_date)}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          Invoice No: {invoice_no}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          Sales By : {sales_by}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          Sales Date : {FormatDate(invoice_sales_date)}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default ClientInfo;
