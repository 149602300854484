import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  theme,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { IViewAccountInvestments } from '../../AccountsTypes/AccountsTypes';
import { useGetViewInvestmentsQuery } from '../../Api/Endpoints/investmentsEndpoints';
import { ReceiptHeader } from '../../../../common/Invoice/InvoiceHeader';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { numberFormate } from '../../../../common/utils/numberFormatting';
const ViewInvestment = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetViewInvestmentsQuery(Number(id));
  const { Text, Title } = Typography;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Investments`,
  });
  const a4sizeStyle: React.CSSProperties = {
    minHeight: '11in',
    width: '8.27in',
    fontSize: '11px',
    background: '#fff',
    margin: '0 auto',
  };
  const column: ColumnsType<IViewAccountInvestments> = [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => index + 1,
    },

    {
      title: 'Date',
      key: 'investment_created_date',
      render: (_, record) => {
        return `${dayjs(record.investment_created_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Transaction Type',
      key: 'transaction_type',
      render: () => {
        return 'Investment';
      },
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    // {
    //   title: 'Cheque No.',
    //   dataIndex: 'investment_cheque_no',
    //   key: 'investment_cheque_no',
    // },
    // {
    //   title: 'Receipt/Trans No.',
    //   dataIndex: 'investment_receipt_no',
    //   key: 'investment_receipt_no',
    // },
    {
      title: 'Amount',
      dataIndex: 'actransaction_amount',
      key: 'actransaction_amount',
      render: (_, record) => numberFormate(Number(record.actransaction_amount)),
    },
  ];
  return (
    <>
      <BreadCrumb arrOfOption={['Dashboard', 'Accounts', 'Investments']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/accounts/investments'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Investment List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Card hoverable ref={componentRef} style={a4sizeStyle}>
          <Row justify={'space-between'} align='middle'>
            <ReceiptHeader />

            <Col>
              <Typography
                style={{
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Account : {data?.data && data?.data[0]?.account_name}
              </Typography>
            </Col>
          </Row>

          <Row justify='center' style={{ margin: '35px 0' }}>
            <Tag>
              <Title
                style={{
                  fontSize: '15px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
                level={4}
              >
                Investments
              </Title>
            </Tag>
          </Row>

          <Table
            size='small'
            bordered
            pagination={false}
            columns={column}
            dataSource={data?.data}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            loading={{ spinning: isLoading, indicator: <LoadingIndicator /> }}
          />

          <Row
            justify={'space-around'}
            align={'middle'}
            style={{
              margin: '25px 0',
            }}
          >
            <Typography
              style={{
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Note : {/* {singleExpense?.data?.expense_note} */}
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {data?.data && (
                <NumToWord
                  number={Number(
                    (data.data && data?.data[0].actransaction_amount) || 0
                  )}
                />
              )}
            </Typography>
          </Row>
          {/* ================== signature ===================== */}
          <Row
            className='signature'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '600px',
            }}
          >
            <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Received By
            </Typography.Text>
            <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Approved By
            </Typography.Text>
          </Row>
          <Row justify={'center'}>
            <Typography.Text
              style={{
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Copyright © {dayjs().format('YYYY')} M360 ICT
            </Typography.Text>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

export default ViewInvestment;
