import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import {
  useExpensesDataQuery,
  useGetDashboardSummaryQuery,
  useSalesReportQuery,
} from '../Api/Endpoints/DashboardEndpoints';
import Account_Details_Insight from '../Components/Account_Details_Insight';
import Account_Insight from '../Components/Account_Insight';
import NoticeBar from '../Components/NoticeBar';
import DailyReport from '../Components/DailyReport';
import ExpenseTotal from '../Components/ExpenseTotal';
import MonthlyReport from '../Components/MonthlyReport';
import ReportCardSkeleton from '../Components/ReportCardSkeleton';
import YearlyReport from '../Components/YearlyReport';
import '../style/dashboard.css';
import InvoiceDetails from '../Components/InvoiceDetails';

const Dashboard = () => {
  const { data: salesData, isLoading: reportCardLoading } =
    useSalesReportQuery();

  const { data } = useGetDashboardSummaryQuery();

  const { data: expenseData, isLoading: expenseLoading } =
    useExpensesDataQuery();

  const expenses = expenseData?.data;

  const summary = data?.data;
  const accountBalInsight = summary?.currAccStatus;
  const totalClVnCm = summary?.account_status;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const dark = localStorage.getItem('theme');

  useEffect(() => {
    if (dark === 'darkTheme') {
      setIsDarkMode(false);
    } else if (dark === 'defaultTheme') {
      setIsDarkMode(true);
    }
  }, [dark]);

  return (
    <div>
      <NoticeBar />

      <Row justify={'space-between'} gutter={[20, 40]}>
        {reportCardLoading ? (
          <ReportCardSkeleton />
        ) : (
          <>
            <Col xl={8} md={12} sm={12} xs={24}>
              <DailyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
            <Col xl={8} md={12} sm={12} xs={24}>
              <MonthlyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
            <Col xl={8} md={12} sm={12} xs={24}>
              <YearlyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
          </>
        )}
        <Col xl={8} md={12} sm={12} xs={24}>
          <Account_Details_Insight
            accountBalInsight={accountBalInsight}
            isDarkMode={isDarkMode}
          />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <Account_Insight isDarkMode={isDarkMode} />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <ExpenseTotal
            expenses={expenses}
            expenseLoading={expenseLoading}
            isDarkMode={isDarkMode}
          />
        </Col>
        <Col xs={24} sm={24} lg={24}>
          <InvoiceDetails isDarkMode={isDarkMode} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
