import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IADM } from './ViewInvoiceHeader';
import TextArea from 'antd/es/input/TextArea';
import { layout } from '../../../../modules/Expense/Pages/AddExpense';

type Props = {
  setAdm: React.Dispatch<React.SetStateAction<IADM>>;
  adm: IADM;
};

type fromSubmit = {
  adm_charge: string;
  adm_reason: string;
};

const TabInvoiceADM = ({ setAdm, adm }: Props) => {
  const user = useAppSelector((state: RootState) => state.user);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (value: fromSubmit) => {
    const body = {
      ...value,
      adm_inv_cat_id: adm.inv_cat,
      adm_inv_id: adm.inv_id,
    };
  };

  const handleOff = () => {
    setAdm((prev) => ({ ...prev, open: false }));
    form.resetFields();
  };

  return (
    <Modal
      title='Void Invoice'
      open={adm.open}
      onOk={() => handleOff()}
      onCancel={() => handleOff()}
      footer={false}
    >
      <>
        <Form
          layout='horizontal'
          labelAlign='left'
          {...layout}
          name='nest-messages'
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name='adm_charge'
            label='Charge'
            required
            style={{ marginTop: '20px' }}
          >
            <Input min={0} type='number' placeholder='ADM Charge' />
          </Form.Item>

          <Form.Item
            name='adm_reason'
            label='Reason'
            style={{ marginTop: '20px' }}
          >
            <TextArea placeholder='ADM Reason' />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
            <Button type='primary' htmlType='submit' loading={false}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};

export default TabInvoiceADM;

export const showModal = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(true);
};

export const handleOk = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

export const handleCancel = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};
