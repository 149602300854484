import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { TState } from '../../auth/states/userSlice';
import { rolePermissionType } from '../../common/types/commonTypes';
import AccountsReport from '../../modules/Reports/AccountsReport/pages/AccountsReport';
import ClientDiscount from '../../modules/Reports/ClientDiscount/pages/ClientDiscount';
import DailySalesReport from '../../modules/Reports/DailySalesReport/pages/DailySalesReport';
import DateWiseExpenseReport from '../../modules/Reports/DateWiseExpenseReport/pages/DateWiseExpenseReport';
import EmployeeExpenseReport from '../../modules/Reports/EmployeeExpenseReport/pages/EmployeeExpenseReport';
import SalesReportItemAndSalesMan from '../../modules/Reports/SalesReport(ItemAndSalesMan)/pages/SalesReportItemAndSalesMan';
import UserLoginHistory from '../../modules/Reports/UserLoginHistory/pages/UserLoginHistory';
import ClientLedgers from '../../modules/Reports/ledgers/pages/ClientLedgers';
import ActivityLogs from '../../modules/Reports/Activity_Log/pages/ActivityLogs';
import HeadWiseExpenseReport from '../../modules/Reports/HeadWiseExpenseReport/pages/HeadWiseExpenseReport';

// DASHBOARD
export const ReportRoutes = (
  permission: rolePermissionType | undefined,
  user: TState
) => (
  <>
    <Route
      path='/reports/client_ledger'
      element={
        <PermissionRoute
          permission={permission?.report_ledgers}
          element={<ClientLedgers />}
        />
      }
    />

    <Route
      path='/reports/airline_wise_sales_report'
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<></>}
        />
      }
    />

    <Route
      path='/reports/sales_report_item_and_salesman'
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<SalesReportItemAndSalesMan />}
        />
      }
    />

    {/* ==================== expense report ========================== */}

    <Route
      path='/reports/date_wise_expense_report'
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<DateWiseExpenseReport />}
        />
      }
    />

    <Route
      path='/reports/expense_report'
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<EmployeeExpenseReport />}
        />
      }
    />
    <Route
      path='/reports/head_wise_expense_report'
      element={
        <PermissionRoute
          permission={permission?.expense_report}
          element={<HeadWiseExpenseReport />}
        />
      }
    />
    <Route
      path='/reports/client_discount_note'
      element={
        <PermissionRoute
          permission={permission?.client_discount}
          element={<ClientDiscount />}
        />
      }
    />

    <Route
      path='/reports/daily_sales_report'
      element={
        <PermissionRoute
          permission={permission?.sales_report}
          element={<DailySalesReport />}
        />
      }
    />
    <Route
      path='/reports/accounts_report'
      element={
        <PermissionRoute
          permission={permission?.accounts_report}
          element={<AccountsReport />}
        />
      }
    />

    <Route
      path='/reports/user_login_history'
      element={
        <PermissionRoute
          permission={permission?.user_login_history}
          element={<UserLoginHistory />}
        />
      }
    />
    <Route
      path='/reports/activity_logs'
      element={
        <PermissionRoute
          permission={permission?.audit_trail}
          element={<ActivityLogs />}
        />
      }
    />
  </>
);
