import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { ACCOUNTREPORT } from '../../Api/constants';
import { IAccountReportCommonDataType } from '../types/accountReportTypes';

export const accountReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all AccountReport
    getAllAccountReport: build.query<
      HTTPResponse<IAccountReportCommonDataType[]>,
      {
        account_id: string | number;
        query: string;
      }
    >({
      query: ({ account_id, query }) => ({
        url: `/report/account-report/${account_id}${query}`,
      }),
      providesTags: [{ type: 'AccountReport', id: ACCOUNTREPORT }],
    }),
    // get all AccountReport
    getAllAccountList: build.query<
      HTTPResponse<{ account_id: number; account_name: string }[]>,
      void
    >({
      query: (body) => ({
        url: `/accounts/account-list`,
      }),
      providesTags: [{ type: 'AccountReport', id: ACCOUNTREPORT }],
    }),
  }),
});

export const { useLazyGetAllAccountReportQuery, useGetAllAccountListQuery } =
  accountReportEndpoints;
