import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Image,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Upload,
} from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload';
import { useState } from 'react';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  usePostEditAppConfigMutation,
  usePostEditAppConfigSignatureMutation,
} from '../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';
import { acceptedFileTypes } from '../../../../common/utils/FileUpload';

type Props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};

const AuthSignature = ({ configDetails, setConfigDetails }: Props) => {
  const [fileType, setFileType] = useState<RcFile>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [image, setImage] = useState<RcFile | undefined>();

  const [imageFile, setImageFile] = useState<string | undefined>('');

  const [postEditSignature] = usePostEditAppConfigSignatureMutation();
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let newImage = newFileList[0]?.originFileObj;

    const formData = new FormData();
    if (newImage) formData.append('tac_sig_url', newImage);
    postEditSignature(formData);
  };

  const beforeUpload = (file: RcFile) => {
    setFileType(file);
    const isJpgOrPng = acceptedFileTypes.includes(file.type);

    setImageFile(URL.createObjectURL(file));

    if (!isJpgOrPng) {
      toasterNotification(
        'error',
        'Accepted File Formats - JPG/JPEG/PNG/WEBP/PDF'
      );
      setImageFile(undefined);
      return isJpgOrPng || Upload.LIST_IGNORE;
    }
    const limitFileSize = file.size / 1024 / 1024 < 0.5;
    if (!limitFileSize) {
      toasterNotification('error', 'File size must be less than 500 KB');
      setImageFile(undefined);
      return limitFileSize || Upload.LIST_IGNORE;
    }
    return false;
  };

  const defaultValue = configDetails?.tac_signtr;
  const signatureURL = configDetails?.tac_sig_url;

  const [postEdit] = usePostEditAppConfigMutation();

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_signtr: e.target.value,
    };

    postEdit(body);
  };

  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Radio.Group onChange={onChange} value={defaultValue}>
            <Space direction='vertical'>
              <Radio value={0}>No Signature</Radio>
              <Radio value={1}>Signature</Radio>
            </Space>
          </Radio.Group>
        </Col>

        <Col>
          <div>
            <Upload
              maxCount={1}
              accept={'image/jpeg,image/jpeg,image/png,image/webp'}
              name='image'
              beforeUpload={beforeUpload}
              onRemove={() => {
                setImageFile(undefined);
              }}
              onChange={handleChange}
            >
              <Button icon={<UploadOutlined />}>Upload Signature</Button>
            </Upload>
          </div>
          {imageFile &&
            (fileType?.type == 'application/pdf' ? (
              <iframe src={imageFile} width={100} />
            ) : (
              <Image src={imageFile} width={100} />
            ))}

          {!imageFile && signatureURL && (
            <Image style={{ marginTop: 10 }} src={signatureURL} width={100} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AuthSignature;
