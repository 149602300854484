import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { Fixed2 } from '../../../common/utils/common.utils';
import FormHeaderTitle from '../common/FormHeaderTitle';
import { IBillingInfo, IViewInvoice } from '../Type/Invoice.interface';
import CostViewSubTotal from '../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal';
import dayjs from 'dayjs';
import { numberFormate } from '../../../common/utils/numberFormatting';

type Props = {
  billing_information: IBillingInfo[];
  invoiceData: IViewInvoice | undefined;
};
const ViewInvoiceOtherBillingInfo = ({
  billing_information,
  invoiceData,
}: Props) => {
  const billingColumn: ColumnsType<IBillingInfo> = [
    {
      title: 'SL',
      align: 'center',
      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: 'Flight Date',
      dataIndex: 'billing_flight_date',
      key: 'billing_flight_date',
      width: 100,
      render: (_, value) =>
        value.billing_flight_date &&
        dayjs(value.billing_flight_date).format('DD-MMM-YYYY'),
    },
    {
      title: 'Pax Name',
      dataIndex: 'billing_pax',
      key: 'billing_pax',
      width: 100,
      render: (billing_pax) => (billing_pax ? billing_pax : 'N/A'),
    },
    {
      title: 'Route',
      dataIndex: 'billing_route',
      key: 'billing_route',
      render: (billing_route) => (billing_route ? billing_route : 'N/A'),
    },
    {
      title: 'PNR',
      dataIndex: 'billing_pnr',
      key: 'billing_pnr',
      render: (billing_pnr) => (billing_pnr ? billing_pnr : 'N/A'),
    },

    {
      title: 'Total Pax',
      dataIndex: 'billing_total_pax',
      key: 'billing_total_pax',
      width: '150px',
      align: 'right',
    },
    {
      title: 'BDT Equivalent',
      dataIndex: 'billing_usd_equivalent',
      key: 'billing_usd_equivalent',
      width: '150px',
      align: 'right',
      render: (_, value) => numberFormate(Fixed2(value.billing_usd_equivalent)),
    },
    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      width: '120px',
      align: 'right',
      render: (_, { billing_total_pax, billing_usd_equivalent }) =>
        billing_total_pax && billing_usd_equivalent
          ? numberFormate(
              Number(billing_total_pax) * Number(billing_usd_equivalent)
            )
          : 'N/A',
    },
  ];

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const billing_information_ref = useRef<HTMLDivElement>(null);
  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  return (
    <div>
      <div
        ref={billing_information_ref}
        style={{
          // footer margin control
          marginTop:
            pageController &&
            pageController[4].identifier === 'billing_information'
              ? `${pageController[4].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[4].identifier === 'billing_information' &&
            pageController[4].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[4].identifier === 'billing_information' &&
            pageController[4].marginTop
              ? '0.5in'
              : '',
        }}
      >
        <>
          <FormHeaderTitle title='Billing Information' />
          <Table
            size='small'
            bordered
            rowKey={(e) => Math.random()}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceData?.billing}
            columns={billingColumn}
            pagination={false}
          />
          <CostViewSubTotal invoice_info={invoiceData} />
        </>
      </div>

      {/* THIS IS CREATE ONLY FOR CONTROLL FOOTER */}
      <div
        style={{
          // footer margin control
          marginTop:
            pageController && pageController[5].identifier === 'footer'
              ? `${pageController[5].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[5].identifier === 'footer' &&
            pageController[5].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[5].identifier === 'footer' &&
            pageController[5].marginTop
              ? '0.5in'
              : '',
        }}
      ></div>
    </div>
  );
};

export default ViewInvoiceOtherBillingInfo;
