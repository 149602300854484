import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { rolePermissionType } from '../../common/types/commonTypes';
import { getListPermission } from '../../common/utils/common.utils';
import EditUser from '../../modules/Configuration/Users/pages/EditUser';
import ViewRoles from '../../modules/Configuration/Users/pages/ViewRoles';
import ViewUser from '../../modules/Configuration/Users/pages/ViewUser';
import AppConfig from '../../modules/Configuration/appConfig/pages/AppConfig';
import ClientCategory from '../../modules/Configuration/clientCategory/pages/ClientCategory';
import Companies from '../../modules/Configuration/companies/pages/Companies';
import Departments from '../../modules/Configuration/department/pages/Departments';
import Designation from '../../modules/Configuration/designation/pages/Designation';
import Employee from '../../modules/Configuration/employee/pages/Employee';
import ExpenseHead from '../../modules/Configuration/expenseHead/pages/ExpenseHead';

// DASHBOARD
export const ConfigurationRoutes = (
  permission: rolePermissionType | undefined,
  isAdmin: string[] | undefined
) => (
  <>
    {/* USERS */}
    <>
      <Route
        path='/users/edit/:user_id'
        element={
          <PermissionRoute permission={isAdmin} element={<EditUser />} />
        }
      />
      <Route
        path='/user/view'
        element={
          <PermissionRoute permission={isAdmin} element={<ViewUser />} />
        }
      />
      <Route
        path='/role/view'
        element={
          <PermissionRoute permission={isAdmin} element={<ViewRoles />} />
        }
      />

      {/* CONFIGURATIONS */}
      <>
        <Route
          path='/app-configuration'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.client_category)}
              element={<AppConfig />}
            />
          }
        />

        <Route
          path='/clients/categories'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.client_category)}
              element={
                <ClientCategory permission={permission?.client_category} />
              }
            />
          }
        />
        <Route
          path='/companies'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.companies)}
              element={<Companies permission={permission?.companies} />}
            />
          }
        />

        <Route
          path='/departments'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.departments)}
              element={<Departments permission={permission?.departments} />}
            />
          }
        />

        <Route
          path='/designation/all_designation'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.designations)}
              element={<Designation permission={permission?.designations} />}
            />
          }
        />
        <Route
          path='/employee'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.employee)}
              element={<Employee permission={permission?.employee} />}
            />
          }
        />
        <Route
          path='/head'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.expense_head)}
              element={<ExpenseHead permission={permission?.expense_head} />}
            />
          }
        />
      </>
    </>
  </>
);
