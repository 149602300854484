import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IPermission,
  ISetState,
  crud,
} from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { IAccountInvestments } from '../../AccountsTypes/AccountsTypes';
import { useDeleteInvestmentMutation } from '../../Api/Endpoints/investmentsEndpoints';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { numberFormate } from '../../../../common/utils/numberFormatting';

export const ListOfInvestmentsColumn = ({
  setEditInfo,
  showModal,
  permission,

  pagination,
}: {
  setEditInfo: ISetState<IAccountInvestments | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAccountInvestments> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [
    deleteInvestment,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteInvestmentMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isDeleteError) {
      setColumnIndex(null);
      toasterNotification('error', 'Something went wrong');
    }
  }, [isDeleteError, isSuccess]);

  const handleClick = (record: IAccountInvestments) => {
    setEditInfo(record);
    showModal();
  };

  const handleDelete = async (values: IAccountInvestments, index: number) => {
    setColumnIndex(index);
    await deleteInvestment({ ...values });
  };

  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'investment_created_date',
      render: (_, record) => {
        return `${dayjs(record.investment_created_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Vouchar No',
      key: 'investment_vouchar_no',
      dataIndex: 'investment_vouchar_no',
    },

    {
      title: 'Transaction Type',
      key: 'transaction_type',
      render: () => {
        return 'Investment';
      },
    },
    {
      title: 'Transaction Details',
      key: 'transaction_details',
      render: (_, record) =>
        ` ${record.account_name}  ${
          record.account_branch_name ? ', ' + record.account_branch_name : ''
        }`,
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    // {
    //   title: 'Cheque No.',
    //   dataIndex: 'investment_cheque_no',
    //   key: 'investment_cheque_no',
    // },
    // {
    //   title: 'Receipt/Trans No.',
    //   dataIndex: 'investment_receipt_no',
    //   key: 'investment_receipt_no',
    // },
    {
      title: 'Amount',
      dataIndex: 'actransaction_amount',
      key: 'actransaction_amount',
      render: (_, record) => numberFormate(Number(record.actransaction_amount)),
    },
    {
      title: 'Note',
      dataIndex: 'investment_note',
      key: 'investment_note',
    },

    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => (
        <Space size='small'>
          {
            <Link
              to={`/accounts/investments/view/${record.investment_id}`}
              state={location.pathname}
            >
              <Button icon={<EyeOutlined />} size='small' type='link'></Button>
            </Link>
          }
          {permission?.[crud.update] && (
            <Button
              size='small'
              type='link'
              icon={<EditOutlined />}
              onClick={() => handleClick(record)}
            ></Button>
          )}

          {permission?.[crud.delete] && record.investment_is_deleted === 0 && (
            <Button
              size='small'
              type='link'
              danger
              loading={columnIndex === index && true}
            >
              {' '}
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
