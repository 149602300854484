import { Row, Space, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import { setModal } from '../../../../../common/slices/commonSlice';
import { IPermission } from '../../../../../common/types/commonTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { IAccountIncentiveIncome } from '../../../AccountsTypes/AccountsTypes';
import { useGetClientIncentiveIncomesQuery } from '../endpoints/client_IncentiveIncomeEndpoints';
import Client_incentive_Add_Modal from '../modal/client/Client_incentive_Add_Modal';
import { client_IncentiveIncomeColumn } from '../utils/client/client_IncentiveIncomeColumn';

type Props = {
  tabChanged: string;
  permission?: IPermission;
};

const Client_Incentive_Income = ({ permission, tabChanged }: Props) => {
  const dispatch = useAppDispatch();
  const [checkButtonState, setCheckButtonState] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [editInfo, setEditInfo] = useState<IAccountIncentiveIncome | null>(
    null
  );
  const { data, isLoading, isFetching } = useGetClientIncentiveIncomesQuery({
    ...pagination,
    trash: checkButtonState ? 1 : 0,
  });
  const showModal = () => {
    dispatch(setModal(true));
  };

  const handleTrashButton = () => {
    if (checkButtonState == false) {
      setCheckButtonState(true);
    } else {
      setCheckButtonState(false);
    }
  };

  return (
    <div>
      <Space style={{ marginBottom: '1rem' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          <Client_incentive_Add_Modal
            type={tabChanged}
            permission={permission}
          />
        </Row>
      </Space>

      <Table
        size='small'
        bordered
        rowKey={(e) => Number(e.incentive_id)}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        columns={client_IncentiveIncomeColumn({
          pagination,
          checkButtonState,
          permission,
        })}
        dataSource={data?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: <LoadingIndicator />,
        }}
      />
    </div>
  );
};

export default Client_Incentive_Income;
