import { LogoutOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { TUser } from '../../auth/states/userSlice';
import { ChangePassword } from '../../components/common/FormItem/ChangeUserPassword';

export const userContent = (
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  form: FormInstance<any>,
  handleLogout: () => void,
  user: TUser
) => {
  const agencyInfo = user?.organization_info;

  const userContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography.Title level={5}>{agencyInfo?.org_name}</Typography.Title>
      <img
        width={100}
        style={{ background: '#fff', padding: 10, borderRadius: 5 }}
        src={agencyInfo?.org_logo || 'Trabill'}
        alt={agencyInfo?.org_name || 'Trabill'}
      />
      <Typography.Title level={5}>{user?.user_full_name}</Typography.Title>
      <Button
        style={{ marginTop: 10, overflow: 'hidden' }}
        size='small'
        type='default'
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Change Password
      </Button>
      <Button
        style={{ marginTop: 10, width: '100%' }}
        size='small'
        type='default'
        danger
      >
        <Popconfirm
          placement='bottom'
          onConfirm={() => handleLogout()}
          title='Sure to logout?'
        >
          <Typography>
            <LogoutOutlined /> Sign Out
          </Typography>
        </Popconfirm>
      </Button>
      <Modal
        title='Change password'
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        footer={null}
      >
        <ChangePassword setIsmodal={setIsModalOpen} />
      </Modal>
    </div>
  );

  return userContent;
};
