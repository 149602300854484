import type { RadioChangeEvent } from 'antd';
import { Radio, Space, Typography } from 'antd';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';

type Props = {
  configDetails: IGetAppConfig | undefined;
};

const InvoiceCalculation = ({ configDetails }: Props) => {
  const [postEdit] = usePostEditAppConfigMutation();

  const defaultValue = configDetails?.tac_ait_cal;

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_ait_cal: e.target.value,
    };

    postEdit(body);
  };
  return (
    <div className='mt-30'>
      <Typography.Text strong>
        {' '}
        <h5>AIT Tax Calculation Presets: </h5>
      </Typography.Text>
      <Radio.Group onChange={onChange} value={defaultValue}>
        <Space direction='vertical'>
          <Radio value={'1'}>DEFAULT</Radio>
          <Radio value={'TAX_1_EXTRA_FIELD'}>TAX_1 EXTRA FIELD</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default InvoiceCalculation;
