import { Card, Col, Image, QRCode, Row, Typography } from 'antd';
import { useAppSelector } from '../../app/hooks';
import { invoiceViewTitleStyle } from '../../modules/Money_Receipt/Components/MoneyReceiptDesign';

type Props = {
  qrCodeValue?: string;
};

export const officeInfo = {
  name: '',
  address1: '',
  address2: '',
  modile: '',
};

export const GetInfo = () => {
  const Org_Info = useAppSelector((state) => state.user?.organization_info);
  return {
    logoProp: {
      height: Org_Info?.org_logo_height || 100,
      padding: 10,
      borderRadius: 12,
    },
    currencyName: {
      name: Org_Info?.org_currency,
    },
  };
};

export const LogoStyle = () => {
  const logoHeight = useAppSelector(
    (state) => state.user?.organization_info?.org_logo_height
  );
  return {
    height: logoHeight || 100,
    padding: 10,
    borderRadius: 12,
  };
};

export const InvoiceHeader = (props: Props) => {
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_wtr_mark } = appConfig;
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  return (
    <div>
      {/* Add Watermark on Invoice */}
      {tac_wtr_mark !== 0 ? (
        <div
          style={{
            position: 'absolute',
            top: '25%',
            left: 0,
            pointerEvents: 'none',
            fontSize: 150,
            opacity: 0.05,
            zIndex: 9,
            userSelect: 'none',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '8in',
              justifyContent: 'center',
            }}
          >
            <img
              src={appConfig?.tac_wtr_mark_url || orgInfo?.org_logo}
              alt={orgInfo?.org_name}
              style={{ maxWidth: 600, userSelect: 'none' }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <Row
        style={{
          fontFamily: "'Source Sans Pro', sans-serif",
          borderBottom: '2px solid #F9F5F6',
        }}
        justify={'space-between'}
        align='middle'
      >
        <Col style={{ display: 'flex', alignItems: 'center', maxWidth: '40%' }}>
          {/* <img
            src={orgInfo?.org_logo || '/m360Ict_Logo.png'}
            alt={orgInfo?.org_name || 'M360-ICT'}
            style={GetInfo().logoProp}
          /> */}
          <svg
            className='org_logo item'
            xmlns='http://www.w3.org/2000/svg'
            width='148.121'
            height='63.898'
            viewBox='0 0 148.121 63.898'
          >
            <g
              id='Group_152'
              data-name='Group 152'
              transform='translate(510.451 -332.666)'
            >
              <path
                id='Path_94'
                data-name='Path 94'
                d='M-298.767,349.852c-8.276.029-14.541,6.445-14.688,14.973,0,.044,0,.088,0,.132v.176c0,.046,0,.088,0,.132.148,8.558,6.416,14.973,14.7,14.973a17.78,17.78,0,0,0,2.927-.208,3.188,3.188,0,0,0,2.538-3.722A3.169,3.169,0,0,0-297,373.782a13.476,13.476,0,0,1-1.756.1c-4.885,0-8.306-3.629-8.344-8.836.038-5.188,3.462-8.818,8.333-8.835,4.921.017,8.358,3.687,8.358,8.923v11.927a3.177,3.177,0,0,0,3.179,3.178,3.177,3.177,0,0,0,3.178-3.178V365.133c0-8.683-6.316-15.252-14.715-15.281'
                transform='translate(-104.441 -9.112)'
                fill='#00b7ac'
              />
              <path
                id='Path_95'
                data-name='Path 95'
                d='M-459.45,333.04a3.179,3.179,0,0,0-3.179,3.179v31.175a3.177,3.177,0,0,0,3.179,3.178,3.177,3.177,0,0,0,3.178-3.178V336.219a3.179,3.179,0,0,0-3.178-3.179'
                transform='translate(-25.354 -0.198)'
                fill='#00b7ac'
              />
              <path
                id='Path_96'
                data-name='Path 96'
                d='M-494.193,332.763c-.258-.008-.521-.025-.781-.039-.446-.027-.954-.058-1.5-.058-8.38,0-13.526,5.211-13.954,14.012a3.417,3.417,0,0,0-.027.43V367.2a3.177,3.177,0,0,0,3.179,3.178,3.177,3.177,0,0,0,3.178-3.178V347.884c0-7.941,4.352-8.86,7.624-8.86.353,0,.72.022,1.114.045.327.02.65.038.976.049a3.176,3.176,0,0,0,3.27-3.083,3.178,3.178,0,0,0-3.083-3.272'
                transform='translate(0 0)'
                fill='#00b7ac'
              />
              <path
                id='Path_97'
                data-name='Path 97'
                d='M-489.633,363.488a3.172,3.172,0,0,0-3.174,3.174,3.172,3.172,0,0,0,3.174,3.173,3.174,3.174,0,0,0,3.176-3.173,3.174,3.174,0,0,0-3.176-3.174'
                transform='translate(-9.354 -16.341)'
                fill='#00b7ac'
              />
              <path
                id='Path_98'
                data-name='Path 98'
                d='M-414.607,349.787a3.179,3.179,0,0,0-4.259,1.443l-8.31,16.843-8.333-16.846a3.179,3.179,0,0,0-4.259-1.439,3.177,3.177,0,0,0-1.441,4.258l10.49,21.205-4.454,9.027a3.181,3.181,0,0,0,1.446,4.257,3.178,3.178,0,0,0,1.4.328,3.177,3.177,0,0,0,2.852-1.773l16.308-33.047a3.178,3.178,0,0,0-1.443-4.257'
                transform='translate(-36.534 -8.902)'
                fill='#00b7ac'
              />
              <path
                id='Path_99'
                data-name='Path 99'
                d='M-365.423,349.848c-7.966.028-13.973,6.6-13.973,15.282v11.926a3.181,3.181,0,0,0,3.18,3.178,3.179,3.179,0,0,0,3.179-3.178V365.13c0-5.237,3.143-8.906,7.614-8.923,4.495.017,7.635,3.686,7.635,8.923v11.926a3.179,3.179,0,0,0,3.179,3.178,3.181,3.181,0,0,0,3.181-3.178V365.13c0-8.682-6.009-15.253-14-15.282'
                transform='translate(-69.482 -9.109)'
                fill='#00b7ac'
              />
              <path
                id='Path_100'
                data-name='Path 100'
                d='M-221.4,370.978v.085c0,5.652-4.166,9.163-10.87,9.163a19.305,19.305,0,0,1-11.171-3.567,2.953,2.953,0,0,1-1.192-2.39,2.914,2.914,0,0,1,2.97-2.926,3.04,3.04,0,0,1,1.814.576,12.7,12.7,0,0,0,7.708,2.711c2.766,0,4.418-1.093,4.418-2.925v-.086c0-1.835-1.312-2.674-6.1-3.9-5.569-1.423-9.828-2.989-9.828-9.038v-.085c0-5.244,4.259-8.905,10.358-8.905a17.3,17.3,0,0,1,9.427,2.631,2.911,2.911,0,0,1,1.4,2.514,2.917,2.917,0,0,1-2.968,2.928,3.022,3.022,0,0,1-1.611-.458,12.577,12.577,0,0,0-6.333-2.021c-2.4,0-3.948,1.065-3.948,2.712v.084c0,2.068,1.391,2.718,6.527,4.032,5.642,1.473,9.4,3.542,9.4,8.867'
                transform='translate(-140.93 -9.025)'
                fill='#00b7ac'
              />
              <path
                id='Path_101'
                data-name='Path 101'
                d='M-277.181,441.418a1.008,1.008,0,0,0-1.006-1.006,1.009,1.009,0,0,0-1.013,1.006v9.07h-3.879c-.949,0-3.843,0-3.843-4.4v-4.666a1.009,1.009,0,0,0-1.01-1.006,1.006,1.006,0,0,0-1.006,1.006v4.666c0,4.08,2.137,6.418,5.859,6.418h4.908a1,1,0,0,0,.99-1.012v-.066c0-.038,0-.076,0-.121Z'
                transform='translate(-117.441 -57.124)'
                fill='#00b7ac'
              />
              <path
                id='Path_102'
                data-name='Path 102'
                d='M-258.317,442.97v-11.5a1.006,1.006,0,0,0-1-1,1.009,1.009,0,0,0-1.011,1v11.5a1.014,1.014,0,0,0,1.011,1.016,1.011,1.011,0,0,0,1-1.016'
                transform='translate(-132.608 -51.851)'
                fill='#00b7ac'
              />
              <path
                id='Path_103'
                data-name='Path 103'
                d='M-276.8,432.263a1.032,1.032,0,0,0,.714-.291,1.021,1.021,0,0,0,.292-.712,1.029,1.029,0,0,0-.295-.717,1.024,1.024,0,0,0-.711-.292,1.015,1.015,0,0,0-.716.293,1.029,1.029,0,0,0-.295.717,1.02,1.02,0,0,0,.295.713,1.019,1.019,0,0,0,.716.29'
                transform='translate(-123.342 -51.737)'
                fill='#00b7ac'
              />
              <path
                id='Path_104'
                data-name='Path 104'
                d='M-312.639,435.1a1.013,1.013,0,0,0-1.013,1.006v5.8h-3.62c-.952,0-3.847,0-3.847-4.4v-6.091a1.007,1.007,0,0,0-1-1.01,1.012,1.012,0,0,0-1.013,1.01V437.5c0,4.079,2.137,6.419,5.863,6.419h4.379a.763.763,0,0,0,.1-.007l.027,0,.028,0a.675.675,0,0,0,.1.007,1,1,0,0,0,1-1.005V436.1a1,1,0,0,0-1-1.006'
                transform='translate(-99.31 -51.816)'
                fill='#00b7ac'
              />
              <path
                id='Path_105'
                data-name='Path 105'
                d='M-302.067,432.266a1.025,1.025,0,0,0,.716-.295,1.022,1.022,0,0,0,.295-.712,1.021,1.021,0,0,0-.3-.716,1.036,1.036,0,0,0-.714-.295,1.024,1.024,0,0,0-.714.3,1.019,1.019,0,0,0-.295.714,1.02,1.02,0,0,0,.3.716,1.013,1.013,0,0,0,.711.291'
                transform='translate(-109.946 -51.736)'
                fill='#00b7ac'
              />
              <path
                id='Path_106'
                data-name='Path 106'
                d='M-356.021,440.363a1.006,1.006,0,0,0-1.006,1.007v3.409c0,.1-.02,2.411-1.824,2.411h-3.069l.12-.278a5.467,5.467,0,0,0,.448-2.133v-3.409a1.008,1.008,0,0,0-1.006-1.007,1.009,1.009,0,0,0-1.011,1.007v3.409c0,.1-.023,2.411-1.821,2.411h-2.463v-5.83a1.007,1.007,0,0,0-1.008-1,1.006,1.006,0,0,0-1.009,1v9.069h-3.795c-.949,0-3.844,0-3.844-4.4v-4.668a1.007,1.007,0,0,0-1.008-1,1.006,1.006,0,0,0-1.009,1v4.668c0,4.08,2.137,6.419,5.861,6.419h4.67c.027,0,.047,0,.066,0a.571.571,0,0,1,.061,0h.007a1.011,1.011,0,0,0,1.008-1.01v-2.23h8.956a.981.981,0,0,0,.124-.008c2.615-.177,3.559-2.742,3.559-4.42v-3.409a1.008,1.008,0,0,0-1.008-1.007'
                transform='translate(-69.52 -57.094)'
                fill='#00b7ac'
              />
              <path
                id='Path_107'
                data-name='Path 107'
                d='M-237.631,462.845a1.008,1.008,0,0,0-.711.3,1,1,0,0,0-.3.714,1.029,1.029,0,0,0,.3.716,1.015,1.015,0,0,0,.711.295,1.032,1.032,0,0,0,.714-.3,1.019,1.019,0,0,0,.292-.714,1.009,1.009,0,0,0-.295-.714,1.019,1.019,0,0,0-.711-.3'
                transform='translate(-144.104 -69.018)'
                fill='#00b7ac'
              />
              <path
                id='Path_108'
                data-name='Path 108'
                d='M-243.923,462.845a1.035,1.035,0,0,0-.714.3,1.01,1.01,0,0,0-.3.712,1.043,1.043,0,0,0,.3.716,1.033,1.033,0,0,0,.711.295,1.021,1.021,0,0,0,.714-.3,1.016,1.016,0,0,0,.3-.714,1.009,1.009,0,0,0-.3-.714,1.013,1.013,0,0,0-.714-.3'
                transform='translate(-140.771 -69.018)'
                fill='#00b7ac'
              />
              <path
                id='Path_109'
                data-name='Path 109'
                d='M-235.1,433.4a8.531,8.531,0,0,0-5.952,1.947l-.081.071c-.139.12-.281.247-.426.38l-.333.305V431.47a1.007,1.007,0,0,0-1.006-1,1.009,1.009,0,0,0-1.008,1v8.159c0,.026.007.041.007.058a.317.317,0,0,1-.007.066,1.033,1.033,0,0,0,.3.724,1.015,1.015,0,0,0,.709.3,1.051,1.051,0,0,0,.716-.3,1.085,1.085,0,0,0,.242-.424l.066-.215h0a3.443,3.443,0,0,1,.322-.779,7.924,7.924,0,0,1,.84-1.208,7.723,7.723,0,0,1,.92-.891l.079-.071a6.466,6.466,0,0,1,4.531-1.444c2.938.107,3.527,1.3,3.527,3.19v3.333h-16.689l.121-.276a5.5,5.5,0,0,0,.448-2.135V436.1a1.007,1.007,0,0,0-1.008-1.006,1.009,1.009,0,0,0-1.008,1.006v3.453c0,.1-.023,2.411-1.821,2.411h-2.057V436.1a1.007,1.007,0,0,0-1.006-1.006,1.008,1.008,0,0,0-1.006,1.006v6.975a3.932,3.932,0,0,1-2.281,3.388,1.013,1.013,0,0,0-.589,1.3,1.027,1.027,0,0,0,1.307.584,5.947,5.947,0,0,0,3.469-4.2l.034-.159h23.125a1.013,1.013,0,0,0,1.008-1.016v-4.337c0-3.341-1.849-5.1-5.5-5.234'
                transform='translate(-133.522 -51.851)'
                fill='#00b7ac'
              />
            </g>
          </svg>
        </Col>

        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: '60%',
          }}
        >
          <div>
            <QRCode
              size={145}
              color='black'
              bordered={false}
              value={`
Agency Name: ${orgInfo?.org_name || 'M360 ICT'}
Address: ${orgInfo?.org_address1 || ''}
Mobile No: ${orgInfo?.org_mobile || officeInfo?.modile}
`}
            />
          </div>
          <div>
            <Typography.Title level={4} style={{}}>
              {orgInfo?.org_name || ''}
            </Typography.Title>

            {orgInfo?.org_address1 ? (
              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
                className=''
              >
                {orgInfo?.org_address2 ? (
                  <>
                    <strong>Address: </strong>
                    <span>{orgInfo?.org_address1}</span>
                  </>
                ) : (
                  orgInfo?.org_address1
                )}
              </Typography.Text>
            ) : (
              ''
            )}

            {orgInfo?.org_address2 && (
              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {orgInfo?.org_address2}
              </Typography.Text>
            )}

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              <strong>Mobile:</strong> {orgInfo?.org_mobile}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              <strong>Email:</strong> {orgInfo?.org_owner_email}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_extra_info}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_website}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_facebook}
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const TitleCenter = ({ title }: { title: string }) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);

  return (
    <Row justify={'center'}>
      <Typography.Title level={5} style={invoiceViewTitleStyle}>
        {title}
      </Typography.Title>
    </Row>
  );
};

export const ReceiptHeader = ({ qrCodeValue }: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const appConfig = useAppSelector((state) => state.configSlice);

  const qrCodeValueInNotGiven = `
Name:${orgInfo?.org_name}
Address:${orgInfo?.org_address1}
${orgInfo?.org_address2}
Mobile:${orgInfo?.org_mobile}
`;

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Add Watermark on Money Receipt */}
      {appConfig.tac_wtr_mark !== 0 ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            opacity: 0.05,
            zIndex: 1,
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '8in',
              height: '100%',
              marginTop: '6%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={appConfig?.tac_wtr_mark_url || orgInfo?.org_logo}
              alt={orgInfo?.org_name}
              style={{ maxWidth: 600, userSelect: 'none' }}
            />
          </div>
        </div>
      ) : null}

      <div style={{ width: '50%' }}>
        {/* <img
          style={GetInfo().logoProp}
          src={orgInfo?.org_logo || '/m360Ict_Logo.png'}
          alt={orgInfo?.org_name || 'Trabill'}
        /> */}
        <svg
          className='org_logo item'
          xmlns='http://www.w3.org/2000/svg'
          width='148.121'
          height='63.898'
          viewBox='0 0 148.121 63.898'
        >
          <g
            id='Group_152'
            data-name='Group 152'
            transform='translate(510.451 -332.666)'
          >
            <path
              id='Path_94'
              data-name='Path 94'
              d='M-298.767,349.852c-8.276.029-14.541,6.445-14.688,14.973,0,.044,0,.088,0,.132v.176c0,.046,0,.088,0,.132.148,8.558,6.416,14.973,14.7,14.973a17.78,17.78,0,0,0,2.927-.208,3.188,3.188,0,0,0,2.538-3.722A3.169,3.169,0,0,0-297,373.782a13.476,13.476,0,0,1-1.756.1c-4.885,0-8.306-3.629-8.344-8.836.038-5.188,3.462-8.818,8.333-8.835,4.921.017,8.358,3.687,8.358,8.923v11.927a3.177,3.177,0,0,0,3.179,3.178,3.177,3.177,0,0,0,3.178-3.178V365.133c0-8.683-6.316-15.252-14.715-15.281'
              transform='translate(-104.441 -9.112)'
              fill='#00b7ac'
            />
            <path
              id='Path_95'
              data-name='Path 95'
              d='M-459.45,333.04a3.179,3.179,0,0,0-3.179,3.179v31.175a3.177,3.177,0,0,0,3.179,3.178,3.177,3.177,0,0,0,3.178-3.178V336.219a3.179,3.179,0,0,0-3.178-3.179'
              transform='translate(-25.354 -0.198)'
              fill='#00b7ac'
            />
            <path
              id='Path_96'
              data-name='Path 96'
              d='M-494.193,332.763c-.258-.008-.521-.025-.781-.039-.446-.027-.954-.058-1.5-.058-8.38,0-13.526,5.211-13.954,14.012a3.417,3.417,0,0,0-.027.43V367.2a3.177,3.177,0,0,0,3.179,3.178,3.177,3.177,0,0,0,3.178-3.178V347.884c0-7.941,4.352-8.86,7.624-8.86.353,0,.72.022,1.114.045.327.02.65.038.976.049a3.176,3.176,0,0,0,3.27-3.083,3.178,3.178,0,0,0-3.083-3.272'
              transform='translate(0 0)'
              fill='#00b7ac'
            />
            <path
              id='Path_97'
              data-name='Path 97'
              d='M-489.633,363.488a3.172,3.172,0,0,0-3.174,3.174,3.172,3.172,0,0,0,3.174,3.173,3.174,3.174,0,0,0,3.176-3.173,3.174,3.174,0,0,0-3.176-3.174'
              transform='translate(-9.354 -16.341)'
              fill='#00b7ac'
            />
            <path
              id='Path_98'
              data-name='Path 98'
              d='M-414.607,349.787a3.179,3.179,0,0,0-4.259,1.443l-8.31,16.843-8.333-16.846a3.179,3.179,0,0,0-4.259-1.439,3.177,3.177,0,0,0-1.441,4.258l10.49,21.205-4.454,9.027a3.181,3.181,0,0,0,1.446,4.257,3.178,3.178,0,0,0,1.4.328,3.177,3.177,0,0,0,2.852-1.773l16.308-33.047a3.178,3.178,0,0,0-1.443-4.257'
              transform='translate(-36.534 -8.902)'
              fill='#00b7ac'
            />
            <path
              id='Path_99'
              data-name='Path 99'
              d='M-365.423,349.848c-7.966.028-13.973,6.6-13.973,15.282v11.926a3.181,3.181,0,0,0,3.18,3.178,3.179,3.179,0,0,0,3.179-3.178V365.13c0-5.237,3.143-8.906,7.614-8.923,4.495.017,7.635,3.686,7.635,8.923v11.926a3.179,3.179,0,0,0,3.179,3.178,3.181,3.181,0,0,0,3.181-3.178V365.13c0-8.682-6.009-15.253-14-15.282'
              transform='translate(-69.482 -9.109)'
              fill='#00b7ac'
            />
            <path
              id='Path_100'
              data-name='Path 100'
              d='M-221.4,370.978v.085c0,5.652-4.166,9.163-10.87,9.163a19.305,19.305,0,0,1-11.171-3.567,2.953,2.953,0,0,1-1.192-2.39,2.914,2.914,0,0,1,2.97-2.926,3.04,3.04,0,0,1,1.814.576,12.7,12.7,0,0,0,7.708,2.711c2.766,0,4.418-1.093,4.418-2.925v-.086c0-1.835-1.312-2.674-6.1-3.9-5.569-1.423-9.828-2.989-9.828-9.038v-.085c0-5.244,4.259-8.905,10.358-8.905a17.3,17.3,0,0,1,9.427,2.631,2.911,2.911,0,0,1,1.4,2.514,2.917,2.917,0,0,1-2.968,2.928,3.022,3.022,0,0,1-1.611-.458,12.577,12.577,0,0,0-6.333-2.021c-2.4,0-3.948,1.065-3.948,2.712v.084c0,2.068,1.391,2.718,6.527,4.032,5.642,1.473,9.4,3.542,9.4,8.867'
              transform='translate(-140.93 -9.025)'
              fill='#00b7ac'
            />
            <path
              id='Path_101'
              data-name='Path 101'
              d='M-277.181,441.418a1.008,1.008,0,0,0-1.006-1.006,1.009,1.009,0,0,0-1.013,1.006v9.07h-3.879c-.949,0-3.843,0-3.843-4.4v-4.666a1.009,1.009,0,0,0-1.01-1.006,1.006,1.006,0,0,0-1.006,1.006v4.666c0,4.08,2.137,6.418,5.859,6.418h4.908a1,1,0,0,0,.99-1.012v-.066c0-.038,0-.076,0-.121Z'
              transform='translate(-117.441 -57.124)'
              fill='#00b7ac'
            />
            <path
              id='Path_102'
              data-name='Path 102'
              d='M-258.317,442.97v-11.5a1.006,1.006,0,0,0-1-1,1.009,1.009,0,0,0-1.011,1v11.5a1.014,1.014,0,0,0,1.011,1.016,1.011,1.011,0,0,0,1-1.016'
              transform='translate(-132.608 -51.851)'
              fill='#00b7ac'
            />
            <path
              id='Path_103'
              data-name='Path 103'
              d='M-276.8,432.263a1.032,1.032,0,0,0,.714-.291,1.021,1.021,0,0,0,.292-.712,1.029,1.029,0,0,0-.295-.717,1.024,1.024,0,0,0-.711-.292,1.015,1.015,0,0,0-.716.293,1.029,1.029,0,0,0-.295.717,1.02,1.02,0,0,0,.295.713,1.019,1.019,0,0,0,.716.29'
              transform='translate(-123.342 -51.737)'
              fill='#00b7ac'
            />
            <path
              id='Path_104'
              data-name='Path 104'
              d='M-312.639,435.1a1.013,1.013,0,0,0-1.013,1.006v5.8h-3.62c-.952,0-3.847,0-3.847-4.4v-6.091a1.007,1.007,0,0,0-1-1.01,1.012,1.012,0,0,0-1.013,1.01V437.5c0,4.079,2.137,6.419,5.863,6.419h4.379a.763.763,0,0,0,.1-.007l.027,0,.028,0a.675.675,0,0,0,.1.007,1,1,0,0,0,1-1.005V436.1a1,1,0,0,0-1-1.006'
              transform='translate(-99.31 -51.816)'
              fill='#00b7ac'
            />
            <path
              id='Path_105'
              data-name='Path 105'
              d='M-302.067,432.266a1.025,1.025,0,0,0,.716-.295,1.022,1.022,0,0,0,.295-.712,1.021,1.021,0,0,0-.3-.716,1.036,1.036,0,0,0-.714-.295,1.024,1.024,0,0,0-.714.3,1.019,1.019,0,0,0-.295.714,1.02,1.02,0,0,0,.3.716,1.013,1.013,0,0,0,.711.291'
              transform='translate(-109.946 -51.736)'
              fill='#00b7ac'
            />
            <path
              id='Path_106'
              data-name='Path 106'
              d='M-356.021,440.363a1.006,1.006,0,0,0-1.006,1.007v3.409c0,.1-.02,2.411-1.824,2.411h-3.069l.12-.278a5.467,5.467,0,0,0,.448-2.133v-3.409a1.008,1.008,0,0,0-1.006-1.007,1.009,1.009,0,0,0-1.011,1.007v3.409c0,.1-.023,2.411-1.821,2.411h-2.463v-5.83a1.007,1.007,0,0,0-1.008-1,1.006,1.006,0,0,0-1.009,1v9.069h-3.795c-.949,0-3.844,0-3.844-4.4v-4.668a1.007,1.007,0,0,0-1.008-1,1.006,1.006,0,0,0-1.009,1v4.668c0,4.08,2.137,6.419,5.861,6.419h4.67c.027,0,.047,0,.066,0a.571.571,0,0,1,.061,0h.007a1.011,1.011,0,0,0,1.008-1.01v-2.23h8.956a.981.981,0,0,0,.124-.008c2.615-.177,3.559-2.742,3.559-4.42v-3.409a1.008,1.008,0,0,0-1.008-1.007'
              transform='translate(-69.52 -57.094)'
              fill='#00b7ac'
            />
            <path
              id='Path_107'
              data-name='Path 107'
              d='M-237.631,462.845a1.008,1.008,0,0,0-.711.3,1,1,0,0,0-.3.714,1.029,1.029,0,0,0,.3.716,1.015,1.015,0,0,0,.711.295,1.032,1.032,0,0,0,.714-.3,1.019,1.019,0,0,0,.292-.714,1.009,1.009,0,0,0-.295-.714,1.019,1.019,0,0,0-.711-.3'
              transform='translate(-144.104 -69.018)'
              fill='#00b7ac'
            />
            <path
              id='Path_108'
              data-name='Path 108'
              d='M-243.923,462.845a1.035,1.035,0,0,0-.714.3,1.01,1.01,0,0,0-.3.712,1.043,1.043,0,0,0,.3.716,1.033,1.033,0,0,0,.711.295,1.021,1.021,0,0,0,.714-.3,1.016,1.016,0,0,0,.3-.714,1.009,1.009,0,0,0-.3-.714,1.013,1.013,0,0,0-.714-.3'
              transform='translate(-140.771 -69.018)'
              fill='#00b7ac'
            />
            <path
              id='Path_109'
              data-name='Path 109'
              d='M-235.1,433.4a8.531,8.531,0,0,0-5.952,1.947l-.081.071c-.139.12-.281.247-.426.38l-.333.305V431.47a1.007,1.007,0,0,0-1.006-1,1.009,1.009,0,0,0-1.008,1v8.159c0,.026.007.041.007.058a.317.317,0,0,1-.007.066,1.033,1.033,0,0,0,.3.724,1.015,1.015,0,0,0,.709.3,1.051,1.051,0,0,0,.716-.3,1.085,1.085,0,0,0,.242-.424l.066-.215h0a3.443,3.443,0,0,1,.322-.779,7.924,7.924,0,0,1,.84-1.208,7.723,7.723,0,0,1,.92-.891l.079-.071a6.466,6.466,0,0,1,4.531-1.444c2.938.107,3.527,1.3,3.527,3.19v3.333h-16.689l.121-.276a5.5,5.5,0,0,0,.448-2.135V436.1a1.007,1.007,0,0,0-1.008-1.006,1.009,1.009,0,0,0-1.008,1.006v3.453c0,.1-.023,2.411-1.821,2.411h-2.057V436.1a1.007,1.007,0,0,0-1.006-1.006,1.008,1.008,0,0,0-1.006,1.006v6.975a3.932,3.932,0,0,1-2.281,3.388,1.013,1.013,0,0,0-.589,1.3,1.027,1.027,0,0,0,1.307.584,5.947,5.947,0,0,0,3.469-4.2l.034-.159h23.125a1.013,1.013,0,0,0,1.008-1.016v-4.337c0-3.341-1.849-5.1-5.5-5.234'
              transform='translate(-133.522 -51.851)'
              fill='#00b7ac'
            />
          </g>
        </svg>
      </div>

      <div style={{ width: '50%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '30%',
            }}
          >
            <QRCode
              size={120}
              style={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
              }}
              bordered={false}
              value={qrCodeValue ? qrCodeValue : qrCodeValueInNotGiven}
            />
          </div>

          <div
            style={{
              width: '70%',
            }}
          >
            <Typography.Text
              style={{
                display: 'block',
                fontFamily: "'Source Sans Pro', sans-serif",
                fontWeight: 600,
              }}
              // level={4}
            >
              {orgInfo?.org_name}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_address1}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_address2}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              <strong>Mobile:</strong> {orgInfo?.org_mobile}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              <strong>Email:</strong> {orgInfo?.org_owner_email}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_extra_info}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_website}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_facebook}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PayrollHeader = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);

  return (
    <Card>
      <Row align='middle' justify='center'>
        <Col>
          <img
            style={GetInfo().logoProp}
            src={orgInfo?.org_logo || ''}
            alt={orgInfo?.org_name || 'M360 ICT'}
          />
        </Col>
        {/* <Typography.Title level={4}>Trabill</Typography.Title> */}
      </Row>
      <Row align='middle' justify='center'>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            marginRight: '2px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          {orgInfo?.org_address1}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            marginRight: '2px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          {orgInfo?.org_address2 + ',' + ','}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            marginRight: '2px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          Mobile : {orgInfo?.org_mobile}
        </Typography.Text>
      </Row>
    </Card>
  );
};

export const LoanHeader = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const { Text } = Typography;
  return (
    <>
      <Row justify={'space-between'} align='middle'>
        <Col>
          <Image
            style={{ height: '50px' }}
            src={
              orgInfo?.org_logo ||
              'https://basis.org.bd/public/images/logo/54e198aa3eae15c215688af3bb13de5f16022022025439.png'
            }
            alt='Trabill'
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'end',
            textTransform: 'uppercase',
            marginBottom: '20px',
          }}
        >
          <Text className='changeFont'>{orgInfo?.org_name}</Text>
          <Text className='changeFont'>{orgInfo?.org_address1}</Text>
          <Text>
            <Text className='changeFont' strong>
              TEL:
            </Text>
            {orgInfo?.org_mobile} ,
            <Text className='changeFont' strong>
              CALL:
            </Text>
            {orgInfo?.org_mobile}
          </Text>
          <Text>
            <Text className='changeFont' strong>
              Email :
            </Text>
            {orgInfo?.org_owner_email}
          </Text>
          <Text>{orgInfo?.org_extra_info}</Text>
          <Text>
            <Text className='changeFont' strong>
              Website :
            </Text>
            {orgInfo?.org_website}
          </Text>
          <Text>
            <Text className='changeFont' strong>
              Facebook :
            </Text>
            {orgInfo?.org_facebook}
          </Text>
        </Col>
      </Row>
    </>
  );
};
