import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import InvoiceMoneyReceipt from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { usePostInvoiceMutation } from '../Api/invoiceEndpoints';
import { useNavigate } from 'react-router';
import { IInvoiceFormValueType } from '../Type/Invoice.interface';

const AddInvoice = () => {
  const [createAndView, setCreateAnView] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    form.setFieldsValue({
      invoice_sales_date: dayjs(),
    });
  }, []);

  const [
    postInvoice,
    { data, isError, isSuccess, isLoading: postLoading, error },
  ] = usePostInvoiceMutation();

  // ============== form submit handle
  const onFinish = async (values: IInvoiceFormValueType) => {
    const {
      account_id,
      receipt_money_receipt_no,
      receipt_note,
      receipt_payment_date,
      receipt_payment_type,
      receipt_total_amount,
      receipt_total_discount,
      ...all
    } = values;

    postInvoice({
      ...all,
      invoice_sales_date: dayjs(all?.invoice_sales_date)?.format('YYYY-MM-DD'),
      invoice_due_date:
        all?.invoice_due_date &&
        dayjs(all?.invoice_due_date)?.format('YYYY-MM-DD'),
      money_receipt: {
        account_id,
        receipt_money_receipt_no,
        receipt_note,
        receipt_payment_date,
        receipt_payment_type,
        receipt_total_amount,
        receipt_total_discount,
      },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice added successfully!');
      if (createAndView) {
        navigate(`/invoice/details/${data?.data}`);
      } else {
        navigate(`/invoice`);
      }
    }
    if (isError) {
      message.error('There was an error');
    }
  }, [postLoading]);

  const invoice_net_total = useWatch(['invoice_net_total'], form);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice', 'Create']} />
      <Form
        onFinish={onFinish}
        onFinishFailed={() => message.error('Submit failed!')}
        layout='vertical'
        form={form}
      >
        <InvoiceHeaderForm
          form={form}
          invoice_net_totalFieldName={['invoice_net_total']}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
        />

        <InvoiceBillingInfo form={form} />
        <InvoiceVisaSubTotalForm form={form} />
        <InvoiceMoneyReceipt
          form={form}
          netTotal={Number(invoice_net_total || 0)}
        />
        {/* @AIR TICKET EDIT BUTTON */}

        <Row gutter={[10, 10]}>
          <Col>
            <FormButton label='CREATE' onClick={() => {}} />
          </Col>
          <Col>
            <FormButton
              label='CREATE & PREVIEW'
              onClick={() => setCreateAnView(true)}
            />
          </Col>
        </Row>
      </Form>
    </ClientsStyle>
  );
};

export default AddInvoice;

const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
`;
