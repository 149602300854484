import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Row, Space, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import { Fixed2 } from '../../../common/utils/common.utils';
import postDateFormat from '../../../common/utils/postDateFormat';
import {
  DateInput,
  FormButton,
  FormInput,
  FormInputItem,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectDataInput,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { Invoices } from '../../../components/notificatioin/Interfaces/Notification.interface';

import {
  IMoneyReceiptInvoices,
  IMoneyReceiptPostType,
} from '../Types/MoneyReceiptTypes';
import {
  useEditMoneyReceiptMutation,
  useLazyGetClientInvoicesInfoQuery,
  useLazyGetForEditQuery,
  usePostMoneyReceiptMutation,
} from '../api/endpoints/moneyReceiptEndpoints';
import NewMoneyReceiptSpecificInvoice from './NewMoneyReceiptSpecificInvoice';
import AccountLastBalanceInput from '../../../components/common/AccountLastBalanceInput';
import { useGetAccountByTypeQuery } from '../../Expense/Api/Endpoints/ExpenseEndpoints';
type Props = {
  reason: 'ADD_NEW' | 'EDIT';
  data?: Invoices;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
const MoneyReceiptForm = ({ reason }: Props) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [selectClient, setSelectclient] = useState<string | number>();
  const [paymentTo, setPaymentTo] = useState<string | undefined>();
  const [account, setAccount] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState<number>();
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );
  const [
    postMoneyReceipt,
    {
      isError: postIsError,
      data: postData,
      isSuccess: postIsSuccess,
      error: postError,
      isLoading: postLoading,
    },
  ] = usePostMoneyReceiptMutation();

  useEffect(() => {
    form.setFieldValue('account_id', undefined);
    setAccount(undefined);
  }, [paymentMethod]);

  const [getClientInvoicesInfo, { data: clientInvoicesInfo }] =
    useLazyGetClientInvoicesInfoQuery();
  console.log(clientInvoicesInfo);
  useEffect(() => {
    if (
      clientInvoicesInfo?.data?.client_lbalance ||
      clientInvoicesInfo?.data?.client_lbalance == 0
    ) {
      form.setFieldValue(
        'presentBalance',
        Math.abs(clientInvoicesInfo.data.client_lbalance)
      );
    } else {
      form.setFieldValue('presentBalance', 0);
    }
  }, [clientInvoicesInfo, paymentTo]);

  const resetFields = () => {
    form.setFieldsValue({
      invoices: [{}],
      receipt_total_amount: undefined,
    });
  };
  const handlePayto = (payToParams: String, clientId: string) => {
    if (payToParams) {
      switch (payToParams) {
        case 'OVERALL':
          getClientInvoicesInfo({
            id: String(selectClient),
            receipt_payment_to: 'OVERALL',
          });
          resetFields();
          break;
        case 'INVOICE':
          getClientInvoicesInfo({
            id: String(selectClient),
            receipt_payment_to: 'INVOICE',
          });
          resetFields();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (postIsError) {
      message.error('Something went wrong on post time');
    }
  }, [postIsError]);

  useEffect(() => {
    if (postIsSuccess) {
      navigate(`/moneyreceipt/view/${postData?.data?.receipt_id}`, {
        state: '/moneyreceipt',
      });
      message.success('Money Receipt Successfully Added');
      form.resetFields();
      setPaymentTo(undefined);
      setSelectclient(undefined);
      setPaymentMethod(undefined);
      setAccount(undefined);
      setPayMethodCatId(undefined);
    }
  }, [postIsSuccess]);

  const invoices: IMoneyReceiptInvoices[] = useWatch(['invoices'], form);
  useEffect(() => {
    if (paymentTo === 'INVOICE') {
      form.setFieldValue(
        'receipt_total_amount',
        invoices?.reduce((a, b) => a + Number(b?.invoice_amount || 0), 0)
      );
    }
  }, [invoices]);
  useEffect(() => {
    if (!id) {
      form.setFieldValue('receipt_payment_date', dayjs());
    }
  }, []);

  // handle edit
  const { receipt_id } = useParams();
  const [getForEdit, { data: getForEditData, isLoading: getEditLoading }] =
    useLazyGetForEditQuery();
  const navigate = useNavigate();
  const [
    editMoneyReceipt,
    {
      isError: editIsError,
      isLoading: editIsLoading,
      isSuccess: editIsSuccess,
    },
  ] = useEditMoneyReceiptMutation();

  useEffect(() => {
    if (postIsError) {
      message.error('Something went wrong on Update time');
    }
  }, [editIsError]);

  useEffect(() => {
    if (editIsSuccess) {
      message.success('Money Receipt Successfully Updated');
      navigate(`/moneyreceipt/view/${receipt_id}`);
      form.resetFields();
      setPaymentTo(undefined);
      setSelectclient(undefined);
      setPaymentMethod(undefined);
      setAccount(undefined);
      setPayMethodCatId(undefined);
    }
  }, [editIsSuccess]);

  useEffect(() => {
    if (reason === 'EDIT' && receipt_id) {
      getForEdit({ receipt_id: receipt_id });
    }
  }, [receipt_id]);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (reason === 'EDIT') {
      const editableData = getForEditData?.data;

      const editableDataInvoice = getForEditData?.data?.invoices;
      if (editableData?.receipt_payment_to) {
        setPaymentTo(editableData?.receipt_payment_to);
        switch (editableData.receipt_payment_to) {
          case 'INVOICE':
            if (editableDataInvoice) {
              getClientInvoicesInfo({
                id: editableData.receipt_client_id,
                receipt_payment_to: 'INVOICE',
              });
              setSelectclient(editableData.receipt_client_id);
            }
            break;
          case 'OVERALL':
            break;

          default:
            break;
        }
      }
      if (editableData?.receipt_payment_type) {
        setPayMethodCatId(editableData?.receipt_payment_type);
      }
      if (
        editableData?.receipt_payment_to === 'OVERALL' &&
        editableData?.receipt_client_id
      ) {
        setPaymentTo('OVERALL');
        setSelectclient(editableData?.receipt_client_id);
        getClientInvoicesInfo({
          id: editableData.receipt_client_id,
          receipt_payment_to: 'OVERALL',
        });
      }

      timeoutId = setTimeout(() => {
        form.setFieldsValue({
          ...editableData,
          // cheque_withdraw_date: editableData?.cheque_withdraw_date || undefined,
          receipt_payment_date: editableData?.receipt_payment_date
            ? dayjs(editableData.receipt_payment_date)
            : undefined,
          cheque_withdraw_date: editableData?.cheque_withdraw_date
            ? dayjs(editableData.cheque_withdraw_date)
            : undefined,
          invoices: editableData?.invoices?.map((el) => {
            return {
              invoice_no: el.invoice_id,
              invoice_amount: el.invoice_amount,
              netTotal: el.netTotal,
              invoiceDate: el.invoiceDate ? dayjs(el.invoiceDate) : undefined,
              paid: el.paid,
              due: Number(el.netTotal || 0) - Number(el.paid || 0),
            };
          }),
        });
      }, 200);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getForEditData]);

  const onFinish = (values: IMoneyReceiptPostType) => {
    const formatedData: IMoneyReceiptPostType = {
      receipt_client_id: values.receipt_client_id,
      receipt_payment_to: values.receipt_payment_to,
      receipt_payment_type: values.receipt_payment_type,
      account_id: values.account_id,
      receipt_total_amount: Fixed2(values.receipt_total_amount),
      receipt_money_receipt_no: values.receipt_money_receipt_no || undefined,
      receipt_total_discount: Fixed2(values?.receipt_total_discount),
      receipt_payment_date: dayjs(values.receipt_payment_date).format(
        'YYYY-MM-DD'
      ),

      cheque_bank_name: values.cheque_bank_name || undefined,
      cheque_number: values.cheque_number || undefined,
      cheque_withdraw_date: postDateFormat(values.cheque_withdraw_date),

      trans_no: values.trans_no || undefined,
      charge_amount: values.charge_amount || undefined,
      receipt_note: values.receipt_note || undefined,

      invoices: values.invoices
        ? values.invoices.map((el, index) => {
            return {
              invoice_id: el.invoice_no, //here invoice_no is invoice_id
              invoice_amount: el.invoice_amount,
            };
          })
        : undefined,
    };

    if (reason === 'ADD_NEW') {
      postMoneyReceipt(formatedData);
    } else if (reason === 'EDIT' && receipt_id) {
      editMoneyReceipt({ body: formatedData, id: receipt_id });
    }
  };
  //======================== ADD FROM NOTIFICATION =======================

  const [clientDetails, setClientDetails] = useState<any>();
  const getPrevFiledValue = form.getFieldValue('receipt_walking_customer_name');
  const role_name = useAppSelector((state) => state.user?.role_name);
  const editPermission = useAppSelector((sate) => sate.user?.role_permissions);
  const editPermissionParse = editPermission
    ? JSON.parse(editPermission)
    : undefined;
  const disableInvoiceEdit =
    editPermissionParse[role_name!]?.mony_receipt_specific_invoice;
  const disableRemoveInvoiceIndex = getForEditData?.data?.invoices?.length;
  return (
    <ClientAddStyle>
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/moneyreceipt'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Money Receipt List
          </Button>
        </Link>
      </Space>
      {(postLoading || editIsLoading || getEditLoading) && <LoadingIndicator />}
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        onFinishFailed={(value) => {}}
      >
        <div className='border p-10' style={{ marginBottom: '2rem' }}>
          <Row
            style={{
              width: '100%',
            }}
          >
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <SelectClients
                offDropDown
                label='Select Client:'
                name='receipt_client_id'
                required
                disabled={reason === 'EDIT' ? true : false}
                size={8}
                mdSize={24}
                setClientDetails={setClientDetails}
                onChange={(value) => {
                  setSelectclient(value.toString());
                  getClientInvoicesInfo({
                    id: String(value),
                    receipt_payment_to: 'OVERALL',
                  });
                  form.setFieldValue('receipt_payment_to', 'OVERALL');
                  setPaymentTo('OVERALL');
                }}
              />
            </Row>

            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              {(clientDetails?.client_walking_customer === 1 ||
                getPrevFiledValue) && (
                <FormInput
                  size={8}
                  mdSize={24}
                  label='Client Name'
                  name='receipt_walking_customer_name'
                />
              )}
            </Row>
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <FormInputItem
                size={8}
                label={
                  (clientInvoicesInfo?.data?.client_lbalance || 0) > 0
                    ? 'Present Balance:'
                    : 'Present Due:'
                }
                name={'presentBalance'}
                readOnly
                status={
                  clientInvoicesInfo?.data?.client_lbalance! < 0 ? true : false
                }
              />
            </Row>

            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <SelectDataInput
                mdSize={24}
                label='Payment To:'
                name='receipt_payment_to'
                required
                disabled={selectClient || reason === 'EDIT' ? false : true}
                data={[
                  {
                    id: 'OVERALL',
                    title: 'Over All',
                  },
                  {
                    id: 'INVOICE',
                    title: 'Specific Invoice',
                  },
                ]}
                onChange={(value) => {
                  if (selectClient && reason !== 'EDIT')
                    handlePayto(String(value), selectClient as string);

                  setPaymentTo(String(value));
                }}
                size={8}
                style={{ marginBottom: '20px' }}
              />
            </Row>

            {paymentTo === 'INVOICE' && clientInvoicesInfo ? (
              <Row
                style={{
                  width: '100%',
                }}
                justify={'center'}
              >
                <Card style={{ margin: '10px 0px' }}>
                  <Form.List name='invoices' initialValue={[{}]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name }, index) => (
                          <NewMoneyReceiptSpecificInvoice
                            fields={fields}
                            key={key}
                            name={name}
                            remove={remove}
                            selectClient={selectClient}
                            paymentTo={paymentTo}
                            index={index}
                            form={form}
                            reason={reason}
                            disableRemoveIndex={
                              disableInvoiceEdit && disableRemoveInvoiceIndex
                            }
                          />
                        ))}
                        <Button
                          style={{ width: '110px', borderRadius: '3px' }}
                          onClick={() => add()}
                          size='small'
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Row>
            ) : null}

            <Row
              style={{
                width: '100%',
                marginTop: '15px',
              }}
              justify={'center'}
            >
              <SelectPaymentMethod
                required
                name='receipt_payment_type'
                label='Payment Method '
                disabled={paymentTo || reason === 'EDIT' ? false : true}
                onChange={(value: number) => {
                  setPayMethodCatId(value);
                  setPaymentMethod(value);
                }}
                cheque
              />
            </Row>
            {Number(payMethodCatId) === 4 && (
              <>
                <Row
                  style={{
                    width: '100%',
                  }}
                  justify={'center'}
                >
                  <FormInputItem
                    label='Cheque No'
                    name='cheque_number'
                    required
                  />
                </Row>
                <Row
                  style={{
                    width: '100%',
                  }}
                  justify={'center'}
                >
                  <DateInput
                    name='cheque_withdraw_date'
                    label='Withdraw Date'
                    required
                  />
                </Row>
                <Row
                  style={{
                    width: '100%',
                  }}
                  justify={'center'}
                >
                  <FormInputItem
                    name={'cheque_bank_name'}
                    label='Bank Name'
                    required
                    disabled={paymentMethod || reason === 'EDIT' ? false : true}
                    onChange={(value) => setAccount(String(value))}
                  />
                </Row>
              </>
            )}

            {Number(payMethodCatId) !== 4 && (
              <Row
                style={{
                  width: '100%',
                }}
                justify={'center'}
              >
                <AccountLastBalanceInput
                  accountsLastBalance={accounts ? accounts.data : []}
                  name='account_id'
                  label='Account:'
                  disabled={paymentMethod || reason === 'EDIT' ? false : true}
                  onSelect={(value: number) => setAccount(String(value))}
                  required
                />
              </Row>
            )}

            {Number(payMethodCatId) === 3 && (
              <>
                <Row
                  style={{
                    width: '100%',
                  }}
                  justify={'center'}
                >
                  <FormInputItem label='Receipt/Trans No:' name='trans_no' />
                </Row>
                <Row
                  style={{
                    width: '100%',
                  }}
                  justify={'center'}
                >
                  <FormInputItem
                    name={'charge_amount'}
                    label='Transaction Charge :'
                  />
                </Row>
              </>
            )}

            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <NumberInput
                mdSize={24}
                smSize={24}
                label='Amount:'
                name={'receipt_total_amount'}
                required
                readOnly={
                  paymentTo === 'INVOICE'
                  // reason === 'EDIT'
                  //   ? false
                  //   : true
                }
                min='0'
                maxChar={14}
                minChar={0}
              />
            </Row>
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <NumberInput
                mdSize={24}
                smSize={24}
                label='Discount:'
                name={'receipt_total_discount'}
                min='0'
                maxChar={12}
                minChar={0}
              />
            </Row>
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <DateInput
                mdSize={24}
                smSize={24}
                required
                label='Payment Date:'
                name={'receipt_payment_date'}
              />
            </Row>
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <FormInput
                mdSize={24}
                smSize={24}
                label='Manual Money reciept no'
                name={'receipt_money_receipt_no'}
                disabled={
                  paymentTo === 'Specific Invoice' ||
                  paymentTo === 'Specific Tickets' ||
                  account ||
                  reason === 'EDIT'
                    ? false
                    : true
                }
              />
            </Row>
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <FormInputItem
                mdSize={24}
                smSize={24}
                label='Note:'
                name={'receipt_note'}
              />
            </Row>
            <Row
              style={{
                width: '100%',
              }}
              justify={'center'}
            >
              <FormButton
                label={
                  reason !== 'EDIT'
                    ? 'Create Money Receipt'
                    : 'Update Money Receipt'
                }
                size={8}
                loading={postLoading || editIsLoading}
              />
            </Row>
          </Row>
        </div>
      </Form>
    </ClientAddStyle>
  );
};

export default MoneyReceiptForm;
const ClientAddStyle = styled.div``;
