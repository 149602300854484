import { List } from 'antd';
import { useGetRecentInvoiceQuery } from '../Api/Endpoints/DashboardEndpoints';
import DashboardTitle from './DashboardTitle';
import dayjs from 'dayjs';

export type IDarkMode = {
  isDarkMode: boolean;
};

const InvoiceDetails = ({ isDarkMode }: IDarkMode) => {
  const { data, isLoading } = useGetRecentInvoiceQuery();
  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle title='RECENT INVOICE' />
      <div
        className='custom-scrollbar'
        style={{
          height: 290,
          overflow: 'auto',
          padding: '5px 5px',
        }}
      >
        <List
          itemLayout='horizontal'
          dataSource={data?.data}
          renderItem={(item) => (
            <List.Item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              }}
            >
              <List.Item.Meta
                description={
                  <>
                    <span className='d-block'>
                      {` Invoice No: ${item.invoice_no}`}
                    </span>
                    <span>Client Name: {item.client_name || 'N/A'}</span>
                  </>
                }
              />
              <List.Item.Meta
                description={
                  <>
                    <span className='d-block'>
                      Employee Name: {item.employee_full_name}
                    </span>
                    <span>Net Total: {item.invoice_net_total}</span>
                  </>
                }
              />
              <List.Item.Meta
                description={
                  <>
                    <span className='d-block'>
                      Sales Date:{' '}
                      {item.invoice_sales_date &&
                        dayjs(item.invoice_sales_date).format('DD-MMM-YYYY')}
                    </span>
                    <span>
                      Create Date:{' '}
                      {item.invoice_create_date &&
                        dayjs(item.invoice_create_date).format('DD-MMM-YYYY')}
                    </span>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default InvoiceDetails;
