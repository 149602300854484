import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { moduleType } from '../../auth/states/userSlice';
import { rolePermissionType } from '../../common/types/commonTypes';
import NewInvoiceOther from '../../modules/Invoices/Pages/AddInvoice';
import EditInvoice_Other from '../../modules/Invoices/Pages/EditInvoice';
import ListOfInvoiceOther from '../../modules/Invoices/Pages/ViewInvoiceInfo/ListOfInvoice';
import ViewInvoiceOther from '../../modules/Invoices/Pages/ViewInvoiceInfo/ViewInvoiceOther';
import { useAppSelector } from '../hooks';

export const InvoicesRoutes = (
  permission: rolePermissionType | undefined,
  hasPermission: (module: moduleType) => boolean | undefined
) => {
  const appConfig = useAppSelector((state) => state.configSlice);
  const addMRPermission = permission?.money_receipt?.['create:any'];

  return (
    <>
      <>
        <Route
          path='/invoice/add'
          element={
            <PermissionRoute permission={['*']} element={<NewInvoiceOther />} />
          }
        />

        <Route
          path='/invoice/details/:id'
          element={
            <PermissionRoute
              permission={['*']}
              element={<ViewInvoiceOther />}
            />
          }
        />
        <Route
          path='/invoice'
          element={
            <PermissionRoute
              permission={['*']}
              element={
                <ListOfInvoiceOther
                  permission={permission?.invoice}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />

        <Route
          path='/invoice/edit/:id'
          element={
            <PermissionRoute
              permission={['*']}
              element={<EditInvoice_Other />}
            />
          }
        />
      </>
    </>
  );
};
