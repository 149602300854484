import { Button, Col, DatePicker, Input, Row, Space, Table } from 'antd';
import { useState } from 'react';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useGetAllInvoiceQuery } from '../../Api/invoiceEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import InvoiceColumnList from '../../utils/InvoiceColumnList';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { removeSpaceBeforeWord } from '../../../../common/utils/removeSpaceBeforeWord';

type Props = {
  permission: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
};

const ListOfInvoice = ({ permission, addMRPermission }: Props) => {
  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const [pagination, setPagination] = useState<{
    current: number;
    pageSize: number;
  }>({ current: 1, pageSize: 20 });
  const [date, setDate] = useState(['', '']);
  const [search, setSearch] = useState<string>('');
  const { data, isLoading } = useGetAllInvoiceQuery({
    fromDate: date[0],
    toDate: date[1],
    search: search,
    ...pagination,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };
  const handleSearch = (e: any) => {
    console.log(e);
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb arrOfOption={['Invoices', 'List']} />
      <>
        <Row
          justify={'space-between'}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
          style={{ marginBottom: '25px' }}
        >
          <Space>
            <Link to={'/invoice/add'}>
              <Button
                type='primary'
                icon={<ArrowLeftOutlined />}
                style={{ width: '100%' }}
              >
                Create
              </Button>
            </Link>
          </Space>{' '}
          <Col lg={12}>
            <Row justify={'end'} gutter={10}>
              <Col xs={24} sm={12} md={8} lg={9}>
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={handleDateRangeChange}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Input
                  placeholder='🔍 Search Invoices...'
                  onPressEnter={handleSearch}
                  allowClear
                ></Input>
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          size='small'
          bordered
          rowKey={(e) => Math.random()}
          rowClassName={(record) =>
            record?.invoice_is_refund ? 'refund-row-style' : ''
          }
          loading={{
            spinning: isLoading,
            indicator: loadingIndicator,
          }}
          columns={InvoiceColumnList({ pagination })}
          scroll={{ x: true }}
          dataSource={data?.data}
          pagination={
            data?.count && data.count > 20
              ? {
                  ...pagination,
                  total: data.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
              : false
          }
        />
      </>
    </div>
  );
};

export default ListOfInvoice;
