import { Button, Col, Form, Modal, Row, Select, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../../../../components/common/FormItem/SelectCustomFeilds';
import SelectPaymentMethod from '../../../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../../../components/common/spinner/LoadingIndicator';
import {
  IClientIncentiveIncomeFormData,
  IaccountsByMethod,
} from '../../../../AccountsTypes/AccountsTypes';
import { useLazyGetAllAccountsByTypeQuery } from '../../../../Api/Endpoints/accountsEndpoints';
import { Select_Client_And_Combined } from '../../../../components/Select_Client';
import {
  useEditClientIncentiveIncomeMutation,
  useLazyGetViewClientIncentiveIncomeQuery,
} from '../../endpoints/client_IncentiveIncomeEndpoints';
import { EditOutlined } from '@ant-design/icons';

type Props = {
  id?: number;
};

function Client_incentive_Edit_Modal({ id }: Props) {
  const [form] = Form.useForm();
  const [adjustBill, setAdjustBill] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const user = useSelector(selectUser);
  const onselect = (value: string) => {
    setAdjustBill(value);
  };
  const isChanged = useRef(false);

  //Get Data and Set Field

  const [fetchData, { data, isLoading: infoLoading, isSuccess: dataSuccess }] =
    useLazyGetViewClientIncentiveIncomeQuery();
  const info = data?.data && data?.data[0];

  useEffect(() => {
    if (dataSuccess) {
      form.setFieldsValue({
        ...info,
        comb_client: info?.comb_client,
        adjust_with_bill: info?.incentive_adjust_bill,
        amount: info?.incentive_amount,
        date: dayjs(info?.incentive_created_date),
        incentive_note: info?.incentive_note,
        type_id: info?.incentive_account_category_id,
        account_id: info?.incentive_account_id,
      });
      if (info?.incentive_adjust_bill == 'NO') {
        setAdjustBill('NO');
      } else {
        setAdjustBill('YES');
      }
      if (info?.incentive_account_category_id) {
        setPaymentMethod(info.incentive_account_category_id.toString());
      }
    }
  }, [info, dataSuccess]);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });
      return;
    }
  }, [paymentMethod]);

  //Edit Data Submit

  const [editIncentive, { isError, isSuccess, isLoading }] =
    useEditClientIncentiveIncomeMutation();

  const handleSubmit = async (values: IClientIncentiveIncomeFormData) => {
    const body = {
      ...values,
      incentive_id: Number(info?.incentive_id),
      date: dayjs(values.date).format('YYYY-MM-DD'),
      incentive_created_by: user?.user_id as number,
      id: id,
    };
    await editIncentive(body);
  };

  const showModal = () => {
    setIsModalOpen(true);
    fetchData(Number(id));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Update Successfully ');
      form.resetFields();
      setIsModalOpen(false);
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess]);
  if (isLoading) {
    return loadingIndicator;
  }
  return (
    <>
      <Button
        icon={<EditOutlined />}
        size='small'
        type='link'
        onClick={showModal}
      ></Button>

      <Modal
        title={`Edit ${info?.client_name} incentive income`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {infoLoading && <LoadingIndicator />}
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Select_Client_And_Combined name='comb_client' disabled />

            <SelectOptionsInput
              name='adjust_with_bill'
              label='Adjust With Bill'
              options={['YES', 'NO']}
              size={12}
              onChange={(e) => onselect(e.toString())}
            />

            <NumberInput
              name='amount'
              label='Amount'
              required
              size={12}
              min='0'
              maxChar={14}
            />
            {adjustBill == 'NO' && (
              <>
                <SelectPaymentMethod
                  name='type_id'
                  label='Payment Method'
                  size={12}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isChanged.current) isChanged.current = true;
                  }}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name='account_id'
                    label='Account'
                    rules={[
                      {
                        required: true,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod, index: number) => {
                          return (
                            <Select.Option key={index} value={item.account_id}>
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <DateInput name='date' label='Date' size={12} />
            <TextAreaInput name='note' label='Note' size={24} />
          </Row>

          <FormButton label='Edit Incentive Income' />
        </Form>
      </Modal>
    </>
  );
}

export default Client_incentive_Edit_Modal;
