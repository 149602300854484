import { api } from '../../../app/baseQuery';
import { HTTPResponse } from '../../../common/types/commonTypes';
import {
  IAirport,
  ICreateInvoice,
  IGellAllInvoice,
  IViewInvoice,
} from '../Type/Invoice.interface';
import { INVOICE } from '../../Accounts/Api/Constants/constants';

export const invoiceEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllInvoice: build.query<
      HTTPResponse<IGellAllInvoice[]>,
      {
        current: number;
        pageSize: number;
        search: string;
        fromDate: string;
        toDate: string;
      }
    >({
      query: ({ current, pageSize, search, fromDate, toDate }) => ({
        url: `/invoices/invoice?from_date=${fromDate}&to_date=${toDate}&page=${current}&size=${pageSize}&search=${search}`,
      }),
      providesTags: () => [INVOICE],
    }),

    // VIEW DETAILIS
    getViewInvoice: build.query<HTTPResponse<IViewInvoice>, number>({
      query: (id) => ({
        url: `/invoices/invoice/${id}`,
      }),
      providesTags: () => [INVOICE],
    }),

    // DELETE AND RESTORE
    deleteInvoice: build.mutation<HTTPResponse<void>, number>({
      query: (id) => ({
        url: `/invoices/invoice/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [INVOICE],
    }),

    postInvoice: build.mutation<HTTPResponse<void>, ICreateInvoice>({
      query: (body) => ({
        url: '/invoices/invoice',
        method: 'POST',
        body,
      }),
      invalidatesTags: [INVOICE],
    }),

    editInvoice: build.mutation<
      HTTPResponse<void>,
      { id: number; body: ICreateInvoice }
    >({
      query: ({ id, body }) => ({
        url: `/invoices/invoice/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [INVOICE],
    }),
    //get voucher no
    getInvoiceVoucherNo: build.query<HTTPResponse<void>, void>({
      query: (query) => ({
        url: `/common/voucher/INV`,
      }),
      providesTags: () => [INVOICE],
    }),

    getAllAirport: build.query<HTTPResponse<IAirport[]>, void>({
      query: (arg) => ({
        url: `/configuration/airports/view-all`,
      }),
      providesTags: () => [{ type: 'Airports' }],
    }),
  }),
});

export const {
  useLazyGetAllInvoiceQuery,
  useGetAllInvoiceQuery,
  useDeleteInvoiceMutation,
  useEditInvoiceMutation,
  useGetViewInvoiceQuery,
  usePostInvoiceMutation,
  useGetInvoiceVoucherNoQuery,
  useGetAllAirportQuery,
} = invoiceEndpoints;
