import '../style/layoutStyle.css';

import {
  DatabaseOutlined,
  MenuOutlined,
  PicCenterOutlined,
  PieChartOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  StockOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  faBriefcase,
  faCircleDollarToSlot,
  faCreditCard,
  faDollar,
  faFileArrowDown,
  faFileInvoice,
  faMoneyBill,
  faNewspaper,
  faObjectGroup,
  faPassport,
  faPeopleGroup,
  faReceipt,
  faSubtract,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Layout, Menu, MenuProps, MenuRef, SiderProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { moduleType, selectUser } from '../../auth/states/userSlice';
import { rolePermissionType } from '../../common/types/commonTypes';
import {
  Configuration_Roles,
  Report_Roles,
} from '../../modules/Configuration/Users/types/role.enums';
import DateTimeWidget from '../../modules/Dashboard/Components/DateTimeWidget';
import '../style/layoutStyle.css';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    label,
    key,
    icon,
    children,
  } as MenuItem;
}

interface DataObject {
  children?: DataObject[] | null;
  icon: string;
  key: string;
  label: any;
}

function findObjectWithKey(
  data: DataObject[],
  path: {
    pathname: string;
    state?: string;
  },
  parentIndices: string[] = []
): string[] | null {
  for (let i = 0; i < data?.length; i++) {
    if (data[i] === null) {
      continue;
    }
    const object = data[i];

    if (object?.key === path.pathname || object?.key === path?.state) {
      return [...parentIndices, object?.key];
    }

    if (object.children && Array.isArray(object.children)) {
      const childIndices = findObjectWithKey(object.children, path, [
        ...parentIndices,
        object.key,
      ]);

      if (childIndices) {
        return childIndices;
      }
    }
  }
  return null;
}

function flattenArray(arr: DataObject[]): DataObject[] {
  return arr.reduce((result: DataObject[], obj: DataObject) => {
    result.push(obj);

    if (obj?.children && Array.isArray(obj?.children)) {
      result.push(...flattenArray(obj.children));
    }

    return result;
  }, []);
}

const { Sider } = Layout;

const Sidebar = ({
  collapsed,
  setCollapsed,
  setWidth,
  width,
}: {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  width: number;
}) => {
  // APP CONFIG
  const appConfig = useAppSelector((state) => state.configSlice);
  const isIataPanel = true;
  // appConfig && appConfig?.tac_airticket_type === 'IATA';

  const iconStyle: React.CSSProperties = {
    fontSize: 12,
  };

  const [isbreakPoint, setIsBreakPoint] = useState(true);

  const props: SiderProps = {
    collapsible: false,
    collapsed,
    onCollapse: (value) => setCollapsed(value),
    ...(isbreakPoint && { collapsedWidth: 0 }),
    breakpoint: 'md',
    onBreakpoint: (broken) => {
      setIsBreakPoint(broken);
    },
  };

  const user = useAppSelector(selectUser);

  const MODULE_TYPE = user?.organization_info.org_module_type || 'TRABILL';

  const permission: rolePermissionType | undefined =
    user?.role_permissions &&
    JSON.parse(user?.role_permissions)[user.role_name];
  //module wise permissions
  const modules = user?.modules;

  const isAdmin = user?.user_role !== 'EMPLOYEE' ? ['*'] : undefined;

  const hasPermission = (module: moduleType) => {
    return modules?.includes(module);
  };

  function isRIncludedInRoleResources(permission: any) {
    const rKeys: any = Object.keys(permission);
    return rKeys.some((key: any) => Report_Roles.hasOwnProperty(key));
  }

  const isIncludedPermissionReport =
    permission && isRIncludedInRoleResources(permission);

  function isIncludeConfigPermission(permission: any) {
    const rKeys: any = Object.keys(permission);
    return rKeys.some((key: any) => Configuration_Roles.hasOwnProperty(key));
  }

  const isIncludedPermissionConfig =
    permission && isIncludeConfigPermission(permission);

  // INVOICE OTHER
  const invoiceOther = getItem(
    'Invoices',
    'Invoice',
    <FontAwesomeIcon style={iconStyle} icon={faFileInvoice} />,
    [
      /*     permission?.invoice?.['create:any']
        ? */ getItem(
        <Link to='/invoice/add'>New Invoice</Link>,
        '/invoice/add',
        <PlusOutlined />
      ),
      /* : null */

      /*   getListPermission(permission?.invoice)
        ? */ getItem(
        <Link to='/invoice'>View Invoice</Link>,
        '/invoice',
        <MenuOutlined />
      ),
      /* : null */
    ]
  );

  // CHEQUES
  const cheques = getItem(
    <Link to='/cheques'>Cheque Management</Link>,
    '/cheques',
    <FontAwesomeIcon style={iconStyle} icon={faMoneyBill} />
  );

  // CLIENTS
  const clients = getItem(
    'Clients',
    'Clients',
    <FontAwesomeIcon style={iconStyle} icon={faUser} />,
    [
      permission?.clients
        ? getItem(
            <Link to='/clients'>Client</Link>,
            '/clients',
            <FontAwesomeIcon style={iconStyle} icon={faUser} />
          )
        : null,
    ]
  );
  // MONEY RECEIPT
  const moneyReceipt = getItem(
    'Money Receipt',
    'Money Receipt',
    <FontAwesomeIcon style={iconStyle} icon={faMoneyBill} />,
    [
      permission?.money_receipt
        ? getItem(
            <Link to='/moneyreceipt'>Invoice Money Receipt</Link>,
            '/moneyreceipt',
            <MenuOutlined />
          )
        : null,

      permission?.money_receipt_advr
        ? getItem(
            <Link to='/moneyreceipt/advance'>Advance Return</Link>,
            '/moneyreceipt/advance',
            <MenuOutlined />
          )
        : null,
    ]
  );
  // ACCOUNTS
  const accounts = getItem(
    'Accounts',
    'Accounts',
    <FontAwesomeIcon style={iconStyle} icon={faCreditCard} />,
    [
      permission?.account_opening_balance
        ? getItem(
            <Link to='/accounts/bill-adjustment'>Bill Adjustment</Link>,
            '/accounts/bill-adjustment',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts'>Add/List of Accounts</Link>,
            '/accounts',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts/transactions'>Transaction History</Link>,
            '/accounts/transactions',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts/balancestatus'>Balance Status</Link>,
            '/accounts/balancestatus',
            <MenuOutlined />
          )
        : null,
      permission?.account_balance_transfer
        ? getItem(
            <Link to='/accounts/balance_transfer'>Balance Transfer</Link>,
            '/accounts/balance_transfer',
            <MenuOutlined />
          )
        : null,
      permission?.account_non_invoice_income
        ? getItem(
            <Link to='/accounts/noninvoice'>Non-Invoice Income</Link>,
            '/accounts/noninvoice',
            <MenuOutlined />
          )
        : null,
      permission?.account_investments
        ? getItem(
            <Link to='/accounts/investments'>Investments</Link>,
            '/accounts/investments',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts/incentive'>Incentive Income</Link>,
            '/accounts/incentive',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // EXPENCE
  const expence = getItem(
    'Expense',
    'Expense',
    <FontAwesomeIcon style={iconStyle} icon={faSubtract} />,
    [
      permission?.expense_head
        ? getItem(
            <Link to='/head'>Expense Head</Link>,
            '/head',
            <FontAwesomeIcon style={iconStyle} icon={faReceipt} />
          )
        : null,
      permission?.expense?.['create:any']
        ? getItem(
            <Link to='/expense/add'>Add Expense</Link>,
            '/expense/add',
            <PlusOutlined />
          )
        : null,
      permission?.expense?.['read:any']
        ? getItem(
            <Link to='/expense'>Expense History</Link>,
            '/expense',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // REPORTS
  const report = getItem(
    'Reports',
    'Reports',
    <FontAwesomeIcon style={iconStyle} icon={faNewspaper} />,
    [
      permission?.report_ledgers
        ? getItem(
            'Ledgers',
            'Ledgers',
            <FontAwesomeIcon style={iconStyle} icon={faFileArrowDown} />,
            [
              getItem(
                <Link to='/reports/client_ledger'>Client Ledger</Link>,
                '/reports/client_ledger',
                <MenuOutlined />
              ),
            ]
          )
        : null,
      permission?.sales_report
        ? getItem('Sales Report', 'Sales Report', <StockOutlined />, [
            // getItem(
            //   <Link to='/reports/sales_report_item_and_salesman'>
            //     Salesman & Item
            //   </Link>,
            //   '/reports/sales_report_item_and_salesman',
            //   <MenuOutlined />
            // ),

            getItem(
              <Link to='/reports/daily_sales_report'>Sales Report</Link>,
              '/reports/daily_sales_report',
              <MenuOutlined />
            ),
          ])
        : null,

      // EXPENSE
      permission?.expense_report
        ? getItem(
            'Expense Report',
            'Expense Report',
            <FontAwesomeIcon style={iconStyle} icon={faNewspaper} />,
            [
              getItem(
                <Link to='/reports/expense_report'>Expense Report</Link>,
                '/reports/expense_report',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/head_wise_expense_report'>
                  Office Expense
                </Link>,
                '/reports/head_wise_expense_report',
                <MenuOutlined />
              ),
            ]
          )
        : null,
      permission?.report_module
        ? getItem('Others', 'Others', <PicCenterOutlined />, [
            permission?.client_discount
              ? getItem(
                  <Link to='/reports/client_discount_note'>
                    Client Discount
                  </Link>,
                  '/reports/client_discount_note',
                  <MenuOutlined />
                )
              : null,

            permission?.audit_trail
              ? getItem(
                  <Link to='/reports/activity_logs'>Audit Trail</Link>,
                  '/reports/activity_logs',
                  <MenuOutlined />
                )
              : null,
          ])
        : null,
    ]
  );

  // CONFIGURATION
  const configuration = getItem(
    'Configuration',
    'Configuration',
    <SettingOutlined />,
    [
      user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionReport
        ? getItem(
            <Link to='/app-configuration'>App Config</Link>,
            '/app-configuration',
            <MenuOutlined></MenuOutlined>
          )
        : null,

      permission?.client_category
        ? getItem(
            <Link to='/clients/categories'>Client Category</Link>,
            '/clients/categories',
            <MenuOutlined></MenuOutlined>
          )
        : null,
      permission?.departments
        ? getItem(
            <Link to='/departments'>Departments</Link>,
            '/departments',
            <MenuOutlined></MenuOutlined>
          )
        : null,

      permission?.designations
        ? getItem(
            <Link to='/designation/all_designation'>Designation</Link>,
            '/designation/all_designation',
            <MenuOutlined></MenuOutlined>
          )
        : null,
      permission?.employee
        ? getItem(
            <Link to='/employee'>Employee</Link>,
            '/employee',
            <FontAwesomeIcon style={iconStyle} icon={faPeopleGroup} />
          )
        : null,
      permission?.companies
        ? getItem(
            <Link to='/companies'>Companies</Link>,
            '/companies',
            <FontAwesomeIcon style={iconStyle} icon={faBriefcase} />
          )
        : null,

      isAdmin
        ? getItem('Users', 'user', <UserOutlined></UserOutlined>, [
            getItem(
              <Link to='/user/view'>View Users</Link>,
              '/user/view',
              <UserSwitchOutlined />
            ),

            getItem(
              <Link to='/role/view'>View Roles</Link>,
              '/role/view',
              <UsergroupAddOutlined></UsergroupAddOutlined>
            ),
          ])
        : null,

      user
        ? getItem(
            <Link to='/db-backup'>Database Backup</Link>,
            '/db-backup',
            <DatabaseOutlined />
          )
        : null,
    ]
  );

  const items: MenuItem[] = [
    getItem(<Link to='/'>Dashboard</Link>, '/', <PieChartOutlined />),

    /* hasPermission('invoice') && permission?.invoice ? */ invoiceOther /* : null */,
    hasPermission('money_receipt') && permission?.money_receipt
      ? moneyReceipt
      : null,
    hasPermission('accounts_module') && permission?.accounts_module
      ? accounts
      : null,
    hasPermission('cheque_management') && permission?.cheque_management
      ? cheques
      : null,

    hasPermission('expense') && permission?.expense ? expence : null,
    hasPermission('clients') && permission?.clients ? clients : null,
    user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionReport
      ? report
      : null,

    user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionConfig
      ? configuration
      : null,
  ].filter((item) => Boolean(item)) as MenuItem[];

  const [sortedItems, setSortedItems] = useState<MenuItem[] | null>(items);

  const [searchText, setSearchText] = useState<string | undefined>();

  const handleSidebarSort = (e: any) => {
    const searchText: string = e?.target?.value;
    setSearchText(searchText);
    const sidebarAllFlat = flattenArray(items as DataObject[]);

    const searchResult = sidebarAllFlat.filter(
      (item) =>
        ((item &&
          item?.key
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())) ||
          item?.label?.props?.children
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toLocaleLowerCase())) &&
        !item.children
    );

    if (searchResult.length) {
      setSortedItems(searchResult);
    } else {
      setSortedItems(null);
    }
  };

  const location = useLocation();
  const [currentSelection, setCurrentSelection] = useState([location.pathname]);
  const [openKeys, setOpenKeys] = useState(['']);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (items.map((item) => item?.key).indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const indices = findObjectWithKey(items as DataObject[], {
      pathname: location.pathname,
      state: location?.state,
    });
    setOpenKeys(indices || []);
    setCurrentSelection(indices || []);
  }, [location.pathname]);

  // ============= HANDLE WIDTH ================
  const handleResize = (e: any) => {
    setWidth(e.clientX || 245);
  };

  const handleResizeEnd = () => {
    window.removeEventListener('mousemove', handleResize);
    window.removeEventListener('mouseup', handleResizeEnd);
  };

  const handleResizeStart = (event: any) => {
    window.addEventListener('mousemove', handleResize);
    window.addEventListener('mouseup', handleResizeEnd);
  };

  const menuRef = useRef<MenuRef>(null);

  // @this block only for resize bar height control.
  const [barHeight, setBarHeight] = useState<number | string>('100%');
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (menuRef.current) {
        setBarHeight(
          menuRef.current.menu?.list?.offsetHeight
            ? menuRef.current.menu?.list?.offsetHeight
            : '100%'
        );
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [menuRef, openKeys, currentSelection, searchText]);

  const noncollapseFunc = () => {
    setCollapsed(true);
  };

  const collapseFunc = () => {
    setCollapsed(false);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    setWidth(201);
  };

  useEffect(() => {
    if (width <= 180) {
      noncollapseFunc();
    } else if (width >= 181) {
      collapseFunc();
      if (width >= 450) {
        setWidth(450);
      }
    }
  }, [width]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Sider
      theme='dark'
      collapsed={collapsed}
      collapsible
      onCollapse={() => {
        handleCollapse();
        if (menuRef.current) {
          setBarHeight(
            menuRef.current.menu?.list?.offsetHeight
              ? menuRef.current.menu?.list?.offsetHeight
              : '100%'
          );
        }
      }}
      width={width}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        userSelect: 'none',
        transition: 'all 0s',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`siderDesign ${isHovered ? 'sidebar-hovered' : ''}`}
      {...props}
    >
      <div
        className='resize-handle'
        style={{
          cursor: 'ew-resize',
          height: barHeight,
          position: 'absolute',
          right: 0,
          top: 0,
          width: 4,
        }}
        onMouseDown={handleResizeStart}
      />
      {/* Date and time */}

      {!collapsed ? (
        <>
          <DateTimeWidget />
          <div
            style={{
              padding: '10px 10px',
              position: 'sticky',
              zIndex: 9,
              top: 0,
            }}
          >
            <Input
              autoFocus
              className='search_feild'
              placeholder='Sidebar items search here...'
              suffix={<SearchOutlined />}
              onChange={handleSidebarSort}
            />
          </div>
        </>
      ) : (
        <Link to='/'>
          <img
            src={
              'https://m360ict.s3.ap-south-1.amazonaws.com/trabill/assets/flynas_logo.svg'
            }
            alt={user?.organization_info.org_name || 'Trabill'}
            style={{
              background: '#fff',
              borderRadius: 5,
              padding: '5px 8px',
              width: 60,
              margin: '10px 6px',
            }}
          />
        </Link>
      )}

      <Menu
        inlineIndent={10}
        ref={menuRef}
        items={
          searchText && searchText !== ' '
            ? sortedItems?.length
              ? sortedItems
              : [getItem('Not found', 'Not found')]
            : items
        }
        theme='dark'
        mode='inline'
        openKeys={openKeys}
        selectedKeys={currentSelection}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/']}
        style={{ paddingBottom: '40px' }}
      />
    </Sider>
  );
};
export default Sidebar;

export const iconStyle: React.CSSProperties = {
  fontSize: 12,
};
