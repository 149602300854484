import { Button, Form, Modal, Row, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  IPostInvoiceMoneyReceipt,
  MoneyReceipt,
} from '../../Money_Receipt/Types/MoneyReceiptTypes';
import {
  useLazyGetMoneyReceiptInvocieDueByIdQuery,
  usePostInvoiceMoneyReceiptMutation,
} from '../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import AccountLastBalanceInput from '../../../components/common/AccountLastBalanceInput';
import { useGetAccountByTypeQuery } from '../../Expense/Api/Endpoints/ExpenseEndpoints';
const InvoiceOtherAddMoneyModal = ({
  invoice_id,
  disabled,
  InvocieModalTitle,
}: {
  invoice_id: number;
  disabled: boolean;
  InvocieModalTitle?: string | undefined;
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [required, setRequired] = useState<boolean>();
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>();
  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId),
    {
      skip: payMethodCatId ? false : true,
    }
  );
  const [getInvoiceDue, { data, isLoading: isInvoiceLoading }] =
    useLazyGetMoneyReceiptInvocieDueByIdQuery();
  const invoice_due = data?.data;

  const [
    postInvoiceMoneyReceipt,
    { isSuccess, error, isError, isLoading, data: MoneyReceiptID },
  ] = usePostInvoiceMoneyReceiptMutation();

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector(selectUser);
  const showModal = async () => {
    // form.resetFields();
    getInvoiceDue(invoice_id).then((data) => {
      console.log(data.data?.data);
      form.setFieldsValue({
        invoice_net_total: data.data?.data?.invoice_net_total,
        invoice_due: data.data?.data?.invoice_due,
        invoice_pay: data.data?.data?.invoice_pay,
      });
      form.setFieldValue('receipt_payment_date', dayjs());
    });

    setButtonDisabled(Number(invoice_due?.invoice_due) === 0);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = (values: MoneyReceipt) => {
    const body = {
      invoice_combclient_id: invoice_due?.invoice_combclient_id as string,
      invoice_created_by: user?.user_id,
      money_receipt: {
        receipt_total_amount: values.receipt_total_amount,
        receipt_total_discount: values.receipt_total_discount,
        receipt_payment_type: values.receipt_payment_type,
        account_id: values.account_id,
        cheque_number: values.cheque_number,
        cheque_withdraw_date: dayjs(values.cheque_withdraw_date).format(
          'YYYY-MM-DD'
        ),
        charge_amount: values.charge_amount,
        cheque_bank_name: values.cheque_bank_name,
        receipt_money_receipt_no: values.receipt_money_receipt_no
          ? values.receipt_money_receipt_no.toString()
          : values.receipt_money_receipt_no,
        receipt_note: values.receipt_note,
        trans_no: values.trans_no,
        receipt_payment_date: dayjs(values.receipt_payment_date).format(
          'YYYY-MM-DD'
        ),
      },
    };
    const invoiceMoneyReceipt: IPostInvoiceMoneyReceipt = {
      body: body,
      invoice_id: Number(invoice_id),
    };

    postInvoiceMoneyReceipt({ data: invoiceMoneyReceipt })
      .then((res) => {
        handleCancel();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/moneyreceipt/view/${MoneyReceiptID?.data}`, {
        state: '/moneyreceipt',
      });
      message.success('Money Receipt Successfully Added');
      form.resetFields();
      setIsModalOpen(false);
    } else if (isError) {
      setIsModalOpen(false);
      message.error('Something Went Wrong!!');
    }
  }, [isSuccess, isError]);

  const onFinishFailed = () => {
    message.error('Something Went Wrong!!');
  };

  useEffect(() => {
    if (!id) {
      form.setFieldValue('receipt_payment_date', dayjs());
    }
  }, []);

  return (
    <>
      {!disabled ? (
        <Button size='small' type='primary' onClick={showModal}>
          Money Receipt
        </Button>
      ) : (
        <></>
      )}

      <Modal
        forceRender
        title={`${InvocieModalTitle} Add Money`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: buttonDisabled, loading: isLoading }}
      >
        <Form
          onFinish={onFinish}
          layout='vertical'
          form={form}
          onFinishFailed={onFinishFailed}
        >
          {isInvoiceLoading && <LoadingIndicator />}
          <Row gutter={[10, 0]}>
            <SelectPaymentMethod
              size={12}
              required
              name='receipt_payment_type'
              label='Payment Method '
              onChange={(value: number) => {
                setPayMethodCatId(value);
                setRequired(value ? true : false);
              }}
            />

            {Number(payMethodCatId) !== 4 && (
              <AccountLastBalanceInput
                size={12}
                required={required}
                accountsLastBalance={accounts ? accounts.data : []}
                name='account_id'
                label='Account:'
              />
            )}

            {Number(payMethodCatId) === 4 && (
              <>
                <FormInputItem
                  size={12}
                  label='Cheque No'
                  name='cheque_number'
                  required
                />

                <DateInput
                  name='cheque_withdraw_date'
                  label='Withdraw Date'
                  required
                  size={12}
                />

                <FormInputItem
                  name={'cheque_bank_name'}
                  label='Bank Name'
                  required
                  size={12}
                />
              </>
            )}

            <NumberInput
              label='Net Total:'
              name={'invoice_net_total'}
              readOnly
              min='0'
              maxChar={14}
            />
            <NumberInput
              label='Paid:'
              name={'invoice_pay'}
              readOnly
              min='0'
              // maxChar={14}
            />
            <NumberInput
              label='Due:'
              name={'invoice_due'}
              readOnly
              min='0'
              // maxChar={14}
            />
            {Number(payMethodCatId) === 3 && (
              <Row
                style={{ width: '100%' }}
                justify={'space-between'}
                align={'middle'}
                gutter={10}
              >
                <FormInputItem
                  label='Transaction Charge:'
                  size={12}
                  name='charge_amount'
                />
                <FormInputItem label='Trans No:' size={12} name='trans_no' />
              </Row>
            )}
            <NumberInput
              label='Amount:'
              size={12}
              name={'receipt_total_amount'}
              required={required}
              min='0'
              maxChar={14}
            />

            <NumberInput
              size={12}
              label='Discount:'
              name={'receipt_total_discount'}
              min='0'
              maxChar={12}
              minChar={0}
            />

            <DateInput
              size={12}
              required={required}
              label='Payment Date:'
              name={'receipt_payment_date'}
              disablePrevDate
            />

            <FormInputItem
              size={12}
              label='Manual Money reciept no'
              name={'receipt_money_receipt_no'}
              min='0'
              maxChar={14}
            />
            <FormInputItem size={12} label='Note:' name={'receipt_note'} />
          </Row>
        </Form>

        {/* {!(Number(invoice_pay) || 0) ? (
          <Alert
            banner
            icon={'NOTE:'}
            message={
              <Marquee
                pauseOnClick
                gradient={false}
              >
                If you add 'Money Receipt' you can't edit/update the invoice!
              </Marquee>
            }
          />
        ) : null} */}
      </Modal>
    </>
  );
};

export default InvoiceOtherAddMoneyModal;
