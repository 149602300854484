import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useLazyYearlySalesDetailsQuery } from '../Api/Endpoints/DashboardEndpoints';
import { IWeeklyMonthlyDailyMonthly } from '../interfaces/dashboard.interfaces';
import DashboardTitle from './DashboardTitle';
import { getColor } from './MonthlyReport';

type Props = {
  data: IWeeklyMonthlyDailyMonthly | undefined;
  isDarkMode: any;
};

const YearlyReport = ({ data, isDarkMode }: Props) => {
  const from_date = dayjs().subtract(1, 'y').format('YYYY-MM-DD');
  const to_date = dayjs().format('YYYY-MM-DD');
  const location = useLocation();
  const { Panel } = Collapse;

  const [getDetails, { data: details, isFetching, isLoading }] =
    useLazyYearlySalesDetailsQuery();

  const handleChange = () => {
    getDetails('', true);
  };

  const reportData = [
    {
      label: 'Sales',
      name: 'Sales Amount',
      value: Fixed2(data?.yearly_sales),
      url: `/reports/daily_sales_report?y_from_date=${from_date}&y_to_date=${to_date}`,
    },

    {
      label: 'Received',
      name: 'Received Amount',
      value: Fixed2(data?.yearly_received),
      url: `/reports/client_wise_collection_and_sales_Report?y_from_date=${from_date}&y_to_date=${to_date}`,
    },

    {
      label: 'Expense',
      name: 'Office Expense',
      value: Fixed2(data?.yearly_expense),
      url: `/reports/head_wise_expense_report?y_from_date=${from_date}&y_to_date=${to_date}`,
    },

    {
      label: 'Purchase',
      name: 'Purchase Amount',
      value: Fixed2(details?.data?.yearly_purchase),
      url: `/reports/vendor_wise_purchase_and_payment?y_from_date=${from_date}&y_to_date=${to_date}`,
    },

    {
      label: 'Payment',
      name: 'Payment Amount',
      value: Fixed2(details?.data?.yearly_payment),
      url: `/vendor/payment-report?y_from_date=${from_date}&y_to_date=${to_date}`,
    },

    {
      label: 'Profit',
      name: 'Profit/Loss',
      value: Fixed2(data?.yearly_sales) - Fixed2(details?.data?.yearly_payment),
      url: `/reports/over_all_profit_loss?y_from_date=${from_date}&y_to_date=${to_date}`,
    },
  ];

  return (
    <div className='dashboard-box-shape'>
      <DashboardTitle title='YEARLY REPORT' />
      <div style={{ padding: '0px 20px 10px' }}>
        {reportData.slice(0, 3)?.map((item: any, index: number) => (
          <div
            className={`${isDarkMode ? 'report_box-dark' : ''} report_box${
              index === reportData?.length - 1 ? ' last_box' : ''
            }`}
            key={index}
          >
            <Link
              state={location.pathname}
              to={item.url}
              className='hover_effect'
              style={getColor(index)}
            >
              {item.name}
            </Link>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YearlyReport;
