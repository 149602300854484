import { ColumnsType } from 'antd/lib/table';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { BalanceStatus } from '../../AccountsTypes/AccountsTypes';
import { numberFormate } from '../../../../common/utils/numberFormatting';

export const ListOfBalanceStatusColumns = (): ColumnsType<BalanceStatus> => {
  const searchColumnProps = useGetSearchColumnProps<BalanceStatus>();

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },

    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
      ...searchColumnProps('account_name'),
    },

    {
      title: 'Branch',
      dataIndex: 'account_branch_name',
      key: 'account_branch_name',
    },
    {
      title: 'Bank Name',
      dataIndex: 'account_bank_name',
      key: 'account_bank_name',
      ...searchColumnProps('account_bank_name'),
    },
    {
      title: 'Account No.',
      dataIndex: 'account_number',
      key: 'account_number',
      ...searchColumnProps('account_number'),
    },
    {
      title: 'Balance',
      dataIndex: 'accbalance_amount',
      key: 'accbalance_amount',
      align: 'center',
      render: (_, record) => numberFormate(Number(record.accbalance_amount)),
    },
  ];
};
