import { Button, Modal, Row, Space, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import BreadCrumb from '../../common/breadCrumb/BreadCrumb';
import { SelectDataInput } from '../../common/FormItem/SelectCustomFeilds';
import {
  useGetAllCollectionChequeNotificationsQuery,
  useGetAllPendingPaymentChequeNotificationsQuery,
} from '../Api/NotificationEndpoints';
import { ICheques } from '../Interfaces/Notification.interface';
import { useGetAllChequeQuery } from '../../../modules/Cheque_Management/api/Endpoints/AllChequeManagementEndpoints';
import { getDataWithIndex } from '../../../helpers/getDataWithIndex';
import dayjs from 'dayjs';
import { IAllChequeDataType } from '../../../modules/Cheque_Management/types/ChequeManagementTypes';
import Cheque_Update_Modal from '../../../modules/Cheque_Management/modals/Common_Modals/Cheque_Update_Modal';
import { useAppDispatch } from '../../../app/hooks';
import {
  setBounceModal,
  setReturnModal,
} from '../../../modules/Cheque_Management/states/chequeSlice';
import { ISetState } from '../../../common/types/commonTypes';
import { numberFormate } from '../../../common/utils/numberFormatting';
import { generatePagination } from '../../../common/utils/generatePagination';
export const size_limit = 20;
export const column = (
  setDepositInfo: ISetState<IAllChequeDataType | null>,
  showDepositModal: () => void,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  pagination: {
    current: number;
    pageSize: number;
  }
): ColumnsType<ICheques> => {
  const setDeposit = (value: any) => {
    setDepositInfo(value);
    // showDepositModal();
    setShowModal(true);
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, value, index) =>
        (pagination.current - 1) * size_limit + (index + 1),
    },
    {
      title: 'Withdraw Date',
      key: 'withdraw_date',
      render: (_: number, record: any) => {
        return `${dayjs(record.withdraw_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Cheque No',
      dataIndex: 'cheque_number',
      key: 'cheque_number',
    },

    {
      title: 'Cheque Type',
      dataIndex: 'cheque_type',
      key: 'cheque_type',
      // render: (cheque_type) => {
      //   return getChequeType(cheque_type);
      // },
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => numberFormate(Number(record.amount)),
    },

    {
      title: 'Bank Name',
      dataIndex: 'cheque_bank_name',
      key: 'cheque_bank_name',
    },

    {
      title: 'Status',
      key: 'cheque_status',
      render: (_, data) => {
        let color = data.cheque_status === 'PENDING' ? 'volcano' : 'green';
        return <Tag color={color}>{data.cheque_status}</Tag>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'collected_by',
      key: 'collected_by',

      render: (_, data) => {
        function depositedCheque() {
          if (
            data.cheque_status == 'DEPOSIT' ||
            data.cheque_status == 'RETURN' ||
            data.cheque_status == 'BOUNCE'
          ) {
            return true;
          }
          return false;
        }

        return (
          <>
            <Space size='small'>
              <Button
                type='primary'
                size='small'
                disabled={depositedCheque()}
                onClick={() => setDeposit(data)}
              >
                <Typography style={{ color: '#ffffff' }}>
                  Change Status
                </Typography>
              </Button>
            </Space>
          </>
        );
      },
    },
  ];
};
export const column1 = (
  setDepositInfo: ISetState<IAllChequeDataType | null>,
  showDepositModal: () => void,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  pagination: {
    current: number;
    pageSize: number;
  }
): ColumnsType<ICheques> => {
  const setDeposit = (value: any) => {
    setDepositInfo(value);
    setShowModal(true);
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, value, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: 'Withdraw Date',
      key: 'withdraw_date',
      render: (_: number, record: any) => {
        return `${dayjs(record.withdraw_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Cheque No',
      dataIndex: 'cheque_number',
      key: 'cheque_number',
    },

    {
      title: 'Cheque Type',
      dataIndex: 'cheque_type',
      key: 'cheque_type',
      // render: (cheque_type) => {
      //   return getChequeType(cheque_type);
      // },
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => numberFormate(Number(record.amount)),
    },

    {
      title: 'Bank Name',
      dataIndex: 'cheque_bank_name',
      key: 'cheque_bank_name',
    },

    {
      title: 'Status',
      key: 'cheque_status',
      render: (_, data) => {
        let color = data.cheque_status === 'PENDING' ? 'volcano' : 'green';
        return <Tag color={color}>{data.cheque_status}</Tag>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'collected_by',
      key: 'collected_by',

      render: (_, data) => {
        function depositedCheque() {
          if (
            data.cheque_status == 'DEPOSIT' ||
            data.cheque_status == 'RETURN' ||
            data.cheque_status == 'BOUNCE'
          ) {
            return true;
          }
          return false;
        }

        return (
          <>
            <Space size='small'>
              <Button
                type='primary'
                size='small'
                disabled={depositedCheque()}
                onClick={() => setDeposit(data)}
              >
                <Typography style={{ color: '#ffffff' }}>
                  Change Status
                </Typography>
              </Button>
            </Space>
          </>
        );
      },
    },
  ];
};

export const PaymentPendingCheque = ({
  showofCheck,
}: {
  showofCheck?: boolean;
}) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const dispatch = useAppDispatch();
  const [chequeStatus, setChequeStatus] = useState<string>('PENDING');

  const [depositInfo, setDepositInfo] = useState<IAllChequeDataType | null>(
    null
  );

  //--------------------------------------------
  const { data: pendingPaymentChequeNotifi } =
    useGetAllPendingPaymentChequeNotificationsQuery({
      cheque_status: chequeStatus,
      pagination,
    });
  //-------------------------------------------------

  const [showModal, setShowModal] = useState(false);
  const { data: allCheque } = useGetAllChequeQuery(chequeStatus);
  const chequeList = allCheque?.data;

  const showDepositModal = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    dispatch(setBounceModal(false));
    dispatch(setReturnModal(false));
  };

  return (
    <div>
      {!showofCheck && <BreadCrumb arrOfOption={['Pending Payment Cheque']} />}

      <Modal
        title='Change Cheque Status'
        open={showModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {depositInfo && <Cheque_Update_Modal info={depositInfo} />}
      </Modal>

      {!showofCheck && (
        <Row style={{ marginBottom: '10px' }}>
          <SelectDataInput
            onChange={(value) => setChequeStatus(value.toString())}
            defaultValue={'PENDING'}
            data={[
              { title: 'PENDING', id: 'PENDING' },
              { title: 'DEPOSIT', id: 'DEPOSIT' },
              { title: 'BOUNCE', id: 'BOUNCE' },
              { title: 'RETURN', id: 'RETURN' },
            ]}
          />
        </Row>
      )}
      <Table
        size='small'
        bordered
        dataSource={getDataWithIndex(pendingPaymentChequeNotifi?.data?.data)}
        columns={column(
          setDepositInfo,
          showDepositModal,
          setShowModal,
          pagination
        )}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={generatePagination(
          pendingPaymentChequeNotifi?.count,
          setPagination,
          pagination
        )}
        scroll={{ x: true }}
      />
    </div>
  );
};

export const CollectionPendingCheque = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const dispatch = useAppDispatch();
  const [chequeStatus, setChequeStatus] = useState('PENDING');

  const [depositInfo, setDepositInfo] = useState<IAllChequeDataType | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const { data: allCheque } = useGetAllChequeQuery(chequeStatus);
  const { data: collectCheNotification } =
    useGetAllCollectionChequeNotificationsQuery({
      cheque_status: chequeStatus,
      pagination,
    });
  const chequeList = allCheque?.data;
  const paymentCollection = chequeList?.filter(
    (item) => item.cheque_pay_type != 'PAYMENT'
  );

  const showDepositModal = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <div>
      <BreadCrumb arrOfOption={['Pending Collection Cheque']} />

      <Modal
        title='Change Cheque Status'
        open={showModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {depositInfo && <Cheque_Update_Modal info={depositInfo} />}
      </Modal>

      <Row style={{ marginBottom: '10px' }}>
        <SelectDataInput
          onChange={(value) => setChequeStatus(value.toString())}
          defaultValue={'PENDING'}
          data={[
            { title: 'PENDING', id: 'PENDING' },
            { title: 'DEPOSIT', id: 'DEPOSIT' },
            { title: 'BOUNCE', id: 'BOUNCE' },
            { title: 'RETURN', id: 'RETURN' },
          ]}
        />
      </Row>

      <Table
        size='small'
        bordered
        dataSource={collectCheNotification?.data?.data}
        columns={column1(
          setDepositInfo,
          showDepositModal,
          setShowModal,
          pagination
        )}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={generatePagination(
          collectCheNotification?.data?.count,
          setPagination,
          pagination
        )}
      />
    </div>
  );
};
