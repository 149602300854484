import { AutoComplete, Col, Divider, Form, Input, Select, Space } from 'antd';
import { Rule } from 'antd/es/form';
import { NamePath } from 'antd/es/form/interface';
import React, { CSSProperties, useEffect, useState } from 'react';
import { ISelectData, commonProps } from '../../../common/types/commonTypes';
import CountryName from '../../../helpers/CountryName';
import {
  useGetAllCombClientsQuery,
  useLazyGetClientsBySearchQuery,
} from '../../../modules/Client/Client/api/endpoints/clientEndpoints';
import {
  genderChildren,
  mobileChildren,
} from '../../../modules/Client/Client/utils/utils_tsx/utilAddClient';
import { useGetAllClientCategoryQuery } from '../../../modules/Configuration/clientCategory/endpoints/clientCategoryEndpoints';
import { useGetAllEmployeesQuery } from '../../../modules/Configuration/employee/endpoints/employeeEndpoints';
import { useGetAllExpenseHeadQuery } from '../../../modules/Configuration/expenseHead/Endpoints/expenseHeadEndpoints';
import { useGetExpenseSubHeadByIDQuery } from '../../../modules/Configuration/expenseSubHead/Endpoints/expenseSubHeadEndpoints';
import { useAppDispatch } from '../../../app/hooks';
import InstanceCreateEmployee from '../../../modules/Configuration/employee/modals/InstanceCreateEmployee';
import { employeeDataType } from '../../../modules/Configuration/employee/types/employeeTypes';
import { useGetAllAccountListQuery } from '../../../modules/Reports/AccountsReport/endpoints/accountReportEndpoints';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useGetClientInvoicesInfoQuery } from '../../../modules/Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import CreateClientModal from '../CreateClientModal';

type vendorProps = {
  vendorInfoName?: number;
  ticketInfoName?: number;
  isPercentage?: boolean;
  rules?: Rule[];
  dependencies?: NamePath[];
  selectVendor?: any;
  vendorID?: number;
  manualData?: any[];
  setInstanceVendorSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  searchText?: string;
  setFetch?: React.Dispatch<React.SetStateAction<boolean>>;
  vendor_id?: string[];
  showDropdownModal?: boolean;
  setInstantVendor?: React.Dispatch<React.SetStateAction<number | undefined>>;
} & commonProps;

// @FORM ITEM LAVEROCK AND WRAPPER WRAP FULL WIDTH
export const formItemLayout = {
  labelCol: {
    span: 24, // Full width
  },
  wrapperCol: {
    span: 24, // Full width
  },
};

const { Option } = Select;

// CLIENTS AND COMBINED CLIENT

export const SelectClients = ({
  name,
  label,
  size,
  required,
  disabled,
  placeholder,
  onChange,
  setInstanceClientSelect,
  showAll,
  loading,
  rules,
  dependencies,
  offDropDown,
  mdSize,
  smSize,
  form,
  autoFillup,
  allowClear,
  setClientDetails,
}: commonProps) => {
  const [search, setSearch] = useState('');

  const {
    isLoading,
    isFetching,
    data: clients,
  } = useGetAllCombClientsQuery(search);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (value !== '') setSearch(value);
      // fetchCombClient(value);
    }, 400);
  };

  const selectClients = clients?.data;

  const clientsChildren: React.ReactNode[] = [];
  if (selectClients) {
    for (let i = 0; i < selectClients.length; i++) {
      const {
        client_id,
        client_name,
        client_type,
        client_entry_id,
        client_mobile,
      } = selectClients[i];

      clientsChildren.push(
        <Select.Option
          key={
            client_name +
            '  ' +
            client_id +
            ' ' +
            client_entry_id +
            ' ' +
            client_mobile
          }
          value={client_id}
        >
          {client_name + ` - (${client_entry_id})`}
        </Select.Option>
      );
    }
  }

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key='all' value='all'>
      All
    </Select.Option>
  );

  // @SELECT CLIENT AND AUTO FILUP MOBILE
  const client_id = useWatch(name || [], form);
  useEffect(() => {
    if (autoFillup) {
      const findSelected = selectClients?.find(
        (item) => `${item?.client_type}-${item?.client_id}` === client_id
      );
      form?.setFieldsValue({
        client_mobile: findSelected?.client_mobile || undefined,
      });
    }
  }, [client_id]);

  showAll && clientsChildren.unshift(allOption);

  const handleSelectChange = (value: string) => {
    const id = value;
    const selectedClientData = selectClients?.find((item) => {
      return Number(item?.client_id) === Number(id);
    }) as any;
    setClientDetails && setClientDetails(selectedClientData);
  };

  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: 'Client required' },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          onSearch={(value) => handleSearch(value)}
          loading={loading || isLoading || isFetching || false}
          dropdownStyle={{ color: 'red' }}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          dropdownMatchSelectWidth={200}
          showSearch
          allowClear={allowClear ? allowClear : true}
          style={{ padding: '0', margin: '0', border: '0' }}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: string) => {
            onChange && onChange(e);
          }}
          onChange={handleSelectChange}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateClientModal
                      setClick={setClick}
                      setInstanceClientSelect={setInstanceClientSelect}
                    />
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectClientsOnly = ({
  name,
  label,
  size,
  required,
  disabled,
  placeholder,
  showAll,
  mdSize,
  smSize,
  xsSize,
  onChange,
  offDropDown,
}: commonProps) => {
  // const { data: clients } = useGetAllClientsQuery({});

  const [fetchClient, { data: clients, isLoading, isFetching }] =
    useLazyGetClientsBySearchQuery();

  useEffect(() => {
    fetchClient('');
  }, []);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchClient(value);
    }, 300);
  };

  const selectClients = clients?.data;

  const clientsChildren: React.ReactNode[] = [];

  if (selectClients) {
    for (let i = 0; i < selectClients.length; i++) {
      const { client_id, client_name, category_prefix } = selectClients[i];
      clientsChildren.push(
        <Select.Option key={client_name + '  ' + client_id} value={client_id}>
          {client_name + ` - (${category_prefix})`}
        </Select.Option>
      );
    }
  }

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key='all' value='all'>
      ALL
    </Select.Option>
  );

  showAll && clientsChildren.unshift(allOption);
  return (
    <Col
      xs={xsSize ? xsSize : 24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: 'Client required' }]}
      >
        <Select
          loading={isLoading || isFetching}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          dropdownMatchSelectWidth={200}
          onSearch={handleSearch}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: string) => {
            onChange && onChange(e);
          }}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateClientModal setClick={setClick} />
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
export const SelectCombined = ({
  name,
  label,
  size,
  required,
  disabled,
  placeholder,
  setCombId,
  onChange,
  showAll,
  offDropDown,
}: commonProps) => {
  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const combinedChildren: React.ReactNode[] = [];

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key='all' value='all'>
      All
    </Select.Option>
  );

  showAll && combinedChildren.unshift(allOption);
  return (
    <Col xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: 'Client required' }]}
      >
        <Select
          disabled={disabled}
          allowClear
          placeholder={placeholder ? placeholder : label}
          dropdownMatchSelectWidth={200}
          showSearch
          onSearch={handleSearch}
          onChange={(e) => setCombId && setCombId(e)}
          style={{ padding: '0', margin: '0', border: '0' }}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: string) => {
            onChange && onChange(e);
          }}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateClientModal setClick={setClick} />
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {combinedChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectInvoice = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  rules,
  disabled,
  dependencies,
}: commonProps) => {
  // const { data: invoices } = useGetOverAllInvoicesQuery();
  // const invoicesAll = invoices?.data;
  const invoiceAllChildren: React.ReactNode[] = [];

  // if (invoicesAll) {
  //   for (let i = 0; i < invoicesAll.length; i++) {
  //     invoiceAllChildren.push(
  //       <Select.Option
  //         key={invoicesAll[i]?.invoice_no}
  //         value={invoicesAll[i]?.invoice_id}
  //       >
  //         {`${invoicesAll[i]?.invoice_no}`}
  //       </Select.Option>
  //     );
  //   }
  // }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          size={small ? 'small' : 'middle'}
          allowClear
          placeholder={'Select Invoice'}
          showSearch
          disabled={disabled || false}
          dropdownMatchSelectWidth={250}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {invoiceAllChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectEmployee = ({
  name,
  label,
  size,
  required,
  showAll,
  setInstanceSalesEmpSelect,
  offDropDown,
}: commonProps) => {
  const { data: employees } = useGetAllEmployeesQuery();

  const selectEmploye = employees?.data;
  const employeeChildrens: React.ReactNode[] = [];
  if (selectEmploye) {
    for (let i = 0; i < selectEmploye.length; i++) {
      employeeChildrens.push(
        <Select.Option
          title=' '
          key={
            selectEmploye[i].employee_id +
            ' ' +
            selectEmploye[i].employee_birth_date
          }
          value={selectEmploye[i].employee_id}
        >
          {selectEmploye[i].employee_full_name}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key='all' value='all'>
      All
    </Select.Option>
  );

  showAll && employeeChildrens.unshift(allOption);
  const [click, setClick] = useState<boolean>();
  const [editInfo, setEditInfo] = useState<employeeDataType | null>(null);
  const dispatch = useAppDispatch();
  return (
    <Col span={6} xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={'Select employee'}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          dropdownMatchSelectWidth={150}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <InstanceCreateEmployee
                      setClick={setClick}
                      setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
                    />
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {employeeChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT EXPENSE HEAD

type SelectExpenseProps = {
  setSubHeadId?:
    | React.Dispatch<React.SetStateAction<Number | undefined>>
    | undefined;
  headID?: number;
  setHeadID?: (arg: number) => void;
} & commonProps;

export const SelectExpenseHead = ({
  name,
  label,
  size,
  placeholder,
  required,
  setHeadID,
  mdSize,
  smSize,
  showAll,
}: SelectExpenseProps) => {
  const { data: headExpense } = useGetAllExpenseHeadQuery();

  const selectExpense = headExpense?.data;

  const headExpenseChildrens: React.ReactNode[] = [];

  if (selectExpense) {
    for (let i = 0; i < selectExpense.length; i++) {
      headExpenseChildrens.push(
        <Select.Option
          key={selectExpense[i].head_name + ' ' + selectExpense[i].head_id}
          value={selectExpense[i].head_id}
        >
          {`${selectExpense[i].head_name}`}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key='all' value='all'>
      All
    </Select.Option>
  );

  showAll && headExpenseChildrens.unshift(allOption);
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: 'Product is required' }]}
      >
        <Select
          placeholder={placeholder || 'Select sales by'}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: number) => {
            setHeadID && setHeadID(e);
          }}
        >
          {headExpenseChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT EXPENSE SUB HEAD

export const SelectExpenseSubHead = ({
  name,
  label,
  size,
  placeholder,
  required,
  headID,
  setSubHeadId,
}: SelectExpenseProps) => {
  const { data: subHeadExpense } = useGetExpenseSubHeadByIDQuery(
    headID as number
  );

  // const SelectClientCategory = allSubHead?.data;
  // const subHeadExpenseChildrens: React.ReactNode[] = [];
  // if (SelectClientCategory) {
  //   for (let i = 0; i < SelectClientCategory.length; i++) {
  //     subHeadExpenseChildrens.push(
  //       <Select.Option
  //         key={
  //           SelectClientCategory[i].subhead_name +
  //           ' ' +
  //           SelectClientCategory[i].subhead_id
  //         }
  //         value={SelectClientCategory[i].subhead_id}
  //       >
  //         {`${SelectClientCategory[i].subhead_name}`}
  //       </Select.Option>
  //     );
  //   }
  // }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: 'SubHead Expense is required' }]}
      >
        <Select
          onChange={(e) => setSubHeadId && setSubHeadId(e)}
          placeholder={placeholder || 'Select sales by'}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {/* {subHeadExpenseChildrens} */}
        </Select>
      </Form.Item>
    </Col>
  );
};
// SELECT EXPENSE SUB HEAD

export const SelectClientCategory = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  placeholder,
  required,
  headID,
  showAll,
  setSubHeadId,
}: SelectExpenseProps) => {
  const { data: clientCategory } = useGetAllClientCategoryQuery();

  const SelectClientCategory = clientCategory?.data;
  const clientCategoryChildrens: React.ReactNode[] = [];
  if (SelectClientCategory) {
    for (let i = 0; i < SelectClientCategory.length; i++) {
      clientCategoryChildrens.push(
        <Select.Option
          key={
            SelectClientCategory[i].category_title +
            ' ' +
            SelectClientCategory[i].category_id
          }
          value={SelectClientCategory[i].category_id}
        >
          {`${SelectClientCategory[i].category_title}`}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key='all' value='all'>
      ALL
    </Select.Option>
  );

  showAll && clientCategoryChildrens.unshift(allOption);
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: 'SubHead Expense is required' }]}
      >
        <Select
          onChange={(e) => setSubHeadId && setSubHeadId(e)}
          placeholder={placeholder || 'Select Client Category'}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {clientCategoryChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectUsers = ({
  name,
  label,
  size,
  placeholder,
  required,
  showAll,
  onSelect,
}: commonProps) => {
  // const { data: users } = useGetAllUsersQuery();

  // const SelectUsers = users?.data;
  // const userChildrens: React.ReactNode[] = [];

  // if (SelectUsers) {
  //   for (let i = 0; i < SelectUsers.length; i++) {
  //     userChildrens.push(
  //       <Select.Option
  //         key={SelectUsers[i].user_full_name + ' ' + SelectUsers[i].user_id}
  //         value={SelectUsers[i].user_id}
  //       >
  //         {`${SelectUsers[i].user_full_name}`}
  //       </Select.Option>
  //     );
  //   }
  // }

  const allOption = (
    <Select.Option key='all' value='all'>
      ALL
    </Select.Option>
  );

  // showAll && userChildrens?.unshift(allOption);

  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: 'Users is required' }]}
      >
        <Select
          allowClear={true}
          placeholder={placeholder || 'Select User'}
          onSelect={onSelect}
          showSearch
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {/* {userChildrens} */}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT CITY

type SelectCityProp = {
  getCityByCId: number | undefined;
} & commonProps;

// SELECT COUNTRY CODE
export const SelectCountryCode = (
  <Select
    showSearch
    placeholder={CountryName[19].dialCode}
    optionFilterProp='roleMobile'
    filterOption={(input, option) =>
      (option!.children as unknown as string)
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  >
    {mobileChildren}
  </Select>
);

// COMMON SELECT INPUT

type CustomInputProps = {
  data?: ISelectData[];
  defaultValue?: number | string;
  name?: NamePath;
  label?: string;
  required?: boolean;
  size?: number;
  mdSize?: number;
  smSize?: number;
  placeholder?: string;
  disabled?: boolean;
  small?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  onSearch?: (value: string) => void;
  mb?: number;
  searchText?: string;
  loading?: boolean;
  mode?: 'multiple' | 'tags';
  style?: CSSProperties;
  allowClear?: boolean;
  clientID?: string | undefined;
  setTableData?: React.Dispatch<React.SetStateAction<any[]>>;
  rules?: Rule[];
};

export const SelectDataInput = ({
  name,
  label,
  size,
  smSize,
  mdSize,
  required,
  data,
  placeholder,
  disabled,
  onChange,
  onSearch,
  small,
  defaultValue,
  mb,
  searchText,
  loading,
  mode,
  style,
  allowClear,
  rules,
}: CustomInputProps) => {
  const childrens: React.ReactNode[] = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      childrens.push(
        <Select.Option
          key={data[i].title + ' ' + data[i].id}
          value={data[i].id}
        >
          {data[i].title}
        </Select.Option>
      );
    }
  }

  return (
    <Col
      style={{ marginBottom: mb }}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
    >
      <Form.Item
        style={{ padding: '0', margin: '0', ...style }}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <Select
          mode={mode}
          size={small ? 'small' : 'middle'}
          placeholder={placeholder ? placeholder : label}
          showSearch
          searchValue={searchText || undefined}
          disabled={disabled}
          onChange={onChange}
          onSearch={onSearch}
          allowClear={allowClear ? true : false}
          defaultValue={defaultValue}
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          loading={loading || false}
        >
          {childrens}
        </Select>
      </Form.Item>
    </Col>
  );
};
// SELECT INPUT
type SelectProps = {
  options?: string[];
  style?: React.CSSProperties;
  onSelect?: (value: string) => void;
  rules?: Rule[] | undefined;
  defaultValue?: string | number | undefined;
  setOpeningType?: React.Dispatch<React.SetStateAction<string>>;
  items?: { label: string; value: number | string }[];
} & commonProps;

export const SelectOptionsInput = ({
  options,
  name,
  label,
  size,
  mdSize,
  smSize,
  onChange,
  disabled,
  required,
  placeholder,
  style,
  small,
  onSelect,
  rules,
  dependencies,
  defaultValue,
  setOpeningType,
}: SelectProps) => {
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? smSize : 8}
      lg={size || 8}
    >
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
        style={{ ...style }}
      >
        <Select
          placeholder={placeholder || `Select ${label}`}
          onChange={onChange}
          defaultValue={defaultValue}
          onSelect={(value) => onSelect && onSelect(value.toString())}
          dropdownMatchSelectWidth={150}
          showSearch
          disabled={disabled}
          size={small ? 'small' : 'middle'}
          allowClear
          onClear={() => setOpeningType && setOpeningType('')}
        >
          {options?.map((item, index) => {
            return (
              <Select.Option value={item} key={String(index)}>
                {item}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectItem = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  onChange,
  disabled,
  required,
  placeholder,
  style,
  small,
  onSelect,
  rules,
  dependencies,
  defaultValue,
  setOpeningType,
  items,
}: SelectProps) => {
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? smSize : 8}
      lg={size || 8}
    >
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
        style={{ ...style }}
      >
        <Select
          placeholder={placeholder || `Select ${label}`}
          onChange={onChange}
          defaultValue={defaultValue}
          onSelect={(value) => onSelect && onSelect(value.toString())}
          dropdownMatchSelectWidth={150}
          showSearch
          disabled={disabled}
          size={small ? 'small' : 'middle'}
          allowClear
          onClear={() => setOpeningType && setOpeningType('')}
        >
          {items?.map((item, index) => {
            return (
              <Select.Option value={item.value} key={String(index)}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

//only visa wise
export const SelectAccountList = ({
  name,
  label,
  size,
  disabled,
  placeholder,
  onChange,
  showAll,
}: commonProps) => {
  const { data: AccountList } = useGetAllAccountListQuery();
  const selectClients = AccountList?.data;
  const visaChildren: React.ReactNode[] = [];
  if (selectClients) {
    for (let i = 0; i < selectClients?.length; i++) {
      const { account_id, account_name } = selectClients[i];

      visaChildren.push(
        <Select.Option
          title=' '
          key={account_name + '  ' + account_id}
          value={`${account_id}`}
        >
          {account_name}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <>
      <Select.Option key='all' value='all'>
        All
      </Select.Option>
      <Select.Option key='investments' value='investments'>
        Investments
      </Select.Option>
      <Select.Option key='noninvoiceincome' value='noninvoiceincome'>
        Non-Invoice Income
      </Select.Option>
      <Select.Option key='incentiveincome' value='incentiveincome'>
        Incentive Income
      </Select.Option>
    </>
  );

  showAll && visaChildren.unshift(allOption);
  return (
    <Col xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item name={'account_id'} label={'Select Account'}>
        <Select
          dropdownStyle={{ color: 'red' }}
          placeholder={'Select Account'}
          showSearch
        >
          {visaChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

interface IClientInvoiceOrTicket {
  client_id: string;
  receipt_payment_to: 'INVOICE' | 'OVERALL';
  form: FormInstance<any>;
  index: number;
  paymentTo: 'INVOICE' | 'TICKET';
}
export const SelectClientsInvoicesById = ({
  name,
  label,
  required,
  disabled,
  placeholder,
  loading,
  rules,
  dependencies,
  client_id,
  receipt_payment_to,
  form,
  index,
  paymentTo,
}: commonProps & IClientInvoiceOrTicket) => {
  const invoiceNo = useWatch(['invoices', index, 'invoice_no'], form);
  const { data: clientInvoicesInfo } = useGetClientInvoicesInfoQuery({
    id: client_id,
    receipt_payment_to,
  });
  const selectClients = clientInvoicesInfo?.data;
  useEffect(() => {
    if (paymentTo === 'INVOICE') {
      const findData = selectClients?.money_receipt.find(
        (item) => item.invoice_id === invoiceNo
      );
      form.setFields([
        {
          name: ['invoices', index, 'invoiceDate'],
          value: findData?.invoice_date
            ? dayjs(findData?.invoice_date)
            : undefined,
        },
        {
          name: ['invoices', index, 'netTotal'],
          value: findData?.invoice_net_total,
        },
        {
          name: ['invoices', index, 'paid'],
          value: findData?.total_pay_amount,
        },
        {
          name: ['invoices', index, 'due'],
          value: findData?.invoice_due,
        },
      ]);
    }
  }, [invoiceNo, selectClients, paymentTo]);

  const clientsChildren: React.ReactNode[] = [];
  if (selectClients?.money_receipt?.length) {
    selectClients?.money_receipt?.forEach((element, index) => {
      clientsChildren.push(
        <Select.Option
          title=' '
          key={element?.invoice_id}
          value={element.invoice_id}
        >
          {element.invoice_no}
        </Select.Option>
      );
    });
  }
  return (
    <Col span={6} xs={24} sm={12} md={8} lg={4}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: 'Client required' },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          loading={loading || false}
          dropdownStyle={{ color: 'red' }}
          placeholder={placeholder ? placeholder : label}
          dropdownMatchSelectWidth={200}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectClientsInvoicesByIdForEdit = ({
  name,
  label,
  required,
  disabled,
  placeholder,
  loading,
  rules,
  dependencies,
  client_id,
  receipt_payment_to,
  index,
  form,
}: commonProps & IClientInvoiceOrTicket) => {
  const { data: clientInvoicesInfo } = useGetClientInvoicesInfoQuery({
    id: client_id,
    receipt_payment_to,
  });

  const invoiceNo = useWatch(['invoices', index, 'invoice_no'], form);
  const paymentTo = form.getFieldValue('receipt_payment_to');

  useEffect(() => {
    if (paymentTo === 'INVOICE') {
      const findData = selectClients?.money_receipt.find(
        (item) => item.invoice_id === invoiceNo
      );

      if (findData)
        form.setFields([
          {
            name: ['invoices', index, 'invoiceDate'],
            value: findData?.invoice_date
              ? dayjs(findData?.invoice_date)
              : undefined,
          },
          {
            name: ['invoices', index, 'netTotal'],
            value: findData?.invoice_net_total,
          },
          {
            name: ['invoices', index, 'paid'],
            value: findData?.total_pay_amount,
          },
          {
            name: ['invoices', index, 'due'],
            value: findData?.invoice_due,
          },
        ]);
    }
  }, [invoiceNo]);

  const selectClients = clientInvoicesInfo?.data;

  const clientsChildren: React.ReactNode[] = [];

  if (selectClients) {
    selectClients?.money_receipt?.forEach((element, index) => {
      clientsChildren.push(
        <Select.Option
          title=' '
          key={element?.invoice_id}
          value={element.invoice_id}
        >
          {element.invoice_no}
        </Select.Option>
      );
    });
  }

  return (
    <Col span={6} xs={24} sm={12} md={8} lg={4}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: 'Client required' },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          loading={loading || false}
          dropdownStyle={{ color: 'red' }}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          dropdownMatchSelectWidth={200}
          showSearch
          allowClear
          style={{ padding: '0', margin: '0', border: '0' }}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectGender = ({ name, label, size, required }: commonProps) => {
  return (
    <Col span={6} xs={24} sm={12} md={12} lg={size || 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required || false, message: 'Gender is required' }]}
      >
        <Select
          showSearch
          allowClear
          placeholder='Select gender'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {genderChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

type Props = {
  label: string;
  name: string | any;
  required?: boolean;
  options: string[];
  size?: number;
};

export const AutoComplete_input = ({
  label,
  name,
  required,
  options,
  size,
}: Props) => {
  return (
    <Col xs={24} sm={12} md={12} lg={size || 12} xl={size || 12}>
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: `Please select ${label}` }]}
      >
        <AutoComplete
          style={{ width: '100%' }}
          placeholder={label}
          options={options?.map((Item) => ({
            value: Item,
          }))}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <Input name={name} required={required} />
        </AutoComplete>
      </Form.Item>
    </Col>
  );
};
