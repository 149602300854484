import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import { useLazyDailySalesDetailsQuery } from '../Api/Endpoints/DashboardEndpoints';
import { IWeeklyMonthlyDailyMonthly } from '../interfaces/dashboard.interfaces';
import '../style/weeklyReport.css';
import DashboardTitle from './DashboardTitle';
import { getColor } from './MonthlyReport';
import { Fixed2 } from '../../../common/utils/common.utils';

type Props = {
  data: IWeeklyMonthlyDailyMonthly | undefined;
  isDarkMode: any;
};

const DailyReport = ({ data, isDarkMode }: Props) => {
  const { Panel } = Collapse;

  const currentDate = dayjs().format('YYYY-MM-DD');
  const [getDetails, { data: details, isFetching, isLoading }] =
    useLazyDailySalesDetailsQuery();

  const handleChange = () => {
    getDetails('', true);
  };

  const reportData = [
    {
      label: 'Sales',
      name: 'Sales Amount',
      value: Fixed2(data?.daily_sales),
      url: `/reports/daily_sales_report?daily=${currentDate}`,
    },

    {
      label: 'Received',
      name: 'Received Amount',
      value: Fixed2(data?.daily_received),
      url: `/reports/client_wise_collection_and_sales_Report?daily=${currentDate}`,
    },
    {
      label: 'Expense',
      name: 'Office Expense',
      value: Fixed2(data?.daily_expense),
      url: `/reports/head_wise_expense_report?daily=${currentDate}`,
    },
    {
      label: 'Purchased',
      name: 'Purchased Amount',
      value: Fixed2(details?.data?.daily_purchase),
      url: `/reports/vendor_wise_purchase_and_payment?daily=${currentDate}`,
    },
    {
      label: 'Payment',
      name: 'Payment Amount',
      value: Fixed2(details?.data?.daily_payment),
      url: `/vendor/payment-report?daily=${currentDate}`,
    },

    {
      label: 'Profit',
      name: 'Profit/Loss',
      value: Fixed2(data?.daily_sales) - Fixed2(details?.data?.daily_purchase),
      url: `/reports/over_all_profit_loss?daily=${currentDate}`,
    },
  ];

  const location = useLocation();

  return (
    <div className='dashboard-box-shape'>
      <DashboardTitle title='DAILY REPORT' />
      <div style={{ padding: '0px 20px 10px' }}>
        {reportData.slice(0, 3)?.map((item: any, index: number) => (
          <div
            className={`${isDarkMode ? 'report_box-dark' : ''} report_box${
              index === reportData?.length - 1 ? ' last_box' : ''
            }`}
            key={index}
          >
            <Link
              to={item.url}
              style={getColor(index)}
              state={location.pathname}
            >
              {item.name}
            </Link>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyReport;
