import { Button, Col, DatePicker, Row, Select, Table, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAccountsQuery } from '../../Api/Endpoints/accountsEndpoints';
import { ListOfTransactionHistoryColumn } from '../../utils/Transaction_History/ListOfTransactionHistoryColumn';

import { ArrowLeftOutlined, PrinterFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Fixed2 } from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { useGetTransactionHistoryQuery } from '../../Api/Endpoints/transactionHistoryEndpoints';
import { numberFormate } from '../../../../common/utils/numberFormatting';

type Props = {};
const Transaction_History = (props: Props) => {
  const [id, setId] = useState<string>('all');
  const [date, setDate] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
      pageSize: 20,
    }));
  }, [id, date]);

  const { data: accounts } = useGetAllAccountsQuery();

  const { data, isLoading, isFetching } = useGetTransactionHistoryQuery({
    ...pagination,
    id,
    from: date[0] || '',
    to: date[1] || '',
  });

  let credit_sum = 0;
  let debit_sum = 0;
  let amount_sum = 0;

  data?.data?.forEach(({ acctrxn_amount, acctrxn_type }) => {
    acctrxn_type == 'CREDIT'
      ? (credit_sum += Number(acctrxn_amount))
      : (debit_sum += Number(acctrxn_amount));
    amount_sum += Number(acctrxn_amount);
  });

  let amountSum = 0;
  data?.data?.forEach(({ amount }) => {
    if (!isNaN(amount)) {
      amountSum += Number(amount || 0);
    }
  });

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle:
      '@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }',
  });

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSelect = (id: string) => {
    setId(id);
  };

  // pagination
  const tablePagination =
    data?.count !== undefined && data?.count < 20
      ? false
      : {
          ...pagination,
          total: data?.count,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100', '200', '500'],
          onChange: handlePaginationChange,
        };

  // summary
  const tableSummary = (record: any) => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={4} colSpan={6}>
          <Typography.Text strong style={{ alignItems: 'end' }}>
            Total
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          <Typography.Text strong>
            {numberFormate(Fixed2(debit_sum))}
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          <Typography.Text strong>
            {' '}
            {numberFormate(Fixed2(credit_sum))}
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}></Table.Summary.Cell>
        <Table.Summary.Cell index={6}></Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const jsx_com = (
    <div ref={componentRef}>
      <Table
        size='small'
        bordered
        rowKey={(e) => e.key}
        columns={ListOfTransactionHistoryColumn({ pagination })}
        dataSource={data?.data}
        pagination={tablePagination}
        summary={tableSummary}
      />
    </div>
  );
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Monthly Sales Earning And Due' }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Accounts', 'Transaction History']} />
      {(isLoading || isFetching) && <LoadingIndicator />}
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        <div style={{ display: 'flex', gap: 10 }}>
          <Link to='/accounts'>
            <Button type='primary'>
              <ArrowLeftOutlined />
              Return to Accounts List
            </Button>
          </Link>

          <Button type='primary' onClick={handlePrint}>
            <PrinterFilled />
            Print
          </Button>
        </div>
        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={11} md={8} lg={8}>
              <Select
                style={{ width: '100%' }}
                showSearch
                allowClear
                placeholder={'Select Account'}
                optionFilterProp='children'
                defaultValue={'all'}
                onChange={handleSelect}
              >
                <Select.Option value='all'>All</Select.Option>
                {accounts?.data?.map((item: any, index) => {
                  return (
                    <Select.Option key={index} value={item.account_id}>
                      {`${item.account_name}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      {jsx_com}

      {print_content}
    </>
  );
};

export default Transaction_History;
