import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { IAccountNonInvoiceIncome } from '../../AccountsTypes/AccountsTypes';
import { useGetAllNonInvoiceIncomeQuery } from '../../Api/Endpoints/nonInvoiceIncomeEndpoints';
import Non_Invoice_Income_Add_Modal from '../../modals/Non_Invoice_Income/Non_Invoice_Income_Add_Modal';
import Non_Invoice_Income_Edit_Modal from '../../modals/Non_Invoice_Income/Non_Invoice_Income_Edit_Modal';
import { ListOfNonInvoiceIncomeColumn } from '../../utils/Non_Invoice_Income/ListOfNonInvoiceIncomeColumn';
import { removeSpaceBeforeWord } from '../../../../common/utils/removeSpaceBeforeWord';

const Non_Invoice_Income = ({ permission }: perProps) => {
  const [editInfo, setEditInfo] = useState<IAccountNonInvoiceIncome | null>(
    null
  );
  const dispatch = useAppDispatch();

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const { data, isLoading, isError, isFetching, refetch } =
    useGetAllNonInvoiceIncomeQuery({
      ...pagination,
      from: date[0] || '',
      to: date[1] || '',
      search,
    });

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };
  return (
    <div>
      <BreadCrumb
        arrOfOption={['Accounts', 'Non Invoice Income']}
        refetch={() => refetch()}
        reloaderSize='small'
      />
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          {permission?.['create:any'] && (
            <Col>
              <Button type='primary' onClick={showModal}>
                <PlusOutlined />
                Add Non-Invoice Income
              </Button>
            </Col>
          )}
        </Row>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonTableHeader
        title=''
        element={
          editInfo ? (
            <Non_Invoice_Income_Edit_Modal
              info={editInfo}
              setNull={setEditInfo}
            />
          ) : (
            <Non_Invoice_Income_Add_Modal />
          )
        }
        setNull={setEditInfo}
      />
      <Table
        size='small'
        bordered
        columns={ListOfNonInvoiceIncomeColumn({
          setEditInfo,
          showModal,
          permission,
          pagination,
        })}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default Non_Invoice_Income;
