import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllClientBillAdjustmentQuery } from '../../Api/Endpoints/clientBillAdjustmentEndpoints';
import Client_Bill_Add_Modal from '../../modals/Client_Bill_Adjustment/Client_Bill_Add_Modal';
import { ListOfClientBillAdjustementColumn } from '../../utils/Client_Bill_Adjustment/ListOfClientBillAdjustementColumn';
import { removeSpaceBeforeWord } from '../../../../common/utils/removeSpaceBeforeWord';

const Client_Bill_Adjustment = ({ permission }: perProps) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { data, isLoading, isFetching } = useGetAllClientBillAdjustmentQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          {permission?.[crud.create] && (
            <Col>
              <Button
                type='primary'
                onClick={showModal}
                style={{ marginBottom: 25 }}
              >
                <PlusOutlined />
                Add Client Bill Adjustment
              </Button>
            </Col>
          )}
        </Row>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowKey={(e) => e.cbilladjust_vouchar_no}
        columns={ListOfClientBillAdjustementColumn({ permission, pagination })}
        dataSource={data?.data}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        scroll={{ x: true }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />

      <Modal
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Client_Bill_Add_Modal setIsModalOpen={setIsModalOpen} />
      </Modal>
    </div>
  );
};

export default Client_Bill_Adjustment;
