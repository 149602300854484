import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IClientMoneyReceiptData } from '../../types/clientInterfaces';
import { IPermission } from '../../../../../common/types/commonTypes';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { numberFormate } from '../../../../../common/utils/numberFormatting';

type Props = {};

export const clientPaymentUtil = (
  permission?: IPermission
): ColumnsType<IClientMoneyReceiptData> => {
  const searchColumnProps = useGetSearchColumnProps<IClientMoneyReceiptData>();

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Vouchar No.',
      dataIndex: 'receipt_vouchar_no',
      key: 'receipt_vouchar_no',
      ...searchColumnProps('receipt_vouchar_no'),
    },
    {
      title: 'Date',
      key: 'receipt_payment_date',
      render: (_, record) => {
        return `${dayjs(record.receipt_payment_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Total Amount',
      key: 'receipt_total_amount',
      dataIndex: 'receipt_total_amount',
      align: 'center',
      render: (_, record) => numberFormate(Number(record.receipt_total_amount)),
    },
    {
      title: 'Action',
      key: 'operation',

      render: (_, record) => (
        <Space size='small'>
          <Link
            to={`/moneyreceipt/view/${record.receipt_id}`}
            state={location.pathname}
          >
            <Button size='small' type='primary'>
              View
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
};
