import { Tabs } from 'antd';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import Client_Bill_Adjustment from '../Client_Bill_Adjustment/Client_Bill_Adjustment';

type Props = {
  permission?: IPermission | undefined;
};

const Bill_Adjustment = ({ permission }: Props) => {
  return (
    <>
      <BreadCrumb arrOfOption={['Bill Adjustment']} reloaderSize='small' />
      <Tabs
        type='line'
        items={[
          {
            label: 'Clients Bill Adjustment',
            key: '1',
            children: <Client_Bill_Adjustment permission={permission} />,
          },
        ]}
      ></Tabs>
    </>
  );
};

export default Bill_Adjustment;
