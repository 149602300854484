import { Radio, RadioChangeEvent, Space } from 'antd';
import React from 'react';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig } from '../type/appConfig_Type';

type props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};
const AutoMail = ({ configDetails }: props) => {
  const [editPost] = usePostEditAppConfigMutation();
  const defaultValue = configDetails?.tac_auto_email;

  const handleChange = (e: RadioChangeEvent) => {
    const body = {
      ...configDetails,
      tac_auto_email: e.target.value,
    };

    editPost(body);
  };
  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Radio.Group onChange={handleChange} value={defaultValue}>
        <Space direction='vertical'>
          <Radio value={0}>No</Radio>
          <Radio value={1}>Yes</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default AutoMail;
