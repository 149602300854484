import { Button, Popconfirm, Space, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  IAccountNonInvoiceIncome,
  IAccountNonInvoiceIncomeDelResBody,
} from '../../AccountsTypes/AccountsTypes';
import { useDeleteNonInvoiceIncomeMutation } from '../../Api/Endpoints/nonInvoiceIncomeEndpoints';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { numberFormate } from '../../../../common/utils/numberFormatting';

type Props = {
  setEditInfo: ISetState<IAccountNonInvoiceIncome | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const ListOfNonInvoiceIncomeColumn = ({
  setEditInfo,
  showModal,
  permission,
  pagination,
}: Props): ColumnsType<IAccountNonInvoiceIncome> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [
    deleteNonInvoiceIncome,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteNonInvoiceIncomeMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Non-Invoice Income Deleted Successfully');
      setColumnIndex(null);
    } else if (isDeleteError) {
      message.error('Something went wrong');
      setColumnIndex(null);
    }
  }, [isDeleteError, isSuccess]);

  const handleClick = (record: IAccountNonInvoiceIncome) => {
    setEditInfo(record);
    showModal();
  };

  const user = useSelector(selectUser);

  const handleDelete = async (
    values: IAccountNonInvoiceIncome,
    index: number
  ) => {
    const body: IAccountNonInvoiceIncomeDelResBody = {
      nonincome_id: values.nonincome_id,
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteNonInvoiceIncome(body);
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'nonincome_created_date',
      render: (_, record) => {
        return `${dayjs(record.nonincome_created_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Vouchar No',
      dataIndex: 'nonincome_vouchar_no',
      key: 'nonincome_vouchar_no',
    },

    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },

    {
      title: 'Transaction Type',
      key: 'transaction_type',
      render: () => {
        return 'Non-Invoice Income';
      },
    },
    {
      title: 'Transaction Details',
      key: 'transaction_details',
      render: (_, record) =>
        ` ${record.account_name}  ${
          record.account_branch_name ? ', ' + record.account_branch_name : ''
        }`,
    },
    {
      title: 'Amount',
      dataIndex: 'nonincome_amount',
      key: 'nonincome_amount',
      render: (_, record) => numberFormate(Number(record.nonincome_amount)),
    },
    {
      title: 'Note',
      dataIndex: 'nonincome_note',
      key: 'nonincome_note',
    },

    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => (
        <Space size='small'>
          {
            <Link
              to={`/accounts/noninvoice/view/${record.nonincome_id}`}
              state={'/accounts/noninvoice'}
            >
              <Button icon={<EyeOutlined />} size='small' type='link'></Button>
            </Link>
          }

          {permission?.['update:any'] && (
            <Button
              size='small'
              type='link'
              icon={<EditOutlined />}
              onClick={() => handleClick(record)}
            ></Button>
          )}

          {/* {permission?.['delete:any'] && record.nonincome_is_deleted === 0 && ( */}

          <Button
            size='small'
            type='link'
            danger
            loading={columnIndex === index && true}
          >
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Button>
          {/* )} */}
        </Space>
      ),
    },
  ];
};
