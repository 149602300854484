import { Divider, Form, Row } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import BillingInfoForm from './BillingInfoForm';
import FormHeaderTitle from '../../../../modules/Invoices/common/FormHeaderTitle';

type Props = {
  form: FormInstance<any>;
  billing_length?: number;
  setProductId?: number;
};
const InvoiceBillingInfo = ({ form, billing_length, setProductId }: Props) => {
  const fullList: any[] = useWatch(['billing'], form);
  useEffect(() => {
    if (fullList?.length) {
      form.setFieldsValue(
        {
          invoice_sub_total: fullList?.reduce(
            // (a, b) => a + (b?.is_deleted === 1 ? 0 : Fixed2(b?.billing_subtotal)),
            (a, b) => a + Fixed2(b?.billing_total_amount),
            0
          ),
        } || []
      );
    }
  }, [fullList]);

  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Billing Information 💵' />
      <Form.List name='billing' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row key={index} gutter={[10, { xs: 8, sm: 16, md: 24, lg: 24 }]}>
                <BillingInfoForm
                  form={form}
                  field={field}
                  setProductId={setProductId}
                  add={add}
                  remove={remove}
                  index={index}
                  billing_length={billing_length}
                />

                {fields.length > 1 && <Divider />}
              </Row>
            );
          })
        }
      </Form.List>
    </div>
  );
};

export default InvoiceBillingInfo;
