import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IDailySalesReportList } from '../types/DailySalesReportTypes';
import { numberFormate } from '../../../../common/utils/numberFormatting';

interface Props {
  pagination: {
    current: number;
    pageSize: number;
  };
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined;
}

export const DailySalesReportColumn = ({
  pagination,
  paramData,
}: Props): ColumnsType<IDailySalesReportList> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      align: 'center',

      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render(value, record, index) {
        return dayjs(record.invoice_sales_date).format('DD-MM-YYYY');
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },

    {
      title: 'Sales By',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
    },
    {
      title: 'Sales Price',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render: (_, value) => numberFormate(Number(value.invoice_sub_total)),
    },
  ];
};
