import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { crud, IPermission } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { IBalanceTransfer } from '../../AccountsTypes/AccountsTypes';
import {
  useDeleteBalanceTransferMutation,
  useRestoreBalanceTransferMutation,
} from '../../Api/Endpoints/balanceTransferEndpoints';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { numberFormate } from '../../../../common/utils/numberFormatting';

export const ListOfBalanceTransferColumn = ({
  permission,
  pagination,
  checkButtonState,
}: {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
  checkButtonState: boolean;
}): ColumnsType<IBalanceTransfer> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useSelector(selectUser);
  const searchColumnProps = useGetSearchColumnProps<IBalanceTransfer>();

  const [
    deleteBalanceTransfer,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteBalanceTransferMutation();
  const [restoreBalanceTransfer, { isError: isRestoreError }] =
    useRestoreBalanceTransferMutation();
  useEffect(() => {
    setColumnIndex(null);
    isDeleteError && toasterNotification('error', 'Something went wrong');
  }, [isDeleteError]);
  useEffect(() => {
    isRestoreError && toasterNotification('error', 'Something went wrong');
  }, [isRestoreError]);
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isDeleteError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isDeleteError]);
  const handleDelete = async (values: IBalanceTransfer, index: number) => {
    setColumnIndex(index);
    const body = {
      btransfer_id: values.btransfer_id,
      created_by: user?.user_id as number,
    };
    await deleteBalanceTransfer(body);
  };
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'btransfer_date',
      render: (_, record) => {
        return `${dayjs(record.btransfer_date).format('DD MM YYYY')}`;
      },
    },
    {
      title: 'Vouchar No',
      dataIndex: 'btransfer_vouchar_no',
      key: 'btransfer_vouchar_no',
    },
    {
      title: 'Transfer From',
      dataIndex: 'btransfer_from_name',
      key: 'btransfer_from_name',
    },
    {
      title: 'Transfer To',
      dataIndex: 'btransfer_to_name',
      key: 'btransfer_to_name',
    },
    {
      title: 'Amount',
      dataIndex: 'btransfer_amount',
      key: 'btransfer_amount',
      render: (_, record) => numberFormate(Number(record.btransfer_amount)),
    },
    {
      title: 'Charge Amount',
      dataIndex: 'btransfer_charge',
      key: 'btransfer_charge',
      render: (_, record) => numberFormate(Number(record.btransfer_charge)),
    },
    {
      title: 'Note',
      dataIndex: 'btransfer_note',
      key: 'btransfer_note',
    },

    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => (
        <Space size='small'>
          {!checkButtonState && (
            <Link
              to={`/accounts/balance_transfer/view/${record.btransfer_id}`}
              state={location.pathname}
            >
              <Button icon={<EyeOutlined />} size='small' type='link'></Button>
            </Link>
          )}

          {permission?.['delete:any'] && record.btransfer_is_deleted === 0 && (
            <Button
              danger
              size='small'
              type='link'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
