import { Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  mt?: number;
  link?: string;
  mb?: string;
};

const DashboardTitle = ({ title, mt = 0, link, mb }: Props) => {
  return (
    <Row
      justify={'space-between'}
      style={{
        marginBottom: mb || 10,
        alignItems: 'center',
        marginTop: mt,
        display: 'flex',
        justifyContent: 'space-between',
        background: 'linear-gradient(30deg, #00b7ac, #7FDBD5)',

        padding: '5px 20px',
        borderRadius: 4,
      }}
    >
      <Typography.Text
        strong
        style={{ fontSize: '16px', color: '#fff', fontWeight: 'bold' }}
      >
        {title.toLocaleUpperCase()}
      </Typography.Text>

      {link && (
        <Link to={link}>
          <span style={{ fontSize: 14, fontFamily: 'unset', color: '#fff' }}>
            Show All
          </span>
        </Link>
      )}
    </Row>
  );
};

export default DashboardTitle;
