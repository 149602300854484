import { Button, Col, Form, FormListFieldData, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
  SelectAirport,
} from '../../FormItem/FormItems';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { AutoComplete_input } from '../../FormItem/SelectCustomFeilds';

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  setProductId: number | undefined;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  index: number;
  billing_length?: number;
};

const BillingInfoForm = ({
  field,
  form,
  setProductId,
  add,
  remove,
  index,
  billing_length,
}: Props) => {
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const totalPax = useWatch(['billing', field.name, 'billing_total_pax'], form);
  const totalINF = useWatch(['billing', field.name, 'billing_inf'], form);
  const EQVLNTBDT = useWatch(
    ['billing', field.name, 'billing_usd_equivalent'],
    form
  );

  useEffect(() => {
    form.setFields([
      {
        name: ['billing', field.name, 'billing_total_amount'],
        value: Number(totalPax || 0) * Number(EQVLNTBDT || 0),
      },
      {
        name: ['billing', field.name, 'billing_total_amount'],
        value: Number(totalPax || 0) * Number(EQVLNTBDT || 0),
      },
    ]);
  }, [totalPax, EQVLNTBDT]);

  const is_deleted: 0 | 1 = useWatch(['billing', index, 'is_deleted'], form);
  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (billing_length && billing_length > index) {
      setDisabled(true);
    }
  }, [is_deleted, billing_length]);
  return (
    <Row
      style={
        is_deleted === 1
          ? { backgroundColor: '#FFC0CB', width: '100%' }
          : { width: '100%' }
      }
      align='bottom'
      gutter={[10, 0]}
    >
      <FormInputItem
        label='Pax Name'
        name={[field.name, 'billing_pax']}
        size={3}
      />
      <AutoComplete_input
        label='Route'
        name={[field.name, 'billing_route']}
        options={['DAC-JED-MED-DAC', 'DAC-JED', 'MED-DAC']}
        size={3}
      />

      <FormInputItem label='PNR' name={[field.name, 'billing_pnr']} size={3} />
      <NumberInput
        label='Total Pax (ADT)'
        name={[field.name, 'billing_total_pax']}
        size={3}
        min='0'
        maxChar={3}
      />
      <NumberInput
        label='INF'
        name={[field.name, 'billing_inf']}
        size={2}
        min='0'
      />

      <NumberInput
        label='EQVLNT BDT'
        name={[field.name, 'billing_usd_equivalent']}
        size={3}
        min='0'
      />

      <NumberInput
        label='Total BDT'
        name={[field.name, 'billing_total_amount']}
        size={3}
        readOnly
        min='0'
        maxChar={14}
      />
      <DateInput
        label='Flight Date:'
        name={[field.name, 'billing_flight_date']}
        size={3}
      />
      <FormInputItem
        label='Remarks'
        name={[field.name, 'billing_remarks']}
        size={3}
      />

      {field.name === 0 ? (
        <Col lg={1}>
          <Form.Item label={false}>
            <Button type='primary' onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={1}>
          <Form.Item label={false}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'billing',
                  field.name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['billing', field.name, 'is_deleted'],
                  Number(!prev)
                );
                if (id) {
                  if (billing_length && index >= billing_length) {
                    remove(field.name);
                  }
                } else {
                  remove(field.name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default BillingInfoForm;
