import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Form, Space, Table } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useGetDailySalesReportQuery,
  useGetExcelSalesReportMutation,
} from '../endpoints/DailySalesReportEndpoints';
import { ISubmitDailySalesReport } from '../types/DailySalesReportTypes';
import { DailySalesReportColumn } from '../utils/DailySalesReportColumn';
import FormHeaderTitle from '../../../Invoices/common/FormHeaderTitle';

const DailySalesReport = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();

  const [fetchExcel] = useGetExcelSalesReportMutation();

  const [getData, SetGetData] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [formDate, setFromDate] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const [ClientId, setClientId] = useState('all');
  const [employeeId, setEmployeeId] = useState('all');
  const [productId, setProductId] = useState('all');

  const date_range_value = useWatch(['date_range'], form);

  // Set Default Value
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('invoice_combclient_id', 'all');
    form.setFieldValue('employee_id', 'all');
    form.setFieldValue('product_id', 'all');
    setParamData({
      date_range: `?from_date=${dayjs()}&to_date=${dayjs()}`,
      id: '',
    });
  }, []);

  const location = useLocation();

  const { data, isLoading, isFetching } = useGetDailySalesReportQuery(
    {
      ...pagination,
      from_date: dayjs(formDate).format('YYYY-MM-DD'),
      to_date: dayjs(toDate).format('YYYY-MM-DD'),
      client_id: ClientId,
      employee_id: employeeId,
    },
    { skip: getData }
  );
  // This useEffect is working based on Params
  useEffect(() => {
    form.submit();
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get('daily');

    const monthFormDate = searchParams.get('from_date');
    const monthToDate = searchParams.get('to_date');

    const yearFormDate = searchParams.get('y_from_date');
    const yearToDate = searchParams.get('y_to_date');

    if (dailyValue) {
      handleFinish({
        date_range: [dailyValue, dailyValue],
        employee_id: 'all',
        invoice_combclient_id: 'all',
        product_id: 'all',
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue('date_range', [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);

      handleFinish({
        date_range: [monthFormDate, monthToDate],
        employee_id: 'all',
        invoice_combclient_id: 'all',
        product_id: 'all',
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue('date_range', [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      handleFinish({
        date_range: [yearFormDate, yearToDate],
        employee_id: 'all',
        invoice_combclient_id: 'all',
        product_id: 'all',
      });
    }
  }, [location.search]);

  const handleFinish = (value: ISubmitDailySalesReport) => {
    console.log(value);
    setClientId(value.invoice_combclient_id);
    setEmployeeId(value.employee_id);
    setProductId(value.product_id);

    setFromDate(value.date_range[0]);
    setToDate(value.date_range[1]);

    SetGetData(false);

    setPagination((prev) => ({ ...prev, current: 1, pageSize: 20 }));
  };

  useEffect(() => {
    if (formDate && toDate) {
      setParamData({
        date_range: `from_date=${formDate}&to_date=${toDate}`,
        id: '',
      });
    }
  }, [formDate, toDate]);
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const LastPage = Math.ceil(
    Number(data?.count || 0) / (pagination?.pageSize || 0)
  );

  const jsx_com = (
    <>
      <FormHeaderTitle title='Sales Report' />
      {
        <Table
          size='small'
          bordered
          columns={DailySalesReportColumn({ pagination, paramData })}
          dataSource={data?.data?.map((item, index) => ({
            ...item,
            key: index + 1,
          }))}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.count && data.count > 20
              ? {
                  ...pagination,
                  total: data.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
              : false
          }
        />
      }
    </>
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Sales_Report${date_range_value}`,
  });

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Sales Report' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Sales Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>

        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              from_date: formDate,
              to_date: toDate,
              client_id: ClientId,
              employee_id: employeeId,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form onFinish={handleFinish} form={form} layout='vertical'>
        <ReportsHeader
          size={5}
          showSelectClients
          showSelectEmployee
          showSelectProduct
          date_range
          title='Sales Report'
          required
        />
      </Form>
      {jsx_com}

      {print_content}
    </>
  );
};

export default DailySalesReport;
