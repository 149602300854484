import { Button, Carousel, Form, Input, Row } from 'antd';
import 'antd/dist/reset.css';
import { BroadcastChannel } from 'broadcast-channel';
import Lottie from 'lottie-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLoginMutation } from '../../app/baseQuery';
import plane from '../../components/images/61111-plane.json';
import { setUser } from '../states/userSlice';
import ForgotPassword from './ForgotPassword';
import './login.css';

type IInputs = {
  username: string;
  password: string;
  remember: boolean;
};

const Login: React.FC = () => {
  const loginChannel = new BroadcastChannel('login');
  const [passwordForm, setPasswordForm] = useState(true);
  const [login, { isLoading, isSuccess }] = useLoginMutation();

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = (values: IInputs) => {
    const body = {
      username: values.username,
      password: values.password,
    };

    login(body).then(({ data }: any) => {
      const agencyID = data?.user_agency_id;

      if (agencyID) {
        loginChannel.postMessage(data);
      }
    });
  };

  loginChannel.onmessage = (msg: any) => {
    const message = msg;
    dispatch(setUser(message));
  };

  const forgotPassword = () => {
    setPasswordForm(!passwordForm);
  };

  return (
    <Style>
      <div className='login-page'>
        <div className='login-box'>
          <Carousel
            className='carousel'
            autoplay
            style={{ width: '580px', padding: '10px' }}
          >
            <img src={'./flynas-2.jpg'} alt='Login' />
            <img src={'./flynas-1.jpg'} alt='Login' />
          </Carousel>
          <div>
            {passwordForm ? (
              <Form
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Row style={{ marginTop: '10px' }} justify='center'>
                  <Lottie animationData={plane} style={{ maxWidth: '300px' }} />
                </Row>

                <div>
                  <Row style={{ marginTop: '10px' }} justify='center'>
                    <img
                      className='illustration-wrapper'
                      src={'./flynas_logo.svg'}
                      alt='Trabill_Logo'
                      width={200}
                    />
                  </Row>

                  <Form.Item
                    style={{ marginTop: '20px' }}
                    name='username'
                    rules={[
                      {
                        // required: true,
                        message: 'Please input your username!',
                      },
                    ]}
                  >
                    <Input
                      autoComplete='password'
                      placeholder='Enter your username'
                    />
                  </Form.Item>

                  <Form.Item
                    name='password'
                    rules={[
                      {
                        // required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Enter your password' />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={isLoading}
                      type='primary'
                      htmlType='submit'
                      className='login-form-button'
                    >
                      LOGIN
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            ) : (
              <ForgotPassword />
            )}

            <h5
              onClick={forgotPassword}
              style={{ color: 'black', cursor: 'pointer' }}
            >
              {passwordForm ? 'Forget Password' : 'Back to login'}
            </h5>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default Login;

const Style = styled.div`
  .carousel {
    @media only screen and (max-width: 1023px) {
      display: none;
      width: 80%;
    }
  }
`;
